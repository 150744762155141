import React from 'react';
import { IconButton, Modal, FlexboxGrid, Loader } from 'rsuite';
import { isNull } from "lodash";

//import icons
import Close from '@rsuite/icons/legacy/Close';

class Synchronization extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const loading = this.props.modal_loading;

        return (
            <Modal backdrop="static" role="alertdialog" open={this.props.open} onClose={handleCloseClick} size="md">
                <Modal.Header closeButton={false}>
                    <Modal.Title>Синхронизация {this.props.syncType} с информационной базой {this.props.infobaseName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <span style={{display: "block", textAlign: "center"}}>
                                {loading ? 
                                    <Loader size="lg" content="Идет синхронизация..." vertical /> :
                                    (this.props.success === true ? <b style={{color: "green", fontSize: "50px"}}>&#10003;</b> : <b style={{color: "red", fontSize: "50px"}}>&#215;</b>)
                                }
                            </span>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick} disabled={loading === false ? false : true}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default Synchronization;