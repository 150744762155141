import React from 'react';
import { Container, Header, Panel, FlexboxGrid, ButtonGroup, ButtonToolbar, IconButton, Table, Whisper, Tooltip, Radio, RadioGroup, Stack, SelectPicker, InputGroup, Input, Checkbox, Pagination, Notification, DateRangePicker } from 'rsuite';
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import icons
import { WiCloudRefresh } from 'react-icons/wi';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import ReloadIcon from '@rsuite/icons/Reload';
import Edit from '@rsuite/icons/Edit';
import Trash from '@rsuite/icons/legacy/Trash';
import Certificate from '@rsuite/icons/legacy/Certificate';
import Repeat from '@rsuite/icons/legacy/Repeat';
import Send from '@rsuite/icons/legacy/Send';
import Eye from '@rsuite/icons/legacy/Eye';

//import common
import predifinedVars from './Common/predifinedVars';

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../controllers/clusterController';

const { Column, HeaderCell, Cell } = Table;

const viewStyles = {
    radioGroupLabel: {
      padding: '8px 2px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
};

const docs = [{
    "id": 1,
    "organisation": "ООО Вектор",
    "date": "13-01-2022",
    "type": "Отчет СЗВ-6",
    "status": "created"
},{
    "id": 2,
    "organisation": "ИП Георгий",
    "date": "13-03-2023",
    "type": "Отчет РСВ",
    "status": "created"
},{
    "id": 3,
    "organisation": "ОАО МСШН",
    "date": "15-06-2022",
    "type": "Отчет РСВ",
    "status": "signed"
},{
    "id": 4,
    "organisation": "ПАО Красная Роза",
    "date": "23-05-2022",
    "type": "Отчет СЗВ-6",
    "status": "signed"
},{
    "id": 5,
    "organisation": "ИП Квантум",
    "date": "22-05-2023",
    "type": "Отчет СЗВ-6",
    "status": "completed"
},{
    "id": 6,
    "organisation": "ФГУП Диктор",
    "date": "23-05-2023",
    "type": "Отчет СЗВ-6",
    "status": "sended"
},{
    "id": 7,
    "organisation": "ООО Марс",
    "date": "27-08-2022",
    "type": "Отчет РСВ",
    "status": "sended"
}]

class EDO extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            clusterId: null,
            clusterList: [],
            periodRange: null,
            infobasesList: [],
            infobaseName: null,
            organisationsList: []
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let infobaseName = this.state.infobaseName;
        let organisationsList = this.state.organisationsList;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationId })
        );

        const data = docs.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const SignCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { isNull(rowData.status) || rowData.status === "created" && <span style={{color: "#fff"}}>Создан</span> }
                { rowData.status === "signed" && <span style={{color: "#7CB9E8"}}>Подписан</span> }
                { rowData.status === "sended" && <span style={{color: "#FFFF00"}}>Отправлен</span> }
                { rowData.status === "completed" && <span style={{color: "#50C878"}}>Сдан</span> }
            </Cell>
        );

        const ToolbarCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                <ButtonGroup>
                    { isNull(rowData.status) || rowData.status === "created" && 
                        <Whisper placement='top' speaker={<Tooltip>Подписать</Tooltip>}>
                            <IconButton icon={<Certificate />} />
                        </Whisper>
                    }
                    { rowData.status === "signed" && 
                        <>
                            <Whisper placement='top' speaker={<Tooltip>Отправить</Tooltip>}>
                                <IconButton icon={<Send />} />
                            </Whisper> 
                            <Whisper placement='top' speaker={<Tooltip>Изменить подпись</Tooltip>}>
                                <IconButton icon={<Repeat />} />
                            </Whisper>
                        </>
                    }
                    { rowData.status === "sended" && 
                        <Whisper placement='top' speaker={<Tooltip>Получить ответ</Tooltip>}>
                            <IconButton icon={<Eye />} />
                        </Whisper>
                    }
                    <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                        <IconButton icon={<SearchIcon />} />
                    </Whisper>
                    <Whisper placement='top' speaker={<Tooltip>Редактировать</Tooltip>}>
                        <IconButton icon={<Edit />} />
                    </Whisper>
                    <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                        <IconButton icon={<Trash />} />
                    </Whisper>
                </ButtonGroup>
            </Cell>
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><WiCloudRefresh/></i> Электронный документооборот</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10} justifyContent="space-between">
                                        <ButtonToolbar>
                                            <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" value={this.convertDate(this.state.periodRange)} style={{ width: 200 }} />
                                            <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                            <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} onChange={(value) => this.getOrganisationsList(value)} placeholder='Информационная база'/>
                                            <SelectPicker data={organisations} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(organisationsList) ? false : true} placeholder='Организация'/>
                                            <IconButton icon={<AddOutlineIcon />} disabled={!isNull(infobaseName) ? false : true}>Добавить</IconButton>
                                            <IconButton icon={<ReloadIcon />}>Обновить</IconButton>
                                            <InputGroup inside style={{ width: 200 }}>
                                                <Input />
                                                <InputGroup.Button>
                                                    <SearchIcon />
                                                </InputGroup.Button>
                                            </InputGroup>
                                        </ButtonToolbar>
                                        <Stack wrap spacing={10}>
                                            <RadioGroup name="filter" inline appearance="picker" defaultValue="created">
                                                <span style={viewStyles.radioGroupLabel}>Фильтр: </span>
                                                <Radio value="created">Создан</Radio>
                                                <Radio value="sended">Отправлен</Radio>
                                                <Radio value="signed">Подписан</Radio>
                                                <Radio value="completed">Сдан</Radio>
                                            </RadioGroup>
                                            <RadioGroup name="edo_type" inline appearance="picker" defaultValue="edo">
                                                <span style={viewStyles.radioGroupLabel}>Тип: </span>
                                                <Radio value="fss">ФСС</Radio>
                                                <Radio value="fns">ФНС</Radio>
                                                <Radio value="pfs">ПФР</Radio>
                                                <Radio value="edo">ЭДО</Radio>
                                            </RadioGroup>
                                        </Stack>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="id" />
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Дата</HeaderCell>
                                        <Cell dataKey="date" />
                                    </Column>
                                    <Column width={250} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Организация</HeaderCell>
                                        <Cell dataKey="organisation" />
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Документ</HeaderCell>
                                        <Cell dataKey="type" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Статус</HeaderCell>
                                        <SignCell dataKey="status" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <ToolbarCell dataKey="status" />
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={docs.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default EDO;