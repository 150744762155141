import React from 'react';
import { Form, Loader, IconButton, Checkbox, FlexboxGrid, List, Notification, InputGroup, InputNumber, RadioTile, RadioTileGroup, Uploader, Stack } from 'rsuite';
import { Icon } from '@rsuite/icons';
import { isEmpty, isNull, isNaN } from "lodash";

//import icons;
import Link from '@rsuite/icons/legacy/Link';
import Download from '@rsuite/icons/legacy/Download';
import Lock from '@rsuite/icons/legacy/Lock';
import User from '@rsuite/icons/legacy/User';
import Numbers from '@rsuite/icons/Numbers';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import ArrowLeft from '@rsuite/icons/legacy/ArrowLeft';
import Unlink from '@rsuite/icons/legacy/Unlink';
import Folder from '@rsuite/icons/legacy/Folder';
import { VscServer, VscVm, VscFolderActive } from "react-icons/vsc";

//import controllers;
import { getCDNFileList, checkFileExist, downloadFilesViaFTP } from '../../../controllers/initController';

import config from '../../../config';

class DownloadInfobases extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cdn_selected: false,
            local_selected: false,
            upload_selected: false,
            download_type_selected: false,
            cdn_choosed: false,
            cdn_checked: false,
            upload_started: false,
            upload_finished: false,
            cdn_url: null,
            cdn_port: null,
            cdn_path: null,
            cdn_login: null,
            cdn_password: null,
            cdn_files: [],
            cdn_files_checked: [],
            cdn_connected: false
        };
        this.handleDownload = this.handleDownload.bind(this);
        this.handleBack = this.handleBack.bind(this);
        this.changeType = this.changeType.bind(this);
        this.handleDisconnect = this.handleDisconnect.bind(this);
        this.UploadStatusChange = this.UploadStatusChange.bind(this);
        this.CheckUploadAvailability = this.CheckUploadAvailability.bind(this);
        this.handleFinishUpload = this.handleFinishUpload.bind(this);
        this.SelectFilesFromFTP = this.SelectFilesFromFTP.bind(this);
    }

    SelectFilesFromFTP = async() => {
        var infobases = document.querySelectorAll('input[type=checkbox]');

        var values = []; 
        for (var index = 0; index < infobases.length; index++) {
           if (infobases[index].checked) {
                values.push(infobases[index].value);
           }
        }

        this.setState({cdn_files_checked: values});
    }

    handleFinishUpload = async() => {
        this.setState({upload_finished: true});
        this.props.check(false);
    }

    CheckUploadAvailability = async(newFile) => {

        let file = newFile.pop();

        let allow = false;

        let ext = file.name.substring(file.name.lastIndexOf(".")+1);

        const toaster = this.props.toaster;

        if(ext === 'dt'){
            let check = await checkFileExist(file);

            if(isEmpty(check) || isNull(check)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Что-то пошло не так, повторите запрос!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(check.success === false){
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{check.message}</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
                allow = false;
            }

            if(check.success === true){
                toaster.push(<Notification showIcon header={'Успех'} type={'success'}>{check.message}</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
                allow = true;
            }
        }else{
            allow = false;
        }

        return new Promise(resolve => {
            setTimeout(() => {

              allow === true ? resolve(true) : resolve(false);

            }, 1000);
        });
    }

    UploadStatusChange = async(response, file) => {
        const toaster = this.props.toaster;

        toaster.push(<Notification showIcon header={'Успех'} type={'success'}>{response.message}</Notification>, {
            duration: 10000,
            placement: 'topEnd'
        })

        this.setState({upload_started: true});
    }

    handleGetListOfFiles = async() => {

        const toaster = this.props.toaster;

        if(isEmpty(this.state.cdn_url) || isNull(this.state.cdn_url)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите адрес сервера!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        const re = /^([\wёa-я-]{2,}\.)+[\wёa-я-]{2,}$/i;
        const re_ip = /^((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])[.]){3}(?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])$/;

        if(re.test(this.state.cdn_url) === false && re_ip.test(this.state.cdn_url) === false){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Некорректный адрес сервера!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.cdn_port) || isNull(this.state.cdn_port) || isNaN(this.state.cdn_port) || this.state.cdn_port < 0){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите порт сервера!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        toaster.push(<Notification showIcon header={'Информация'} type={'info'}>Попытка соединения с сервером. Пожалуйста, подождите...</Notification>, {
            duration: 10000,
            placement: 'topEnd'
        })

        let fields = {
            url: this.state.cdn_url,
            port: this.state.cdn_port,
            path: this.state.cdn_path,
            login: this.state.cdn_login,
            password: this.state.cdn_password
        }

        let getFileList = await getCDNFileList(fields);

        if(!isEmpty(getFileList.data.fileList) || !isNull(getFileList.data.fileList)){

            toaster.push(<Notification showIcon header={'Успех'} type={'success'}>{getFileList.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            })
  
            this.setState({cdn_connected: true, cdn_files: getFileList.data.fileList, cdn_checked: true});

            return;

        }else{
            
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{getFileList.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            })
  
            this.setState({cdn_connected: false, cdn_files: []});

            return;

        }
    }

    handleDisconnect = async() => {
        this.setState({cdn_connected: false, cdn_files: [], cdn_files_checked: []});
    }

    handleDownload = async() => {
        
        const toaster = this.props.toaster;

        if(isEmpty(this.state.cdn_url) || isNull(this.state.cdn_url)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите адрес сервера!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.cdn_port) || isNull(this.state.cdn_port)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите порт сервера!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.cdn_files_checked) || isNull(this.state.cdn_files_checked)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Файлы для загрузки не выбраны!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let fields = {
            url: this.state.cdn_url,
            port: this.state.cdn_port,
            path: this.state.cdn_path,
            login: this.state.cdn_login,
            password: this.state.cdn_password,
            files: this.state.cdn_files_checked
        }

        this.setState({cdn_choosed: true, upload_started: true });

        let downloadFiles = await downloadFilesViaFTP(fields);
        if(downloadFiles.data.success === false){
            this.setState({cdn_choosed: false, upload_started: false });

            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{downloadFiles.data.message}</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }else{
            this.setState({upload_finished: true });
            this.props.check(false);
        }
    }

    changeType = async(value) => {
        if(value === 'cdn'){
            this.setState({
                cdn_selected: true,
                local_selected: false,
                upload_selected: false,
                download_type_selected: true,
                cdn_choosed: false,
                cdn_checked: false,
                upload_started: false,
                upload_finished: false,
                cdn_url: null,
                cdn_port: null,
                cdn_path: null,
                cdn_login: null,
                cdn_password: null,
                cdn_files: [],
                cdn_files_checked: [],
                cdn_connected: false
            });
            this.props.check(true);
            return;
        };

        if(value === 'upload'){
            this.setState({
                cdn_selected: false,
                local_selected: false,
                upload_selected: true,
                download_type_selected: true,
                cdn_choosed: false,
                cdn_checked: false,
                upload_started: false,
                upload_finished: false,
                cdn_url: null,
                cdn_port: null,
                cdn_path: null,
                cdn_login: null,
                cdn_password: null,
                cdn_files: [],
                cdn_files_checked: [],
                cdn_connected: false
            });
            this.props.check(true);
            return;
        }

        if(value === 'local'){
            this.setState({
                cdn_selected: false,
                local_selected: true,
                upload_selected: false,
                download_type_selected: true,
                cdn_choosed: false,
                cdn_checked: false,
                upload_started: false,
                upload_finished: false,
                cdn_url: null,
                cdn_port: null,
                cdn_path: null,
                cdn_login: null,
                cdn_password: null,
                cdn_files: [],
                cdn_files_checked: [],
                cdn_connected: false
            });
            this.props.check(false);
            return;
        }
    }

    handleBack = async() => {
        this.setState({
            cdn_selected: false,
            local_selected: false,
            upload_selected: false,
            download_type_selected: false,
            cdn_choosed: false,
            cdn_checked: false,
            upload_started: false,
            upload_finished: false,
            cdn_url: null,
            cdn_port: null,
            cdn_path: null,
            cdn_login: null,
            cdn_password: null,
            cdn_files: [],
            cdn_files_checked: [],
            cdn_connected: false
        });

        this.props.check(true);
    }

    componentDidMount() {
        this.props.check(true);
    }

    render() {

        let cdn_selected = this.state.cdn_selected;
        let local_selected = this.state.local_selected;
        let upload_selected = this.state.upload_selected;
        let cdn_choosed = this.state.cdn_choosed;
        let cdn_checked = this.state.cdn_checked;
        let cdn_connected = this.state.cdn_connected;
        let upload_started = this.state.upload_started;
        let upload_finished = this.state.upload_finished;
        let download_type_selected = this.state.download_type_selected;
        let cdn_files = this.state.cdn_files;
        let cdn_files_checked = this.state.cdn_files_checked;

        return (
            <Form fluid>
                {download_type_selected ? (
                    <>
                        {cdn_selected && (
                            cdn_choosed ? (
                                upload_finished ? (
                                    <div className='modal-fixed-custom'>
                                        <Form.Group style={{textAlign: 'center'}}>
                                            <span style={{display: "block", color: "green", fontSize: "48px", paddingBottom: "15px"}}><CheckOutlineIcon/></span>
                                            <span style={{display: "block"}}>Файлы успешно загружены!</span>
                                        </Form.Group>
                                    </div>
                                ):(
                                    <div className='modal-fixed-custom'>
                                        <Form.Group style={{textAlign: 'center'}}>
                                            {
                                                upload_started &&
                                                    <div style={{ width: 120, margin: 'auto' }}>
                                                        <Loader size="lg" content="Идет загрузка..." vertical />
                                                    </div>
                                            }
                                            {
                                                cdn_checked &&
                                                    <span style={{display: 'block', padding: '8px 10px'}}><b>Подключение к серверу: </b>Успешно</span>
                                            }
                                            {
                                                upload_started && 
                                                    <div style={{ display: 'block' }}>
                                                        <span style={{display: 'block', padding: '8px 10px'}}><b>Загрузка информационных баз: </b></span>
                                                        {cdn_files_checked.map((item) => (
                                                            <span style={{display: 'block', padding: '8px 10px'}}><b>{item}</b></span>
                                                        ))}
                                                    </div>
                                            }
                                            {
                                                !cdn_checked &&
                                                    <IconButton icon={<ArrowLeft />} onClick={this.handleBack}>Назад</IconButton>
                                            }
                                        </Form.Group>
                                    </div>
                                )  
                            ) : (
                                <div className='modal-fixed-custom'>
                                    <FlexboxGrid justify="center">
                                        <FlexboxGrid.Item colspan={cdn_connected? 12 : 24}>
                                            <Form.Group controlId="server">
                                                <Form.ControlLabel>Сервер</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><Link/></InputGroup.Addon>
                                                    <Form.Control name="server" type="text" readOnly={cdn_connected} onBlur={(event) => this.setState({ cdn_url: event.target.value.replaceAll(' ', '') })} placeholder="test.com (без протокола)"/>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group controlId="path">
                                                <Form.ControlLabel>Путь к файлам</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><Folder/></InputGroup.Addon>
                                                    <Form.Control name="path" type="text" readOnly={cdn_connected} onBlur={(event) => this.setState({ cdn_path: event.target.value })} placeholder="/path/to/infobases/files"/>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group controlId="port">
                                                <Form.ControlLabel>Порт</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><Numbers/></InputGroup.Addon>
                                                    <Form.Control name="port" accepter={InputNumber} readOnly={cdn_connected} onBlur={(event) => this.setState({ cdn_port: event.target.value })} placeholder="21"/>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group controlId="login">
                                                <Form.ControlLabel>Пользователь</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><User/></InputGroup.Addon>
                                                    <Form.Control name="login" type="text" autocomplete="off" readOnly={cdn_connected} onBlur={(event) => this.setState({ cdn_login: event.target.value.replaceAll(' ', '') })} placeholder="admin"/>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group controlId="password">
                                                <Form.ControlLabel>Пароль</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><Lock/></InputGroup.Addon>
                                                    <Form.Control name="password" type="password" autocomplete="off" readOnly={cdn_connected} onBlur={(event) => this.setState({ cdn_password: event.target.value })}/>
                                                </InputGroup>
                                            </Form.Group>
                                        </FlexboxGrid.Item>
                                        { 
                                            cdn_connected &&
                                                <FlexboxGrid.Item colspan={12} style={{padding: "25px 0 25px 25px"}}>
                                                    <List hover style={{height: "370px"}}>
                                                        {cdn_files.map((item, index) => (
                                                            <List.Item key={item['name']} index={index + 1}>
                                                                <FlexboxGrid>
                                                                    <FlexboxGrid.Item colspan={3} >
                                                                        <Checkbox value={item['name']} onChange={() => this.SelectFilesFromFTP()}/>
                                                                    </FlexboxGrid.Item>
                                                                    <FlexboxGrid.Item colspan={15} >
                                                                        <div style={{ textAlign: 'center', lineHeight: "36px", overflow: "hidden" }}>{item['name']}</div>
                                                                    </FlexboxGrid.Item>
                                                                    <FlexboxGrid.Item colspan={6}>
                                                                        <div style={{ textAlign: 'center', lineHeight: "36px" }}>
                                                                            {item['size']}
                                                                        </div>
                                                                    </FlexboxGrid.Item>
                                                                </FlexboxGrid>
                                                            </List.Item>
                                                        ))}
                                                    </List>
                                                </FlexboxGrid.Item>
                                        }
                                        <FlexboxGrid.Item colspan={24}>
                                            <Stack wrap spacing={10} justifyContent="space-between" style={{padding: "10px 0"}}>
                                                {
                                                    !cdn_connected &&
                                                        <IconButton icon={<ArrowLeft />} onClick={this.handleBack}>Назад</IconButton>
                                                }
                                                {
                                                    cdn_connected ? 
                                                        <IconButton icon={<Unlink />} onClick={this.handleDisconnect}>Отключиться</IconButton>:
                                                        <IconButton icon={<Link />} onClick={this.handleGetListOfFiles}>Подключиться</IconButton>
                                                }
                                                {
                                                    cdn_connected &&
                                                        <IconButton icon={<Download />} onClick={this.handleDownload}>Загрузить</IconButton>
                                                }
                                            </Stack>
                                        </FlexboxGrid.Item>
                                    </FlexboxGrid>
                                </div>
                            )
                        )}
                        {upload_selected && (
                            upload_finished ? (
                                <div className='modal-fixed-custom'>
                                    <Form.Group style={{textAlign: 'center'}}>
                                        <span style={{display: "block", color: "green", fontSize: "48px", paddingBottom: "15px"}}><CheckOutlineIcon/></span>
                                        <span style={{display: "block"}}>Файлы успешно загружены!</span>
                                    </Form.Group>
                                </div>
                            ):(
                                <div className='modal-fixed-custom'>
                                    <Uploader 
                                        action={config.url + config.api.public + '/upload/client'} 
                                        draggable 
                                        accept='.dt'
                                        removable={false}
                                        shouldQueueUpdate={(newFile) => this.CheckUploadAvailability(newFile)}
                                        onSuccess={(response, file) => this.UploadStatusChange(response, file)}
                                    >
                                        <div style={{ height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <span>Перетащите копии информационных баз (*.dt) в это поле для загрузки</span>
                                        </div>
                                    </Uploader>
                                    {
                                        !upload_started &&
                                            <IconButton icon={<ArrowLeft />} style={{marginTop: "15px"}} onClick={this.handleBack}>Назад</IconButton>
                                    }
                                    {
                                        upload_started &&
                                            <IconButton icon={<CheckOutlineIcon />} style={{marginTop: "15px"}} onClick={this.handleFinishUpload}>Готово</IconButton>
                                    }
                                </div>
                            )
                        )}
                        {local_selected && (
                            <div className='modal-fixed-custom'>
                                <Form.Group style={{textAlign: 'center'}}>
                                    <span style={{display: "block", color: "green", fontSize: "48px", paddingBottom: "15px"}}><CheckOutlineIcon/></span>
                                    <span style={{display: "block"}}>Будут использованы копии информационных баз из дистрибутива платформы</span>
                                </Form.Group>
                            </div>
                        )}
                    </>
                ):(
                    <RadioTileGroup aria-label="Тип загрузки" onChange={(value) => {this.changeType(value)}}>
                        <RadioTile icon={<Icon as={VscServer} />} label="Удаленный FTP/CDN" value="cdn">
                            Загрузка копий информационных баз (*.dt) из удаленного источника
                        </RadioTile>
                        <RadioTile icon={<Icon as={VscVm} />} label="Из локального источника" value="upload">
                            Загрузка копий информационных баз с локального диска пользователя
                        </RadioTile>
                        <RadioTile icon={<Icon as={VscFolderActive} />} label="Из дистрибутива" value="local">
                            Использовать копии информационных баз из дистрибутива платформы
                        </RadioTile>
                    </RadioTileGroup>
                )}
            </Form>
        );
    };
}

export default DownloadInfobases;