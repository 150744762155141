import React, { memo } from 'react';
import { FlexboxGrid } from 'rsuite';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';

//import icons;
import { AiOutlineFall, AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { MdOutlineTrendingUp } from 'react-icons/md';
import { GoDiff } from 'react-icons/go';
import { ImLink } from 'react-icons/im';

const StyledReactFlowButton = styled.span`
    display: block;
    font-size: 7px;
    padding: 3px;
    margin: 6px;
    background-color: #35824E;
    color: #fff;
    border-radius: 15px;
    text-align: center;

    &:hover {
    background-color: #5D9B71;
    }
`;

export default memo(({ data }) => {

  const displayConnexionsByOrgId = async(data) => {
    data.getConnexionsByOrganisationId(data.organisationId, data.nodeId);
  }

  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
      />
      <div style={{fontSize: "8px", margin: "5px", fontWeight: "bold", textAlign: "center"}}>{data.org_name}</div>
      <FlexboxGrid>
        <FlexboxGrid.Item colspan={12}>
            <div style={{fontSize: "7px", margin: "5px"}}><MdOutlineTrendingUp style={{color: "green", margin: "0 5px"}}/>{data.debt}</div>
            <div style={{fontSize: "7px", margin: "5px"}}><AiOutlineFall style={{color: "red", margin: "0 5px"}}/>{data.cr}</div>
            <div style={{fontSize: "7px", margin: "5px"}}><GoDiff style={{color: "orange", margin: "0 5px"}}/> {data.ext}</div>
            <div style={{fontSize: "7px", margin: "5px"}}><AiOutlineArrowUp style={{color: "green", margin: "0 5px"}}/> {data.ndsout}</div>
            <div style={{fontSize: "7px", margin: "5px"}}><AiOutlineArrowDown style={{color: "red", margin: "0 5px"}}/> {data.ndsin}</div>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={12}>
            <StyledReactFlowButton onClick={() => displayConnexionsByOrgId(data)} ><ImLink style={{margin: "auto 3px auto 0"}}/>Получить связи</StyledReactFlowButton>
            <div style={{fontSize: "7px", margin: "8px", textAlign: "center"}}>Связей: 0</div>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
      />
    </>
  );
});
