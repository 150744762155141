import axios from 'axios';
import fileDownload from 'js-file-download'
import config from '../config';

export async function getListOfDocumentsSales (infobaseName, organisationId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/list/sales', {organisationId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSelectedDocumentOfSale (infobaseName, documentId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/documents/' + infobaseName + '/sales/' + documentId).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSelectedDocumentOfProcurement (infobaseName, documentId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/documents/' + infobaseName + '/procurement/' + documentId).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSelectedDocumentOfSalary (infobaseName, documentId, documentType) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/salary/' + documentId, { documentType }).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getListOfDocumentsProcurement (infobaseName, organisationId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/list/procurement', {organisationId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getListOfDocumentsSchetFacturaOut (infobaseName, organisationId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/list/sf/outgoing', {organisationId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getListOfDocumentsSchetFacturaIn (infobaseName, organisationId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/list/sf/incoming', {organisationId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getReglamentReports (infobaseName, organisationId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/list/reglament', {organisationId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSelectedReglamentReport (infobaseName, documentId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/documents/' + infobaseName + '/reglament/' + documentId, { responseType: "blob" }).then(async (response)=>{
            const file = new Blob([response.data], {type: 'application/pdf'});
            resolve(file);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSalaryJournalFromInfobase (infobaseName, organisationId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/list/salary', {organisationId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getOrganisationCardInPDF (organisationId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/catalog/' + organisationId + '/pdf', { responseType: "blob" }).then(async (response)=>{
            const filename = response.headers['content-disposition'].split('filename=')[1];
            fileDownload(response.data, filename + "_organisation.pdf")
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function syncContragentsFromInfobaseToDatabase (infobaseName) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.info + '/infobase/' + infobaseName + '/sync/contragents').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function syncOrganisationsFromInfobaseToDatabase (infobaseName) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.info + '/infobase/' + infobaseName + '/sync/organisations').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getReportsTypesFromOdata (infobaseName) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/catalog/' + infobaseName + '/reports/standart/list').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSelectedDocumentPrintForm (infobaseName, documentId, printformType, docType) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/printform/' + documentId, {printformType, docType}, { responseType: "blob" }).then(async (response)=>{
            const filename = response.headers['content-disposition'].split('filename=')[1];
            fileDownload(response.data, printformType + "-" + filename + "form.pdf")
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getBook (infobaseName, bookType, organisationExternalId, periodStart, periodEnd) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.documents + '/documents/' + infobaseName + '/book', {bookType, organisationExternalId, periodStart, periodEnd}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}