import React from 'react';
import { Container, Header, Panel, FlexboxGrid, Table, Dropdown, Popover, Whisper, IconButton, Form, ButtonToolbar, SelectPicker, InputGroup, Pagination, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import common
import ConfirmModal from "../Common/ConfirmModal";
import UserDataModal from "../Common/UserDataModal";
import UserPasswordChange from '../Common/UserPasswordChange';

//import icons
import { FaUserFriends } from 'react-icons/fa';
import MoreIcon from '@rsuite/icons/More';
import CharacterLockIcon from '@rsuite/icons/CharacterLock';
import CharacterAuthorizeIcon from '@rsuite/icons/CharacterAuthorize';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import IdInfoIcon from '@rsuite/icons/IdInfo';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import EmailIcon from '@rsuite/icons/Email';
import DataAuthorizeIcon from '@rsuite/icons/DataAuthorize';
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import ParagraphIcon from '@rsuite/icons/Paragraph';
import Lock from '@rsuite/icons/legacy/Lock';
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch';
import Check from '@rsuite/icons/legacy/Check';
import Phone from '@rsuite/icons/legacy/Phone';
import Telegram from '@rsuite/icons/legacy/Telegram';

//import controller functions
import { getRoles, getUsers, createUser, changeStatusForUser, verifyUser, getUserInfo, updateUserKcPassword } from '../../controllers/userController';

const { Column, HeaderCell, Cell } = Table;

class Users extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: null,
            username: '',
            password: '',
            status: '',
            fullname: '',
            user_1c: '',
            pswd_1c: '',
            telegram: '',
            phone: '',
            role: null,
            email: '',
            user_list: [],
            roles: [],
            limit: 10,
            page: 1,
            confirm: false,
            caption: '',
            text: "",
            buttonIcon: null,
            buttonOkText: '',
            onAction: null,
            actionRequestBody: null,
            color: "",
            info_modal: false,
            info_modal_data: null,
            password_modal: false,
            user_edit: false
        };
        this.handleChangeLimit = this.handleChangeLimit.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.getRoles = this.getRoles.bind(this);
        this.getUsers = this.getUsers.bind(this);
        this.createUser = this.createUser.bind(this);
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangeFullname = this.handleChangeFullname.bind(this);
        this.handleChangeOneSLogin = this.handleChangeOneSLogin.bind(this);
        this.handleChangeOneSPassword = this.handleChangeOneSPassword.bind(this);
        this.handleChangeMail = this.handleChangeMail.bind(this);
        this.handleChangePhone = this.handleChangePhone.bind(this);
        this.handleChangeTelegram = this.handleChangeTelegram.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeRole = this.handleChangeRole.bind(this);
        this.handleResetFields = this.handleResetFields.bind(this);
        this.updateEntries = this.updateEntries.bind(this);
        this.handleCreateUserClick = this.handleCreateUserClick.bind(this);
        this.handleVerifyUserClick = this.handleVerifyUserClick.bind(this);
        this.handleBlockUserClick = this.handleBlockUserClick.bind(this);
        this.handleUnblockUserClick = this.handleUnblockUserClick.bind(this);
        this.handleDeleteUserClick = this.handleDeleteUserClick.bind(this);
        this.handleGetUserInfoClick = this.handleGetUserInfoClick.bind(this);
        this.handleChangePasswordClick = this.handleChangePasswordClick.bind(this);
        this.confirmAction = this.confirmAction.bind(this);
        this.verifyUser = this.verifyUser.bind(this);
        this.blockUser = this.blockUser.bind(this);
        this.unblockUser = this.unblockUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
    }

    getRoles = async() => {
        let roles = await getRoles();
        this.setState({
            roles: roles.data,
        })
    }

    getUsers = async() => {
        const toaster = this.props.toaster;

        try{

            let users = await getUsers();
            this.setState({
                user_list: users.data,
            })

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Вы не администратор!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleChangePasswordClick = async(rowData) => {
        const toaster = this.props.toaster;

        try {

            let userId = rowData.userId;

            this.setState({ password_modal: true, userId: userId, caption: `Изменение пароля для ${rowData.username}`, onAction: this.updatePassword });
            
        } catch (err) {
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{err.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    updatePassword = async(userId, password) => {
        const toaster = this.props.toaster;

        try {

            let fields = {
                password: password
            }

            let request = await updateUserKcPassword(userId, fields);
            toaster.push(<Notification showIcon header={request.success === true ? 'Информация' : 'Ошибка'} type={request.success === true ? 'success' : 'error'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
            
        } catch (err) {
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{err.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleGetUserInfoClick = async(rowData) => {
        const toaster = this.props.toaster;

        try {

            let userId = rowData.userId;

            let data = await getUserInfo(userId);

            this.setState({ info_modal: true, info_modal_data: data });
            
        } catch (err) {
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{err.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleCreateUserClick = async() => {
        const toaster = this.props.toaster;

        let fields = {
            username: this.state.username,
            password: this.state.password,
            statusId: 3,
            roleId: this.state.role,
            fullname: this.state.fullname,
            userOneSLogin: this.state.user_1c,
            userOneSPassword: this.state.pswd_1c,
            email: this.state.email,
            telegram: this.state.telegram,
            phone: this.state.phone
        }

        if(isEmpty(this.state.username) || isNull((this.state.username))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Поле Username должно быть заполнено!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.password) || isNull((this.state.password))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Поле Пароль должно быть заполнено!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.phone) || isNull(this.state.phone)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите номер телефона!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.telegram) || isNull(this.state.telegram)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите telegram (username)!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isNull((this.state.role))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Вы должны выбрать роль!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }


        this.confirmAction("blue", "Создание пользователя", `Создать пользователя ${fields.username}?`, <Check/>, "Создать", this.createUser, fields );

    }

    handleVerifyUserClick = async(rowData) => {
        const toaster = this.props.toaster;

        let fields = {
            userId: rowData.userId
        }

        if(isNull((rowData.userId))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пользователь не выбран!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("green", "Верификация пользователя", `Верифицировать пользователя ${rowData.username}?`, <CharacterAuthorizeIcon/>, "Верифицировать", this.verifyUser, fields );
    }

    handleBlockUserClick = async(rowData) => {
        const toaster = this.props.toaster;

        let fields = {
            userId: rowData.userId
        }

        if(isNull((rowData.userId))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пользователь не выбран!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("red", "Блокировка пользователя", `Заблокировать пользователя ${rowData.username}?`, <CharacterLockIcon/>, "Заблокировать", this.blockUser, fields );
    }

    handleUnblockUserClick = async(rowData) => {
        const toaster = this.props.toaster;

        let fields = {
            userId: rowData.userId
        }

        if(isNull((rowData.userId))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пользователь не выбран!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("blue", "Разблокировка пользователя", `Разблокировать пользователя ${rowData.username}?`, <Check/>, "Разблокировать", this.unblockUser, fields );
    }

    handleDeleteUserClick = async(rowData) => {
        const toaster = this.props.toaster;

        let fields = {
            userId: rowData.userId
        }

        if(isNull((rowData.userId))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пользователь не выбран!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        this.confirmAction("red", "Удаление пользователя", `Удалить пользователя ${rowData.username}?`, <TrashIcon/>, "Удалить", this.deleteUser, fields );
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm: true, 
            color: color, 
            caption: caption, 
            text: text, 
            buttonIcon: buttonIcon, 
            buttonOkText: buttonOkText, 
            onAction: onAction, 
            actionRequestBody: actionRequestBody
        });
    }

    verifyUser = async(fields) => {
        const toaster = this.props.toaster;

        try{
            let params = {
                statusId: 1
            }

            let userId = fields.userId;

            let request = await verifyUser(userId, params);
            toaster.push(<Notification showIcon header={request.success === true ? 'Информация' : 'Ошибка'} type={request.success === true ? 'success' : 'error'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }
    blockUser = async(fields) => {
        const toaster = this.props.toaster;

        try{
            let params = {
                statusId: 2
            }

            let userId = fields.userId;

            let request = await changeStatusForUser(userId, params);
            toaster.push(<Notification showIcon header={request.success === true ? 'Информация' : 'Ошибка'} type={request.success === true ? 'success' : 'error'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }
    unblockUser = async(fields) => {
        const toaster = this.props.toaster;

        try{

            let params = {
                statusId: 3
            }

            let userId = fields.userId;

            let request = await changeStatusForUser(userId, params);
            toaster.push(<Notification showIcon header={request.success === true ? 'Информация' : 'Ошибка'} type={request.success === true ? 'success' : 'error'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }
    deleteUser = async(fields) => {
        console.log(fields);
    }

    createUser = async(fields) => {
        const toaster = this.props.toaster;

        try{
            let request = await createUser(fields);

            toaster.push(<Notification showIcon header={'Информация'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
    
            this.setState(
                {
                    userId: null,
                    username: '',
                    password: '',
                    status: '',
                    fullname: '',
                    user_1c: '',
                    pswd_1c: '',
                    role: '',
                    email: '',
                    telegram: '',
                    phone: '',
                }
            );
        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.updateEntries();
    }

    componentDidMount() {
        this.updateEntries();
    }

    updateEntries = async() => {
        this.getRoles();
        this.getUsers();
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    handleChangeUsername = (value) => {
        this.setState({username: value});
    };

    handleChangeFullname = (value) => {
        this.setState({fullname: value});
    };

    handleChangeOneSLogin = (value) => {
        this.setState({user_1c: value});
    };

    handleChangeOneSPassword = (value) => {
        this.setState({pswd_1c: value});
    };

    handleChangeMail = (value) => {
        this.setState({email: value});
    };

    handleChangePhone = (value) => {
        this.setState({phone: value});
    };

    handleChangeTelegram = (value) => {
        this.setState({telegram: value});
    };

    handleChangePassword = (value) => {
        this.setState({password: value});
    };

    handleChangeRole = (value) => {
        this.setState({role: value});
    };

    handleResetFields = () => {
        const toaster = this.props.toaster;
        this.setState(
            {
                userId: null,
                username: '',
                password: '',
                status: '',
                fullname: '',
                user_1c: '',
                pswd_1c: '',
                role: '',
                email: '',
            }
        );
        toaster.push(<Notification showIcon header={'Информация'} type={'success'}>Поля очищены!</Notification>, {
            duration: 10000,
            placement: 'topEnd'
        });
    };
    
    render() {

        let userId = this.state.userId;
        let roles = this.state.roles;
        let users = this.state.user_list;
        const confirm = this.state.confirm;
        const info_modal = this.state.info_modal;
        const password_modal = this.state.password_modal;
        const user_edit = this.state.user_edit;

        const role_list = roles.map(
            item => ({ label: item.roleName, value: item.roleId })
        );
        
        const StatusCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {
                    rowData.statusId === 1 ? <CharacterAuthorizeIcon style={{color: '#00FF00'}}/> :
                    (rowData.statusId === 2 ? <CharacterLockIcon style={{color: '#FA5F55'}}/> :
                    <OperatePeopleIcon style={{color: '#fff'}}/>)
                }
            </Cell>
        );

        const RoleCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { 
                    rowData.roleId === 1 ? 'Главный администратор':
                    (rowData.roleId === 2 ? 'Администратор':
                    (rowData.roleId === 3 ? 'Бухгалтер': 
                    (rowData.roleId === 4 ? 'Организация': "Агент")))
                }
            </Cell>
        );
              
        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
              <Cell {...props} className="link-group">
                <Whisper 
                    placement="autoVerticalStart"
                    trigger="click" 
                    speaker={(props, ref) => {
                        const { onClose, left, top, className } = props;
                        const handleSelect = eventKey => {
                            if (eventKey === 1){
                                this.handleGetUserInfoClick(rowData);
                            }
                            if (eventKey === 2){
                                this.handleVerifyUserClick(rowData);
                            }
                            if (eventKey === 3){
                                rowData.statusId === 2 ?
                                    this.handleUnblockUserClick(rowData) :
                                    this.handleBlockUserClick(rowData)
                            }
                            if (eventKey === 4){
                                
                            }
                            if (eventKey === 5){
                                this.handleChangePasswordClick(rowData);
                            }
                            if (eventKey === 6){
                                
                            }
                            if (eventKey === 7){
                                this.handleDeleteUserClick(rowData);
                            }
                            onClose();
                        };
                        return (
                            <Popover ref={ref} className={className} style={{ left, top }} full>
                                <Dropdown.Menu onSelect={handleSelect}>
                                    <Dropdown.Item eventKey={1} icon={<UserInfoIcon />} >Информация о пользователе</Dropdown.Item>
                                    <Dropdown.Item divider />
                                    {rowData.statusId === 3 &&
                                        <Dropdown.Item eventKey={2} icon={<CharacterAuthorizeIcon />} >Верифицировать</Dropdown.Item>
                                    }
                                    <Dropdown.Item eventKey={3} icon={<CharacterLockIcon />} >{rowData.statusId === 2 ? "Разблокировать" : "Заблокировать"}</Dropdown.Item>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item eventKey={4} icon={<PeopleBranchIcon />} >Связать с пользователем 1С</Dropdown.Item>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item eventKey={5} icon={<DataAuthorizeIcon />} >Изменить пароль</Dropdown.Item>
                                    <Dropdown.Item divider />
                                    <Dropdown.Item eventKey={6} icon={<EditIcon />} >Редактировать</Dropdown.Item>
                                    <Dropdown.Item eventKey={7} icon={<TrashIcon />} >Удалить</Dropdown.Item>
                                </Dropdown.Menu>
                            </Popover>
                        )
                    }}
                >
                  <IconButton appearance="subtle" icon={<MoreIcon />} />
                </Whisper>
              </Cell>
            );
        };

        const data = users.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaUserFriends/></i> Пользователи</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <Form fluid>
                                                <Form.Group controlId="username">
                                                    <Form.ControlLabel>Username*:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><OperatePeopleIcon/></InputGroup.Addon>
                                                        <Form.Control name="username" onChange={this.handleChangeUsername} value={this.state.username}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="fullname">
                                                    <Form.ControlLabel>Фамилия Имя:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><ParagraphIcon/></InputGroup.Addon>
                                                        <Form.Control name="fullname" onChange={this.handleChangeFullname} value={this.state.fullname}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="user_connexion_login">
                                                    <Form.ControlLabel>Пользователь в 1С:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><PeopleBranchIcon/></InputGroup.Addon>
                                                        <Form.Control name="user_connexion_login" onChange={this.handleChangeOneSLogin} value={this.state.user_1c}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="user_connexion_password">
                                                    <Form.ControlLabel>Пароль в 1С:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><DataAuthorizeIcon/></InputGroup.Addon>
                                                        <Form.Control name="user_connexion_password" onChange={this.handleChangeOneSPassword} type="password" value={this.state.pswd_1c}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="telegram">
                                                    <Form.ControlLabel>Telegam (имя пользователя)*</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><Telegram/></InputGroup.Addon>
                                                        <Form.Control name="telegram" onChange={this.handleChangeTelegram} value={this.state.telegram}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="phone">
                                                    <Form.ControlLabel>Номер телефона*</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><Phone/></InputGroup.Addon>
                                                        <Form.Control name="phone" onChange={this.handleChangePhone} value={this.state.phone}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="email">
                                                    <Form.ControlLabel>Email:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon>@</InputGroup.Addon>
                                                        <Form.Control name="email" onChange={this.handleChangeMail} type="email" value={this.state.email}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="password">
                                                    <Form.ControlLabel>Пароль*:</Form.ControlLabel>
                                                    <InputGroup>
                                                        <InputGroup.Addon><Lock/></InputGroup.Addon>
                                                        <Form.Control name="password" onChange={this.handleChangePassword} type="password" value={this.state.password}/>
                                                    </InputGroup>
                                                </Form.Group>
                                                <Form.Group controlId="role">
                                                    <Form.ControlLabel>Роль*:</Form.ControlLabel>
                                                    <Form.Control name="role" accepter={SelectPicker} data={role_list} value={this.state.role} onChange={this.handleChangeRole} style={{ width: '100%' }} cleanable={false} searchable={false} placeholder='Роль пользователя'/>
                                                </Form.Group>
                                                <Form.Group>
                                                    <ButtonToolbar>
                                                        {userId !== null && userId !== undefined && user_edit === true ?
                                                            <IconButton icon={<EditIcon />}> Обновить</IconButton> :
                                                            <IconButton icon={<CheckOutlineIcon />} onClick={this.handleCreateUserClick}> Создать</IconButton>
                                                        }           
                                                        <IconButton icon={<CloseOutlineIcon />} onClick={this.handleResetFields}> Очистить</IconButton>
                                                    </ButtonToolbar>
                                                </Form.Group>
                                            </Form>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <Table data={data} height={560} rowHeight={64} virtualized>

                                                <Column width={60} align="center" verticalAlign="middle">
                                                    <HeaderCell>Статус</HeaderCell>
                                                    <StatusCell dataKey="statusId" />
                                                </Column>

                                                <Column width={60} align="center" verticalAlign="middle">
                                                    <HeaderCell><IdInfoIcon/> ID</HeaderCell>
                                                    <Cell dataKey="userId" />
                                                </Column>

                                                <Column width={250} align="center" verticalAlign="middle">
                                                    <HeaderCell><OperatePeopleIcon/> Имя пользователя</HeaderCell>
                                                    <Cell dataKey="username" />
                                                </Column>

                                                <Column width={180} align="center" verticalAlign="middle">
                                                    <HeaderCell><UserBadgeIcon/> Роль</HeaderCell>
                                                    <RoleCell dataKey="roleId" />
                                                </Column>

                                                <Column flexGrow={1} align="center" verticalAlign="middle">
                                                    <HeaderCell>...</HeaderCell>
                                                    <ActionCell dataKey="userId" />
                                                </Column>
                                            </Table>
                                            <div style={{ padding: 20 }}>
                                                <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={users.length} limitOptions={[10, 30, 50]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit}/>
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                {confirm && 
                    <ConfirmModal
                        open={this.state.confirm} 
                        onClose={() => this.setState({ 
                            confirm: false,
                            caption: '',
                            text: "",
                            buttonIcon: null,
                            buttonOkText: '',
                            onAction: null,
                            actionRequestBody: null,
                            color: "",
                        })} 
                        color={this.state.color} 
                        caption={this.state.caption} 
                        text={this.state.text} 
                        buttonIcon={this.state.buttonIcon} 
                        buttonOkText={this.state.buttonOkText} 
                        onAction={this.state.onAction} 
                        actionRequestBody={this.state.actionRequestBody}
                    />
                }
                {info_modal && <UserDataModal open={this.state.info_modal} onClose={() => this.setState({ info_modal: false })} data={this.state.info_modal_data}/>}
                {password_modal && <UserPasswordChange open={this.state.password_modal} onClose={() => this.setState({ password_modal: false })} userId={this.state.userId} caption={this.state.caption} onAction={this.state.onAction} toaster={this.props.toaster}/>}
            </main>    
        );
    };
}

export default Users;