import React from 'react';
import { IconButton, Modal, Form, SelectPicker } from 'rsuite';
import { isEmpty } from "lodash";

//import icons
import CloseIcon from '@rsuite/icons/Close';
import Save from '@rsuite/icons/legacy/Save';

const FILE_TYPES = ["xml", "xls"];
const ARCHIVE_TYPES = ["tar.gz", "rar", "zip"];
const REPORT_ARCHIVE_TYPES = ["/тип_отчета/база_данных/имя_отчета.[xml/xls]", "/тип_отчета/имя_отчета/база_данных.[xml/xls]"];

class SaveWizard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const file_type = FILE_TYPES.map(
            item => ({ label: item, value: item })
        );

        const archive_type = ARCHIVE_TYPES.map(
            item => ({ label: item, value: item })
        );

        const report_archive_type = REPORT_ARCHIVE_TYPES.map(
            item => ({ label: item, value: item })
        );

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleSaveClick = () => {
            console.log("FORM SUBMITTED");
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="sm">
                <Modal.Header>
                    <Modal.Title>Сохранить отчет в файл</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <div className='modal-fixed-custom'>
                            <Form.Group controlId="typeFile">
                                <Form.ControlLabel>Тип файла:</Form.ControlLabel>
                                <Form.Control name="typeFile" accepter={SelectPicker} data={file_type} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Тип файла'/>
                            </Form.Group>
                            <Form.Group controlId="typeArchive">
                                <Form.ControlLabel>Тип архива:</Form.ControlLabel>
                                <Form.Control name="typeArchive" accepter={SelectPicker} data={archive_type} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Тип архива'/>
                            </Form.Group>
                            <Form.Group controlId="reportArchiveType">
                                <Form.ControlLabel>Тип архивации отчетов:</Form.ControlLabel>
                                <Form.Control name="reportArchiveType" accepter={SelectPicker} data={report_archive_type} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Тип архивации отчетов'/>
                                <Form.HelpText>Влияет на расположение отчетов внутри архива.</Form.HelpText>
                            </Form.Group>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Save />} onClick={handleSaveClick}>Сохранить</IconButton>
                    <IconButton icon={<CloseIcon />} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default SaveWizard;