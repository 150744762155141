import React from 'react';
import { IconButton, Modal, Form } from 'rsuite';
import { isEmpty } from 'lodash';

//import icons
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

class SyncParametersModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="xs">
                <Modal.Title>Параметры синхронизации</Modal.Title>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="name-1">
                            <Form.ControlLabel>Пользователь</Form.ControlLabel>
                            <Form.Control name="usernameSync" />
                            <Form.HelpText>Введите имя пользователя, который отвечает за синхронизацию во всех базах данных 1С</Form.HelpText>
                        </Form.Group>
                        <Form.Group controlId="password-1">
                            <Form.ControlLabel>Пароль</Form.ControlLabel>
                            <Form.Control name="userpasswordSync" type="password" autoComplete="off" />
                            <Form.HelpText>Введите пароль пользователя.</Form.HelpText>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CheckIcon />} onClick={handleCloseClick}>Сохранить</IconButton>
                    <IconButton icon={<CloseIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default SyncParametersModal;