import React from 'react';
import { Container, Header, Checkbox, FlexboxGrid, Tooltip, Whisper, IconButton, Table, InputGroup, Input, SelectPicker, Stack, Pagination, Panel, ButtonGroup, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import { FaBriefcase } from 'react-icons/fa';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import ReloadIcon from '@rsuite/icons/Reload';
import Edit from '@rsuite/icons/Edit';
import Trash from '@rsuite/icons/legacy/Trash';
import Refresh from '@rsuite/icons/legacy/Refresh';
import ConversionIcon from '@rsuite/icons/Conversion';

//import components
import Synchronization from '../Common/Synchronization';

//import common
import ConfirmModal from "../Common/ConfirmModal";

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getContragents } from '../../controllers/clusterController';
import { syncContragentsFromInfobaseToDatabase } from '../../controllers/documentsController';

const { Column, HeaderCell, Cell } = Table;

class Contragents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            infobaseName: null,
            contragents: [],
            loading: false,
            modal_loading: false,
            sync_modal_open: false,
            confirm: false,
            caption: '',
            text: "",
            buttonIcon: null,
            buttonOkText: '',
            onAction: null,
            actionRequestBody: null,
            color: "",
            success: false
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getContragents = this.getContragents.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.syncData = this.syncData.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getContragents = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value });

            this.setTableLoading();
            
            let contragents = await getContragents(value);

            this.setState({
                contragents: contragents.data,
                loading: false
            });

        }catch(e){

            this.setState({
                organisations: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Контрагенты отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    handleSyncData = async() => {

        const toaster = this.props.toaster;

        if(isNull((this.state.infobaseName))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>База данных не выбрана!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let fields = {
            infobaseName: this.state.infobaseName,
        }

        this.confirmAction("blue", "Синхронизация элементов", `Синхронизировать контрагентов с базой данных ${this.state.infobaseName}?`, <ConversionIcon style={{color: '#fff'}}/>, "Начать синхронизацию", this.syncData, fields );
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm: true, 
            color: color, 
            caption: caption, 
            text: text, 
            buttonIcon: buttonIcon, 
            buttonOkText: buttonOkText, 
            onAction: onAction, 
            actionRequestBody: actionRequestBody
        });
    }

    syncData = async() => {

        const toaster = this.props.toaster;

        try{

            this.setState({
                sync_modal_open: true,
                modal_loading: true
            });

            if(isNull((this.state.infobaseName))){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>База данных не выбрана!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            let data_sync = await syncContragentsFromInfobaseToDatabase(this.state.infobaseName);
            toaster.push(<Notification showIcon header={data_sync.success === true ? 'Информация' : 'Ошибка'} type={data_sync.success === true ? 'success' : 'error'}>{data_sync.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

            this.setState({
                modal_loading: false,
                success: data_sync.success === true ? true : false
            });

            if (data_sync.success === true) {
                this.getContragents(this.state.infobaseName);
            }
        
        }catch(e){

            this.setState({
                modal_loading: false,
                success: false
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка синхронизации организаций!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let infobaseName = this.state.infobaseName;
        let contragent = this.state.contragents;

        const confirm = this.state.confirm;
        const sync_modal_open = this.state.sync_modal_open;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const data = contragent.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const NdsCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { !isNull(rowData.nds) ? (
                    rowData.nds
                ) : (
                    <span>Не указан</span>
                )}
            </Cell>
        );

        const AgentCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { !isNull(rowData.agentId) ? (
                    rowData.agentId
                ) : (
                    <span>Отсутствует</span>
                )}
            </Cell>
        );

        const APercentCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { !isNull(rowData.agent_percent) ? (
                    rowData.agent_percent
                ) : (
                    <span>Не указан</span>
                )}
            </Cell>
        );

        const WebsiteCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { !isNull(rowData.website) ? (
                    rowData.website
                ) : (
                    <span>Отсутствует</span>
                )}
            </Cell>
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaBriefcase/></i> Контрагенты</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                        <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} onChange={(value) => this.getContragents(value)} placeholder='Информационная база'/>
                                        <IconButton icon={<AddOutlineIcon />} disabled={!isNull(infobaseName) ? false : true}>Добавить</IconButton>
                                        <IconButton icon={<ReloadIcon />} disabled={!isNull(infobaseName) ? false : true}>Обновить</IconButton>
                                        <Whisper placement='top' speaker={<Tooltip>Синхронизировать с базой данных</Tooltip>}>
                                            <IconButton icon={<Refresh />} disabled={!isNull(infobaseName) ? false : true} onClick={() => this.handleSyncData()}/>
                                        </Whisper>
                                        <InputGroup inside style={{ width: 200 }}>
                                            <Input />
                                            <InputGroup.Button>
                                                <SearchIcon />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="contragentId" />
                                    </Column>
                                    <Column width={270} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Наименование</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={250} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Тип</HeaderCell>
                                        <Cell dataKey="type" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>ИНН</HeaderCell>
                                        <Cell dataKey="inn" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>КПП</HeaderCell>
                                        <Cell dataKey="kpp" />
                                    </Column>
                                    <Column width={90} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>НДС, %</HeaderCell>
                                        <NdsCell dataKey="nds" />
                                    </Column>
                                    <Column width={90} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>ID Агента</HeaderCell>
                                        <AgentCell dataKey="agentId" />
                                    </Column>
                                    <Column width={90} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>% агента</HeaderCell>
                                        <APercentCell dataKey="agent_percent" />
                                    </Column>
                                    <Column width={140} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Сайт</HeaderCell>
                                        <WebsiteCell dataKey="website" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <Cell>                                               
                                            <ButtonGroup>
                                                <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                                                    <IconButton icon={<SearchIcon />} externalId={data.contragentExternalId} />
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Редактировать</Tooltip>}>
                                                    <IconButton icon={<Edit />} externalId={data.contragentExternalId}/>
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                                                    <IconButton icon={<Trash />} externalId={data.contragentExternalId}/>
                                                </Whisper>
                                            </ButtonGroup>
                                        </Cell>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={contragent.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {sync_modal_open && 
                    <Synchronization 
                        open={this.state.sync_modal_open} 
                        onClose={() => this.setState({ 
                                sync_modal_open: false, 
                                modal_loading: false,
                                success: false
                            })} 
                        modal_loading={this.state.modal_loading}
                        syncType={"контрагентов"}
                        infobaseName={this.state.infobaseName}
                        success={this.state.success}
                    />
                }
                {confirm && 
                    <ConfirmModal
                        open={this.state.confirm} 
                        onClose={() => this.setState({ 
                            confirm: false,
                            caption: '',
                            text: "",
                            buttonIcon: null,
                            buttonOkText: '',
                            onAction: null,
                            actionRequestBody: null,
                            color: "",
                        })} 
                        color={this.state.color} 
                        caption={this.state.caption} 
                        text={this.state.text} 
                        buttonIcon={this.state.buttonIcon} 
                        buttonOkText={this.state.buttonOkText} 
                        onAction={this.state.onAction} 
                        actionRequestBody={this.state.actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default Contragents;
