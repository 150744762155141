import React from 'react';
import { Container, Header, SelectPicker, Panel, Form, ButtonToolbar, IconButton, Checkbox, DateRangePicker, FlexboxGrid, Notification, Stack, Uploader } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import { FaDatabase } from 'react-icons/fa';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import FileUploadIcon from '@rsuite/icons/FileUpload';

//import common
import predifinedVars from '../Common/predifinedVars';
import NewInfobaseProgress from '../Common/NewInfobaseProgress';
import ConfirmModal from '../Common/ConfirmModal';

//import controllers
import { getClusterList, createNewInfobase, disableConfigurationSupport, getAllInfobasesByClusterId, restoreInfobase, getInitListOfIB, getAvailableLevelsOfIB, createExtension, loadExtension, applyConfig, checkFileExist, publishInfobase, activateOData, addInfobaseToDatabase, getAvailableInfobasesByLevels, addLevelsToInfobase } from '../../controllers/clusterController';

import config from '../.././config';

class NewDB extends React.Component {
    constructor(props) {
        super(props);
      
        this.state = {
            syncDisable: true,
            syncActive: false,
            syncSource: null,
            copyDisable: true,
            copyActive: false,
            infobaseName: "",
            infobaseDescr: "",
            infobaseLevel: null,
            infobaseParent: null,
            adminOnecLogin: "",
            adminOnecPass: "",
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            infobasesLocal: [],
            levels: [],
            infobasesParents: [],
            infobasesParentsId: null,
            localCopy: false,
            remoteCopy: false,
            copySourceLocal: null,
            copySourceRemote: null,
            uploading: false,
            confirm_modal: false,
            confirm_modal_caption: '',
            confirm_modal_text: null,
            confirm_modal_buttonIcon: null,
            confirm_modal_buttonOkText: '',
            confirm_modal_onAction: null,
            confirm_modal_actionRequestBody: null,
            confirm_modal_color: "",
            pr_modal: false,
            pr_modal_progress: 0,
            pr_modal_progress_error: false,
            pr_modal_create_ib_response: null,
            pr_modal_create_dump_response: null,
            pr_modal_restore_dump_response: null,
            pr_modal_pub_ib_response: null,
            pr_modal_disable_support_response: null,
            pr_modal_create_ext_response: null,
            pr_modal_load_ext_response: null,
            pr_modal_apply_ext_response: null,
            pr_modal_activate_odata_response: null,
            pr_modal_assign_level_response: null,
            pr_modal_ib_to_db_response: null,
            pr_modal_skip_dump: false,
            pr_modal_copy_active: false
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getListOfIB = this.getListOfIB.bind(this);
        this.addNewInfobase = this.addNewInfobase.bind(this);
        this.confirmAction = this.confirmAction.bind(this);
        this.handleCreateInfobaseClick = this.handleCreateInfobaseClick.bind(this);
        this.UploadStatusChange = this.UploadStatusChange.bind(this);
        this.CheckUploadAvailability = this.CheckUploadAvailability.bind(this);
        this.getInfobasesParents = this.getInfobasesParents.bind(this);
        this.setProgress = this.setProgress.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
    }

    setProgress = async(progress) => {
        this.setState({ pr_modal_progress: progress });
    }

    handleCreateInfobaseClick = async() => {
        const toaster = this.props.toaster;

        let fields = {
            clusterId: this.state.clusterId,
            infobaseName: this.state.infobaseName,
            infobaseDescr: this.state.infobaseDescr,
            infobaseLevel: this.state.infobaseLevel,
            infobaseParent: this.state.infobaseParent,
            adminOnecLogin: this.state.adminOnecLogin,
            adminOnecPass: this.state.adminOnecPass,
            copyActive: this.state.copyActive,
            copySourceLocal: this.state.copySourceLocal,
            copySourceRemote: this.state.copySourceRemote,
            localCopy: this.state.localCopy,
            remoteCopy: this.state.remoteCopy,
            syncActive: this.state.syncActive,
            syncSource: this.state.syncSource
        }

        if(isEmpty(this.state.clusterId)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пожалуйста, выберите кластер для создания базы данных!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if(isEmpty(this.state.infobaseName)){
            return (
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пожалуйста, укажите имя базы данных!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        const Text = () => (
            <div>
                <span style={{display:"block"}}>Создать информационную базу {this.state.infobaseName} со следующими параметрами?</span>
                <br/>
                <span style={{display:"block"}}>Параметры:</span>
                <span style={{display:"block"}}>Кластер: {this.state.clusterId}</span>
                <span style={{display:"block"}}>Имя информационной базы: {this.state.infobaseName}</span>
                <span style={{display:"block"}}>Описание информационной базы: {isEmpty(this.state.infobaseDescr) ? <b style={{color: "red"}}>Не задано</b> : this.state.infobaseDescr}</span>
                <span style={{display:"block"}}>Уровень информационной базы: {isNull(this.state.infobaseLevel) ? <b style={{color: "red"}}>Не указан</b> : this.state.infobaseLevel}</span>
                <span style={{display:"block"}}>Родитель информационной базы: {isNull(this.state.infobaseParent) ? <b style={{color: "red"}}>Не указан</b> : this.state.infobaseParent}</span>
                <span style={{display:"block"}}>Администратор информационной базы: {isEmpty(this.state.adminOnecLogin) ? <b style={{color: "red"}}>Не указан</b> : this.state.adminOnecLogin}</span>
                <span style={{display:"block"}}>Пароль администратора: {isEmpty(this.state.adminOnecPass) ? <b style={{color: "red"}}>Не указан</b> : <b>*</b>}</span>
                <span style={{display:"block"}}>Копировать данные: {this.state.copyActive === false ? <b style={{color: "red"}}>Нет</b> : <b style={{color: "green"}}>Да</b>}</span>
                {this.state.copyActive === true  &&
                    <span style={{display:"block"}}>
                        Источник: 
                        {(isNull(this.state.copySourceLocal) && isNull(this.state.copySourceRemote)) && <b style={{color: "red"}}> Не указан</b>}
                        {!isNull(this.state.copySourceRemote) && this.state.copySourceRemote}
                        {!isNull(this.state.copySourceLocal) && this.state.copySourceLocal}
                    </span>
                }
                {this.state.copyActive === true  &&
                    <span style={{display:"block"}}>
                        Тип: 
                        {(this.state.localCopy === false && this.state.remoteCopy === false) && <b style={{color: "red"}}> Не указан</b>}
                        {this.state.localCopy === true && <b style={{color: "green"}}> Из файла</b>}
                        {this.state.remoteCopy === true && <b style={{color: "green"}}> Серверный</b>}
                    </span>
                }
                <span style={{display:"block"}}>Синхронизация: {this.state.syncActive === false ? <b style={{color: "red"}}>Нет</b> : <b style={{color: "green"}}>Да</b>}</span>
                {this.state.syncActive === true  &&
                    <span style={{display:"block"}}>Источник: {isNull(this.state.syncSource) ? <b style={{color: "red"}}>Не указан</b> : this.state.syncSource}</span>
                }
            </div>
        );

        this.confirmAction("green", `Создание базы данных ${this.state.infobaseName}`, <Text/>, <AddOutlineIcon/>, "Создать", this.addNewInfobase, fields );
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm_modal: true, 
            confirm_modal_color: color, 
            confirm_modal_caption: caption, 
            confirm_modal_text: text, 
            confirm_modal_buttonIcon: buttonIcon, 
            confirm_modal_buttonOkText: buttonOkText, 
            confirm_modal_onAction: onAction, 
            confirm_modal_actionRequestBody: actionRequestBody
        });
    }

    getListOfIB = async() => {
        let infobases = await getInitListOfIB();
        let levels = await getAvailableLevelsOfIB();
        this.setState({
            infobasesLocal: infobases,
            levels: levels.data
        })
    }

    getClusters = async() => {

        let clusters = await getClusterList();

        this.setState({
            clusterList: clusters.data,
        });

    }

    getInfobasesByClusterId = async(value) => {

        this.setState({ clusterId: value });
        
        let infobases = await getAllInfobasesByClusterId(value);

        this.setState({
            infobasesList: infobases.data,
        });

    }

    getInfobasesParents = async(value) => {

        this.setState({infobaseLevel: value});

        let parents = await getAvailableInfobasesByLevels(value);

        this.setState({
            infobasesParents: parents.data,
        });

    }

    addNewInfobase = async() => {

        this.setState({pr_modal: true});

        const toaster = this.props.toaster;

        let init_fields = {};

        try {

            let newInfobase = await createNewInfobase(this.state.clusterId, this.state.infobaseName, this.state.infobaseDescr);

            if(newInfobase.success === true){

                let progress = Math.min(this.state.pr_modal_progress + 5, 100);
                this.setProgress(progress);
                this.setState({pr_modal_create_ib_response: newInfobase});
            
                if(this.state.copyActive === true){

                    this.setState({pr_modal_copy_active: true});

                    if( this.state.localCopy === true && !isNull(this.state.copySourceLocal) ){

                        this.setState({pr_modal_skip_dump: true});

                        try {
                            
                            let restoredInfobase = await restoreInfobase(this.state.infobaseName, this.state.copySourceLocal);
                            if(!isEmpty(restoredInfobase)){
                                if(restoredInfobase.success === true){
                                    init_fields.not_empty = true;
                                    progress = Math.min(this.state.pr_modal_progress + 20, 100);
                                    this.setProgress(progress);
                                    this.setState({pr_modal_restore_dump_response: restoredInfobase});
                                } else {
                                    init_fields.not_empty = false;
                                    this.setState({pr_modal_restore_dump_response: restoredInfobase, pr_modal_progress_error: true});
                                }
                            }

                        }catch(e){
                            init_fields.not_empty = false;
                            this.setState({pr_modal_progress_error: true, pr_modal_restore_dump_response: e.response.data})
                        }

                    } 
                    
                    //////////////////////////////////////////////////////////////////////////////
                    if ( this.state.remoteCopy === true && !isNull(this.state.copySourceRemote) ){

                        this.setState({pr_modal_skip_dump: false});

                        toaster.push(<Notification showIcon header={'Информация'} type={'info'}>Для базы данных {this.state.infobaseName} с идентификатором {newInfobase.data[0].infobase} начинается загрузка из базы данных {this.state.copySourceRemote}.</Notification>, {
                            duration: 10000,
                            placement: 'topEnd'
                        });
                        //add dump
                        //progress = Math.min(this.state.pr_modal_progress + 10, 100);
                        //add restore this.setProgress(progress);
                        //progress = Math.min(this.state.pr_modal_progress + 10, 100);

                        //init_fields.not_empty = true|false
                    }
                    //////////////////////////////////////////////////////////////////////////////

                    try {
                        let disableSupport = await disableConfigurationSupport(this.state.infobaseName, this.state.adminOnecLogin, this.state.adminOnecPass);
                        if(disableSupport.success === true){
                            progress = Math.min(this.state.pr_modal_progress + 5, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_disable_support_response: disableSupport});
                        } else {
                            this.setState({pr_modal_progress_error: true, pr_modal_disable_support_response: disableSupport});
                        }
                    } catch (e) {
                        this.setState({pr_modal_progress_error: true, pr_modal_disable_support_response: e.response.data});
                    }

                    try {
                        let createExt = await createExtension(this.state.infobaseName, this.state.adminOnecLogin, this.state.adminOnecPass, null, null);
                        if(createExt.success === true){
                            progress = Math.min(this.state.pr_modal_progress + 5, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_create_ext_response: createExt});
                        } else {
                            this.setState({pr_modal_progress_error: true, pr_modal_create_ext_response: createExt});
                        }
                    } catch (e) {
                        this.setState({pr_modal_progress_error: true, pr_modal_create_ext_response: e.response.data});
                    }

                    try {
                        let loadExt = await loadExtension(this.state.infobaseName, this.state.adminOnecLogin, this.state.adminOnecPass, null, null);
                        if(loadExt.success === true){
                            progress = Math.min(this.state.pr_modal_progress + 10, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_load_ext_response: loadExt});
                        } else {
                            this.setState({pr_modal_progress_error: true, pr_modal_load_ext_response: loadExt});
                        }
                    } catch (e) {
                        this.setState({pr_modal_progress_error: true, pr_modal_load_ext_response: e.response.data});
                    }

                    try {
                        let apply = await applyConfig(this.state.infobaseName, this.state.adminOnecLogin, this.state.adminOnecPass);
                        if(apply.success === true){
                            init_fields.ext_init = true;

                            progress = Math.min(this.state.pr_modal_progress + 15, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_apply_ext_response: apply});
                        } else {
                            init_fields.ext_init = false;
                            this.setState({pr_modal_progress_error: true, pr_modal_apply_ext_response: apply});
                        }
                    } catch (e) {
                        init_fields.ext_init = false;
                        this.setState({pr_modal_progress_error: true, pr_modal_apply_ext_response: e.response.data});
                    }

                    try {
                        let publish = await publishInfobase(this.state.infobaseName);
                        if(publish.success === true){
                            init_fields.publish_init = true;

                            progress = Math.min(this.state.pr_modal_progress + 15, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_pub_ib_response: publish});
                        } else {
                            init_fields.publish_init = false;
                            this.setState({pr_modal_progress_error: true, pr_modal_pub_ib_response: publish});
                        }
                    } catch (e) {
                        init_fields.publish_init = false;
                        this.setState({pr_modal_progress_error: true, pr_modal_pub_ib_response: e.response.data});
                    }

                    try {
                        let activate = await activateOData(this.state.infobaseName, this.state.adminOnecLogin, this.state.adminOnecPass);
                        if(activate.success === true){
                            init_fields.odata_init = true;

                            progress = Math.min(this.state.pr_modal_progress + 20, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_activate_odata_response: activate});
                        } else {
                            init_fields.odata_init = false;
                            this.setState({pr_modal_progress_error: true, pr_modal_activate_odata_response: activate});
                        }
                    } catch (e) {
                        init_fields.odata_init = false;
                        this.setState({pr_modal_progress_error: true, pr_modal_activate_odata_response: e.response.data});
                    }

                } else {
                    progress = Math.min(this.state.pr_modal_progress + 75, 100);
                    this.setProgress(progress);
                    this.setState({pr_modal_copy_active: false})
                }

                try {

                    let level;
                    if(isNull(this.state.infobaseLevel || (!isNull(this.state.infobaseLevel) && isNull(this.state.infobasesParentsId) && this.state.infobaseLevel>1))){
                        level = null;
                    }else{
                        level = this.state.infobaseLevel;
                    }

                   let insertToDatabase = await addInfobaseToDatabase(newInfobase.data[0].infobase, this.state.infobaseName, this.state.clusterId, level, this.state.adminOnecLogin, this.state.adminOnecPass, init_fields.ext_init, init_fields.odata_init, init_fields.publish_init, init_fields.not_empty);

                    if(insertToDatabase.success === true){
                        progress = Math.min(this.state.pr_modal_progress + 15, 100);
                        this.setProgress(progress);
                        this.setState({pr_modal_ib_to_db_response: insertToDatabase});
                    } else {
                        this.setState({pr_modal_progress_error: true, pr_modal_ib_to_db_response: insertToDatabase});
                    }
                } catch (e) {
                    this.setState({pr_modal_progress_error: true, pr_modal_ib_to_db_response: e.response.data});
                }

                try {

                    let addLevels;
                    if(isNull(this.state.infobaseLevel)){
                        addLevels = ({success: true, message: "Уровень информационной базы отсутствует. Пропуск операции."})

                        progress = Math.min(this.state.pr_modal_progress + 5, 100);
                        this.setProgress(progress);
                        this.setState({pr_modal_assign_level_response: addLevels});
                    }else if(!isNull(this.state.infobaseLevel) && isNull(this.state.infobasesParentsId) && this.state.infobaseLevel>1){
                        addLevels = ({success: true, message: "Не указан родитель информационной базы. Пропуск операции."})

                        progress = Math.min(this.state.pr_modal_progress + 5, 100);
                        this.setProgress(progress);
                        this.setState({pr_modal_assign_level_response: addLevels});

                    }else if(!isNull(this.state.infobaseLevel) && this.state.infobaseLevel == 1){
                        addLevels = ({success: true, message: "Уровень информационной базы - 1. Пропуск операции."})

                        progress = Math.min(this.state.pr_modal_progress + 5, 100);
                        this.setProgress(progress);
                        this.setState({pr_modal_assign_level_response: addLevels});
                            
                    } else {

                        let addLevels = await addLevelsToInfobase(this.state.infobasesParentsId, newInfobase.data[0].infobase);
 
                        if(addLevels.success === true){
                            progress = Math.min(this.state.pr_modal_progress + 5, 100);
                            this.setProgress(progress);
                            this.setState({pr_modal_assign_level_response: addLevels});
                        } else {
                            this.setState({pr_modal_progress_error: true, pr_modal_assign_level_response: addLevels});
                        }
                    }

                } catch (e) {
                    this.setState({pr_modal_progress_error: true, pr_modal_assign_level_response: e.response.data});
                }

                this.getInfobasesByClusterId(this.state.clusterId);

            } else {
                this.setState({pr_modal_progress_error: true, pr_modal_create_ib_response: newInfobase});
            }

        }catch(e){
            this.setState({pr_modal_progress_error: true, pr_modal_create_ib_response: e.response.data})
        }

    }

    CheckUploadAvailability = async(newFile) => {

        let file = newFile.pop();

        let allow = false;

        let ext = file.name.substring(file.name.lastIndexOf(".")+1);

        const toaster = this.props.toaster;

        if(ext === 'dt'){
            let check = await checkFileExist(file);

            if(isEmpty(check) || isNull(check)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Что-то пошло не так, повторите запрос!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(check.success === false){
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{check.message}</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
                allow = false;
            }

            if(check.success === true){
                toaster.push(<Notification showIcon header={'Успех'} type={'success'}>{check.message}</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
                allow = true;
            }
        }else{
            allow = false;
        }

        return new Promise(resolve => {
            setTimeout(() => {

              allow === true ? resolve(true) : resolve(false);

            }, 1000);
        });
    }

    UploadStatusChange = async(response, file) => {
        const toaster = this.props.toaster;

        toaster.push(<Notification showIcon header={'Успех'} type={'success'}>{response.message}</Notification>, {
            duration: 10000,
            placement: 'topEnd'
        })

        this.setState({uploading: false});

        this.getListOfIB();
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
            this.getListOfIB();
        });
    }

    render() {

        let clusters = this.state.clusterList;
        let infobases = this.state.infobasesList;
        let levelsList = this.state.levels;
        let infobasesLocal = this.state.infobasesLocal;
        let infobasesParents = this.state.infobasesParents;
        const pr_modal = this.state.pr_modal;
        const confirm_modal = this.state.confirm_modal;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );
        
        const databasesToSync = infobases.map(
            item => ({ label: item.name + " [" + item.infobase + "]", value: item.name })
        );
        
        const databasesToCopy = infobases.map(
            item => ({ label: item.name + " [" + item.infobase + "]", value: item.name })
        );

        const databasesToCopyLocal = infobasesLocal.map(
            item => ({ label: item.name, value: item.name })
        );

        const levels = levelsList.map(item => ({
            label: item.level_name,
            value: item.infobaseLevelId
        }));

        const parents = infobasesParents.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.infobaseExternalId })
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaDatabase/></i> Новая база данных 1С</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <Form fluid>
                                                <Form.Group controlId="cluster">
                                                    <Form.ControlLabel>Сервер 1С:</Form.ControlLabel>
                                                    <Form.Control name="cluster" accepter={SelectPicker} data={cluster} value={this.state.clusterId} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Кластер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                                </Form.Group>
                                                <Form.Group controlId="newDBName">
                                                    <Form.ControlLabel>Имя новой базы 1С:</Form.ControlLabel>
                                                    <Form.Control name="newDBName" onBlur={(event) => this.setState({ infobaseName: event.target.value })}/>
                                                    <Form.HelpText>Введите имя новой базы данных 1С, которая будет создана в кластере серверов 1С (Допускаются латинские буквы и цифры).</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="copyData">
                                                    <Form.ControlLabel>Копировать данные:</Form.ControlLabel>
                                                    <Checkbox value="copy" onChange={() => this.setState({ copyDisable: !this.state.copyDisable, copyActive: !this.state.copyActive })} checked={this.state.copyActive}>Активно</Checkbox>
                                                    <Form.HelpText tooltip>Когда установлен флажок, при создании базы данных будет выполнено копирование/перенос данных из существующей базы 1С.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="copyDBServer">
                                                    <Form.ControlLabel>БД для копирования (сервер):</Form.ControlLabel>
                                                    <Form.Control name="copyDBServer" accepter={SelectPicker} data={databasesToCopy} disabled={this.state.copyDisable} style={{width: "100%"}} cleanable={false} value={this.state.copySourceRemote} searchable={false} placeholder='Информационная база (копирование)' onChange={(value) => this.setState({ localCopy: false, remoteCopy: true, copySourceLocal: null, copySourceRemote: value })}/>
                                                    <Form.HelpText>Выберите базу данных 1С, с которой будет выполнен перенос данных в новую базу данных 1С (из активной базы данных).</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="copyDBLocal">
                                                    <Form.ControlLabel>БД для копирования (локально):</Form.ControlLabel>
                                                    <Stack wrap spacing={6} style={{padding: "10px 0"}}>
                                                        <Stack.Item grow={1}>
                                                            <Form.Control name="copyDBLocal" accepter={SelectPicker} data={databasesToCopyLocal} disabled={this.state.copyDisable} style={{width: "100%"}} cleanable={false} value={this.state.copySourceLocal} searchable={false} placeholder='Информационная база (из файла)' onChange={(value) => this.setState({ localCopy: true, remoteCopy: false, copySourceLocal: value, copySourceRemote: null })}/>
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            <Uploader
                                                                action={config.url + config.api.cluster + '/infobases/upload'} 
                                                                headers={{Authorization: 'Bearer ' + this.props.token}}
                                                                fileListVisible={false}
                                                                disabled={this.state.copyDisable && this.state.uploading == false}
                                                                accept='.dt'
                                                                removable={false}
                                                                shouldQueueUpdate={(newFile) => this.CheckUploadAvailability(newFile)}
                                                                onSuccess={(response, file) => this.UploadStatusChange(response, file)}
                                                                onChange={() => this.setState({uploading: true})}
                                                            >
                                                                <IconButton icon={<FileUploadIcon />} loading={this.state.uploading}> Загрузить</IconButton>
                                                            </Uploader>
                                                        </Stack.Item>
                                                    </Stack>
                                                    <Form.HelpText>Выберите базу данных 1С, с которой будет выполнен перенос данных в новую базу данных 1С (из файла *.dt). Для инициализации базы данных на платформе у нее должен быть включена возможность изменения конфигурации («Конфигурация» - «Поддержка» - «Настройка поддержки» - «Включить возможность изменения»)</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="copyPeriod">
                                                    <Form.ControlLabel>Период:</Form.ControlLabel>
                                                    <Form.Control name="copyPeriod" placement="auto" placeholder="Период переносимых данных" accepter={DateRangePicker} ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" disabled={this.state.copyDisable} style={{width: "100%"}}/>
                                                </Form.Group>
                                            </Form>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <Form fluid>
                                                <Form.Group controlId="description">
                                                    <Form.ControlLabel>Описание базы данных:</Form.ControlLabel>
                                                    <Form.Control name="description" onBlur={(event) => this.setState({ infobaseDescr: event.target.value })}/>
                                                    <Form.HelpText>Введите описание базы данных.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="databaseLevel">
                                                    <Form.ControlLabel>Уровень базы данных:</Form.ControlLabel>
                                                    <Stack wrap spacing={6} style={{padding: "10px 0"}}>
                                                        <Stack.Item>
                                                            <Form.Control name="databaseLevel" accepter={SelectPicker} data={levels} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Уровень базы данных' onChange={(value) => this.getInfobasesParents(value)}/>
                                                        </Stack.Item>
                                                        <Stack.Item>
                                                            {infobasesParents.length > 0 &&
                                                                <Form.Control name="parentInfobase" accepter={SelectPicker} data={parents} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Родитель базы данных' onChange={(value) => this.setState({infobasesParentsId: value})}/>
                                                            }
                                                        </Stack.Item>
                                                    </Stack>
                                                </Form.Group>
                                                <Form.Group controlId="oneSUserLogin">
                                                    <Form.ControlLabel>Пользователь:</Form.ControlLabel>
                                                    <Form.Control name="oneSUserLogin" onBlur={(event) => this.setState({ adminOnecLogin: event.target.value })} />
                                                    <Form.HelpText>Введите администратора базы данных 1С.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="oneSUserPassword">
                                                    <Form.ControlLabel>Пароль пользователя:</Form.ControlLabel>
                                                    <Form.Control name="oneSUserPassword"  type="password" onBlur={(event) => this.setState({ adminOnecPass: event.target.value })}/>
                                                    <Form.HelpText>Введите пароль администратора базы данных 1С.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="syncActive">
                                                    <Form.ControlLabel>Синхронизация:</Form.ControlLabel>
                                                    <Checkbox value="sync" onChange={() => this.setState({ syncDisable: !this.state.syncDisable, syncActive: !this.state.syncActive })} checked={this.state.syncActive}>Активно</Checkbox>
                                                    <Form.HelpText tooltip>Когда установлен флажок, необходимо выбрать базу-корреспондента, с которой будет выполняться синхронизация.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="syncDB">
                                                    <Form.ControlLabel>БД для синхронизации:</Form.ControlLabel>
                                                    <Form.Control name="syncDB" accepter={SelectPicker} placement="auto" data={databasesToSync} disabled={this.state.syncDisable} style={{width: "100%"}} value={this.state.syncSource} cleanable={false} searchable={false} onChange={(value) => this.setState({ syncSource: value })} placeholder='Информационная база (синхронизация)'/>
                                                    <Form.HelpText>Выберите базу данных 1С, с которой будет синхронизироваться новая база данных.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group>
                                                    <ButtonToolbar>
                                                        <IconButton icon={<AddOutlineIcon />} onClick={this.handleCreateInfobaseClick} disabled={this.state.uploading}> Создать</IconButton>
                                                        <IconButton icon={<CloseOutlineIcon />}> Очистить</IconButton>
                                                    </ButtonToolbar>
                                                </Form.Group>
                                            </Form>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                {pr_modal && 
                    <NewInfobaseProgress 
                        open={this.state.pr_modal} 
                        onClose={() => this.setState({ 
                                pr_modal: false, 
                                pr_modal_progress: 0, 
                                pr_modal_progress_error: false,
                                pr_modal_create_ib_response: null,
                                pr_modal_create_dump_response: null,
                                pr_modal_restore_dump_response: null,
                                pr_modal_pub_ib_response: null,
                                pr_modal_disable_support_response: null,
                                pr_modal_create_ext_response: null,
                                pr_modal_load_ext_response: null,
                                pr_modal_apply_ext_response: null,
                                pr_modal_activate_odata_response: null,
                                pr_modal_assign_level_response: null,
                                pr_modal_ib_to_db_response: null,
                                pr_modal_skip_dump: false,
                                pr_modal_copy_active: false
                            })} 
                        percent={this.state.pr_modal_progress}
                        error={this.state.pr_modal_progress_error}
                        createIbResp={this.state.pr_modal_create_ib_response}
                        createDumpResp={this.state.pr_modal_create_dump_response}
                        restoreDumpResp={this.state.pr_modal_restore_dump_response}
                        createExt={this.state.pr_modal_create_ext_response}
                        pubIbResp={this.state.pr_modal_pub_ib_response}
                        disableSupportResp={this.state.pr_modal_disable_support_response}
                        loadExtResp={this.state.pr_modal_load_ext_response}
                        applyExtResp={this.state.pr_modal_apply_ext_response}
                        activateOdataResp={this.state.pr_modal_activate_odata_response}
                        assignLevelResp={this.state.pr_modal_assign_level_response}
                        createIbInDbResp={this.state.pr_modal_ib_to_db_response}
                        skipDump={this.state.pr_modal_skip_dump}
                        copyActive={this.state.pr_modal_copy_active}
                    />
                }    
                {confirm_modal && 
                    <ConfirmModal 
                        open={this.state.confirm_modal}
                        onClose={() => this.setState({ 
                            confirm_modal: false,
                            confirm_modal_caption: '',
                            confirm_modal_text: null,
                            confirm_modal_buttonIcon: null,
                            confirm_modal_buttonOkText: '',
                            confirm_modal_onAction: null,
                            confirm_modal_actionRequestBody: null,
                            confirm_modal_color: "",
                        })} 
                        color={this.state.confirm_modal_color} 
                        caption={this.state.confirm_modal_caption} 
                        text={this.state.confirm_modal_text} 
                        buttonIcon={this.state.confirm_modal_buttonIcon} 
                        buttonOkText={this.state.confirm_modal_buttonOkText} 
                        onAction={this.state.confirm_modal_onAction} 
                        actionRequestBody={this.state.confirm_modal_actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default NewDB;