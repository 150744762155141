import React from 'react';
import { Nav } from 'rsuite';
import { isEmpty } from "lodash";

//icons
import NoticeIcon from '@rsuite/icons/Notice';

class NotificationButton extends React.Component {

    render() {
        return (
            <Nav.Item icon={<NoticeIcon />}>Уведомления</Nav.Item>
        );
    }

}

export default NotificationButton;