import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Sidenav, Nav } from 'rsuite';
import { isNull } from 'lodash';

//import icons
import { FiExternalLink } from 'react-icons/fi';
import DashboardIcon from '@rsuite/icons/legacy/Dashboard';
import UserBadgeIcon from '@rsuite/icons/UserBadge';
import GearIcon from '@rsuite/icons/Gear';
import TextImageIcon from '@rsuite/icons/TextImage';
import BarLineChartIcon from '@rsuite/icons/BarLineChart';
import AdminIcon from '@rsuite/icons/Admin';
import CloudReflashIcon from '@rsuite/icons/CloudReflash';
import ConversionIcon from '@rsuite/icons/Conversion';
import Peoples from '@rsuite/icons/Peoples';
import Storage from '@rsuite/icons/Storage';
import Database from '@rsuite/icons/legacy/Database';
import Server from '@rsuite/icons/legacy/Server';
import Book from '@rsuite/icons/legacy/Book';
import Sales from '@rsuite/icons/legacy/Sales';
import ShoppingBasket from '@rsuite/icons/legacy/ShoppingBasket';
import Map from '@rsuite/icons/legacy/Map';
import ChangeList from '@rsuite/icons/ChangeList';
import Industry from '@rsuite/icons/legacy/Industry';
import Briefcase from '@rsuite/icons/legacy/Briefcase';
import SingleSource from '@rsuite/icons/SingleSource';
import Money from '@rsuite/icons/legacy/Money';
import ToolsIcon from '@rsuite/icons/Tools';
import DocPassIcon from '@rsuite/icons/DocPass';
import BarChartIcon from '@rsuite/icons/BarChart';
import RelatedMapIcon from '@rsuite/icons/RelatedMap';
import ListUl from '@rsuite/icons/legacy/ListUl';

const NavLink = React.forwardRef(({ href, children, ...rest }, ref) => (
    <Link ref={ref} to={href} {...rest}>
      {children}
    </Link>
));

export default function MySidebar({ user }) {

    const [expanded, setExpanded] = useState(true);
    const [activeKey, setActiveKey] = useState('1');
    const [openKeys, setOpenKeys] = useState([]);
    const [role, setRole] = useState(null);

    const handleResetOpenSub = (openKeys) => {
        let activeSub;
        if(openKeys.length > 0){
            activeSub = openKeys.slice(-1);
        }else{
            activeSub = openKeys;
        }
        setOpenKeys(activeSub);
    }

    useEffect(() => {
        if(!isNull(user)){
            setRole(user.user.role);
        }
    }, [user]);

    return( 
        <Sidebar style={{ display: 'flex', flexDirection: 'column' }} width={expanded ? 300 : 56} collapsible>
            <Sidenav expanded={expanded} openKeys={openKeys} onOpenChange={handleResetOpenSub}>
                <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={setActiveKey}>
                    <Nav.Item eventKey="1" icon={<DashboardIcon />} as={NavLink} href="/dashboard">Статистика</Nav.Item>
                    <Nav.Item eventKey="2" icon={<UserBadgeIcon />} as={NavLink} href="/profile">Профиль</Nav.Item>
                    <Nav.Menu placement="rightStart" eventKey="3" title="Документы" icon={<TextImageIcon />}>
                        <Nav.Item eventKey="3-1" icon={<ShoppingBasket />} as={NavLink} href="/docs/procurement">Поступления</Nav.Item>
                        <Nav.Item eventKey="3-2" icon={<Sales />} as={NavLink} href="/docs/sales">Отгрузки</Nav.Item>
                        <Nav.Item eventKey="3-3" icon={<DocPassIcon />} as={NavLink} href="/docs/sf/outgoing">Счета-Фактуры исходящие</Nav.Item>
                        <Nav.Item eventKey="3-4" icon={<DocPassIcon />} as={NavLink} href="/docs/sf/incoming">Счета-Фактуры входящие</Nav.Item>
                        <Nav.Item eventKey="3-5" icon={<Money />} as={NavLink} href="/docs/salary">Зарплата</Nav.Item>
                    </Nav.Menu>
                    <Nav.Menu placement="rightStart" eventKey="4" title="Справочники" icon={<ChangeList />}>
                        <Nav.Item eventKey="4-1" icon={<Industry />} as={NavLink} href="/sprav/org">Организации</Nav.Item>
                        <Nav.Item eventKey="4-2" icon={<Briefcase />} as={NavLink} href="/sprav/contragents">Контрагенты</Nav.Item>
                        <Nav.Item eventKey="4-3" icon={<SingleSource />} as={NavLink} href="/sprav/proxy">Прокси</Nav.Item>
                    </Nav.Menu>
                    <Nav.Menu placement="rightStart" eventKey="5" title="Отчеты" icon={<BarLineChartIcon />}>
                        <Nav.Item eventKey="5-1" icon={<BarChartIcon />} as={NavLink} href="/reports/standart">Стандартные</Nav.Item>
                        <Nav.Item eventKey="5-2" icon={<RelatedMapIcon />} as={NavLink} href="/reports/reglament">Регламентированные</Nav.Item>
                        <Nav.Item eventKey="5-3" icon={<Book />} as={NavLink} href="/reports/book/procurement">Книга покупок</Nav.Item>
                        <Nav.Item eventKey="5-4" icon={<Book />} as={NavLink} href="/reports/book/sales">Книга продаж</Nav.Item>
                    </Nav.Menu>
                    <Nav.Item eventKey="6" icon={<CloudReflashIcon />} as={NavLink} href="/edo">ЭДО</Nav.Item>
                    <Nav.Item eventKey="7" icon={<Map />} as={NavLink} href="/map">Карта кластера</Nav.Item>
                    <Nav.Item eventKey="8" icon={<ConversionIcon />} as={NavLink} href="/sync">Синхронизация</Nav.Item>
                    {
                        (role === "Главный администратор" || role === "Администратор") &&
                        <Nav.Menu placement="rightStart" eventKey="9" title="Администрирование" icon={<AdminIcon />}>
                            <Nav.Item eventKey="9-1" icon={<GearIcon />} as={NavLink} href="/admin/parameters">Параметры системы</Nav.Item>
                            <Nav.Item eventKey="9-2" icon={<Peoples />} as={NavLink} href="/admin/users">Пользователи</Nav.Item>
                            <Nav.Item eventKey="9-3" icon={<ListUl />} as={NavLink} href="/admin/requests">Заявки агентов</Nav.Item>
                            <Nav.Item eventKey="9-4" icon={<Database />} as={NavLink} href="/admin/createdb">Новая база данных</Nav.Item>
                            <Nav.Item eventKey="9-5" icon={<Server />} as={NavLink} href="/admin/status">Статус сервера</Nav.Item>
                            <Nav.Item eventKey="9-6" icon={<Storage />} as={NavLink} href="/admin/backups">Бэкапы</Nav.Item>
                        </Nav.Menu>
                    }
                    <Nav.Item eventKey="10" icon={<ToolsIcon />} as={NavLink} href={`${window.location.protocol}//api.${process.env.REACT_APP_HOST}/doc`}>Разработчикам<FiExternalLink style={{fontSize: "12px", margin: "-1px 0 0 3px", position: "absolute"}}/></Nav.Item>
                </Nav>
                </Sidenav.Body>
                <Sidenav.Toggle expanded={expanded} onToggle={expanded => setExpanded(expanded)} />
            </Sidenav>
        </Sidebar>
    )
};