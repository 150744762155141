import React from 'react';
import { Container, Header, FlexboxGrid, Panel, ButtonToolbar, Button, Form, DateRangePicker, Checkbox } from 'rsuite';
import { isNull } from "lodash";
import { format, parseISO } from 'date-fns';

//import icons
import { FaRegIdBadge } from 'react-icons/fa';
import EmailIcon from '@rsuite/icons/Email';
import Lock from '@rsuite/icons/legacy/Lock';
import Save from '@rsuite/icons/legacy/Save';

//import common
import predifinedVars from './Common/predifinedVars';

//import controller functions
import { getProfileData } from '../controllers/userController';

class Profile extends React.Component {
    constructor(props) {
        super(props);
      
        this.state = {
            mailNotifyActive: false,
            mailNotifyAddress: '',
            defaultDateStart: new Date(),
            defaultDateEnd: new Date(),
            username: '',
            status: '',
            fullname: '',
            role: '',
            email: '',
            telegram: '',
            phone: '',
            last_login: null
        };
        this.getProfileData = this.getProfileData.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.convertToLocalDate = this.convertToLocalDate.bind(this);
        this.handleChangeMailNotifyAddress = this.handleChangeMailNotifyAddress.bind(this);
        this.handleChangeDateRange = this.handleChangeDateRange.bind(this);
    }

    getProfileData = async() => {
        let userInfo = await getProfileData();
        this.setState({
            mailNotifyActive: userInfo.data.params.notifyMailAllow,
            mailNotifyAddress: userInfo.data.params.notifyMailAddress,
            defaultDateStart: userInfo.data.params.defaultDateStart,
            defaultDateEnd: userInfo.data.params.defaultDateEnd,
            username: userInfo.data.user.username,
            status: userInfo.data.status.statusName,
            fullname: userInfo.data.user.lastname + ' ' + userInfo.data.user.firstname,
            role: userInfo.data.user.role,
            email: userInfo.data.user.email,
            telegram: userInfo.data.user.telegram,
            phone: userInfo.data.user.phone,
            last_login: userInfo.data.user.last_login
        })
    }

    componentDidMount() {
        this.getProfileData();
    }

    convertDate = (value) => {
        let d = new Date(value);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        let year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        let correctDate = [year, month, day].join('-');

        return new Date(correctDate);
    }

    convertToLocalDate = (value) => {
        const parsedDate = parseISO(value);
        return new Date(parsedDate.getTime());
    }

    handleChangeDateRange = (value) => {
        this.setState({defaultDateStart: value[0], defaultDateEnd: value[1]});
    }

    handleChangeMailNotifyAddress = (value) => {
        this.setState({mailNotifyAddress: value});
    }

    render() {
        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaRegIdBadge/></i> Профиль</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Информация" bordered style={{margin: "10px"}}>
                                            <span className='profileInfo'><b>Имя пользователя:</b> {this.state.username}</span>
                                            <span className='profileInfo'><b>ФИО:</b> {this.state.fullname}</span>
                                            <span className='profileInfo'>
                                                <b>Роль:</b> 
                                                <b style={{color:'green'}}>{this.state.role}</b>
                                            </span>
                                            <span className='profileInfo'><b>email:</b> {this.state.email}</span>
                                            <span className='profileInfo'><b>telegram:</b> <a style={{textDecoration: "none"}} href={`https://t.me/${this.state.telegram}`}>{this.state.telegram}</a></span>
                                            <span className='profileInfo'><b>Телефон:</b> <a style={{textDecoration: "none"}} href={`tel:${this.state.phone}`}>{this.state.phone}</a></span>
                                            <span className='profileInfo'>
                                                <b>Статус:</b> 
                                                {this.state.status === "verified" && <b style={{color:'green'}}>Верифицирован</b>}
                                                {this.state.status === "blocked" && <b style={{color:'red'}}>Заблокирован</b>}
                                                {this.state.status === "created" && <b style={{color:'white'}}>Создан</b>}
                                            </span>
                                            <span className='profileInfo'><b>Последняя авторизация:</b> {isNull(this.state.last_login) ? "Отсутствует" : format(this.convertToLocalDate(this.state.last_login), 'yyyy-MM-dd HH:mm:ss')}</span>
                                            <div className='lowPadding'>
                                                <ButtonToolbar>
                                                        <Button appearance='ghost'><Lock /><span style={{marginLeft: "7px"}}>Сменить пароль</span></Button>
                                                        <Button appearance='ghost'><EmailIcon /><span style={{marginLeft: "7px"}}>Войти в почту</span></Button>
                                                </ButtonToolbar>
                                            </div>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Параметры" bordered style={{margin: "10px"}}>
                                            <Form fluid>
                                                <Form.Group controlId="mailNotifyCheck">
                                                    <Form.ControlLabel>Почтовые уведомления:</Form.ControlLabel>
                                                    <Checkbox value="mailNotifyCheck" onChange={() => this.setState({ mailNotifyActive: !this.state.mailNotifyActive })} checked={this.state.mailNotifyActive}>Активно</Checkbox>
                                                    <Form.HelpText tooltip>Когда установлен флажок, уведомления будут отправляться на Вашу почту, указанную ниже.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="mailNotifyAddress">
                                                    <Form.ControlLabel>Почта для уведомлений:</Form.ControlLabel>
                                                    <Form.Control name="mailNotifyAddress" value={this.state.mailNotifyAddress} onChange={this.handleChangeMailNotifyAddress} disabled={!this.state.mailNotifyActive}/>
                                                    <Form.HelpText>Введите почтовый ящик для уведомлений.</Form.HelpText>
                                                </Form.Group>
                                                <Form.Group controlId="mainRangeDate">
                                                    <Form.ControlLabel>Основной период работы:</Form.ControlLabel>
                                                    <Form.Control name="mainRangeDate" accepter={DateRangePicker} ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" onChange={this.handleChangeDateRange} value={[this.convertDate(this.state.defaultDateStart), this.convertDate(this.state.defaultDateEnd)]} style={{ width: '100%' }}/>
                                                    <Form.HelpText>Основной период для работы будет подставляться во всех отчетах и документах по-умолчанию.</Form.HelpText>
                                                </Form.Group>
                                                <ButtonToolbar>
                                                    <Button appearance='ghost'><Save/> Сохранить</Button>
                                                </ButtonToolbar>
                                            </Form>
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default Profile;