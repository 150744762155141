import React from 'react';
import { Container, Header, FlexboxGrid, Stack, SelectPicker, DateRangePicker, ButtonToolbar, ButtonGroup, IconButton, Panel, Table, Input, Whisper, Tooltip, Notification, Pagination, Checkbox } from 'rsuite';
import {Base64} from 'js-base64';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';
import '@react-pdf-viewer/core/lib/styles/index.css';

//import icons
import { HiOutlinePresentationChartLine } from 'react-icons/hi';
import CreativeIcon from '@rsuite/icons/Creative';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import EmailIcon from '@rsuite/icons/Email';
import SendIcon from '@rsuite/icons/Send';
import ReloadIcon from '@rsuite/icons/Reload';
import Trash from '@rsuite/icons/legacy/Trash';
import TagUnauthorizeIcon from '@rsuite/icons/TagUnauthorize';
import TagAuthorizeIcon from '@rsuite/icons/TagAuthorize';
import TagIcon from '@rsuite/icons/Tag';
import SearchIcon from '@rsuite/icons/Search';
import CloseIcon from '@rsuite/icons/Close';
import Save from '@rsuite/icons/legacy/Save';
import Edit from '@rsuite/icons/legacy/Edit';
import DocPassIcon from '@rsuite/icons/DocPass';

//import wizard
import MainReporEdit from './MainReporEdit';
import SaveWizard from "./SaveWizard";

//import common
import predifinedVars from '../Common/predifinedVars';

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../../controllers/clusterController';
import { getReglamentReports, getSelectedReglamentReport } from '../../controllers/documentsController';

const { Column, HeaderCell, Cell } = Table;

class ReglamentReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            loading: false,
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            organisationsList: [],
            infobaseName: null,
            periodRange: null,
            organisationId: null,
            reports: [],
            documentId: null,
            selectedReport: null,
            opened: false,
            setWizardOpen: false,
            setSaveOpen: false,
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.setOrganisation = this.setOrganisation.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.getSelectedReglamentReport = this.getSelectedReglamentReport.bind(this);
        this.downloadReportXML = this.downloadReportXML.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

            this.setTableLoading();

            try{

                let reports = await getReglamentReports(value, this.state.organisationId, this.state.periodRange);

                this.setState({ reports: reports.data, loading: false, page: 1 });

            }catch(e){
            
                this.setState({ reports: [], loading: false, page: 1 });
    
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка документов!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                });
            }

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getSelectedReglamentReport = async(documentId) => {

        const toaster = this.props.toaster;

        try {

            if(isEmpty(documentId)){
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка определения идентификатора документа!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                });
            }

            this.setState({documentId: documentId}, async function() {
                let report = await getSelectedReglamentReport(this.state.infobaseName, this.state.documentId);

                this.setState({opened: true, selectedReport: report});

            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Не удалось получить детали отчета!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    downloadReportXML = () => {
        console.log("here");
        const element = document.createElement("a");
        const file = new Blob([Base64.decode(this.state.selectedReport.ДанныеОтчета_Base64Data)], {type: 'application/xml+xdto'});
        element.href = URL.createObjectURL(file);
        element.download = "Report_" + this.state.selectedReport.НаименованиеОтчета + "_" + this.state.selectedReport.Организация + ".xml";
        document.body.appendChild(element);
        element.click();
    }

    setPeriod = async(value) => {

        const toaster = this.props.toaster;

        try{

            let period = [];

            if(!isNull(value)){

                for(const date of value){
                    let date_converted = format(new Date(date), 'yyyy-MM-dd');
                    period.push(date_converted);
                }

                period[0] = period[0] + 'T00:00:00';
                period[1] = period[1] + 'T23:59:59';

                this.setState({ periodRange: period });

            }else{
                this.setState({ periodRange: null });
            }

            if(!isNull(this.state.infobaseName)){
                this.setTableLoading();

                let reports = await getReglamentReports(this.state.infobaseName, this.state.organisationId, period);

                this.setState({ reports: reports.data, loading: false, page: 1 });
            }

        }catch(e){
            
            this.setState({ reports: [], loading: false, page: 1 });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка документов!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setOrganisation = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ organisationId: value });

            this.setTableLoading();
            
            let reports = await getReglamentReports(this.state.infobaseName, value, this.state.periodRange);

            this.setState({ reports: reports.data, loading: false, page: 1 });

        }catch(e){
            
            this.setState({ reports: [], loading: false, page: 1 });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка документов!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let organisationsList = this.state.organisationsList;
        const reports = this.state.reports;

        let opened = this.state.opened;

        let selectedReport = this.state.selectedReport;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationId })
        );

        const data = reports.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const StatusCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { (rowData.Posted === true && rowData.DeletionMark === false) && <TagAuthorizeIcon style={{color: '#00FF00'}}/>}
                { (rowData.Posted === false && rowData.DeletionMark === true) && <TagUnauthorizeIcon style={{color: '#FA5F55'}}/>}
                { (rowData.Posted === false && rowData.DeletionMark === false) && <TagIcon style={{color: '#fff'}}/>}
            </Cell>
        );

        const DateCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {format(new Date(rowData.Date), 'yyyy-MM-dd')}
            </Cell>
        );

        const OrgCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {isNull(rowData.Организация) ? 
                    <span style={{color: "#FA5F55"}}>Отсутствует</span> :
                    <span organisationId={rowData.Организация.Ref_Key}>{isNull(rowData.Организация.НаименованиеСокращенное) ? rowData.Организация.НаименованиеПолное : rowData.Организация.НаименованиеСокращенное}</span>
                }
            </Cell>
        );

        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Cell {...props}>                                               
                    <ButtonGroup>
                        <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                            <IconButton icon={<SearchIcon />} onClick={() => this.getSelectedReglamentReport(rowData.Ref_Key)}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Редактировать</Tooltip>}>
                            <IconButton icon={<Edit />}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Статус сдачи</Tooltip>}>
                            <IconButton icon={<DocPassIcon />}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                            <IconButton icon={<Trash/>}/>
                        </Whisper>
                    </ButtonGroup>
                </Cell>
            );
        };

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><HiOutlinePresentationChartLine/></i> Регламентированные отчеты</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10} justifyContent="space-between">                                        
                                        <ButtonToolbar>
                                            <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" style={{ width: 200 }} value={this.convertDate(this.state.periodRange)} onChange={(value) => this.setPeriod(value)} onClean={(event) => this.setPeriod(null)}/>
                                            <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                            <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} placeholder='Информационная база' onChange={(value) => this.getOrganisationsList(value)}/>
                                            <SelectPicker data={organisations} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(organisationsList) ? false : true} placeholder='Организации' onChange={(value) => this.setOrganisation(value)} onClean={(event) => this.setOrganisation(null)}/>
                                            <IconButton icon={<CreativeIcon />} onClick={() => this.setState({ setWizardOpen: true })}>Мастер отчетов</IconButton>
                                            <Whisper placement='top' speaker={<Tooltip>Обновить</Tooltip>}>
                                                <IconButton icon={<ReloadIcon />} />
                                            </Whisper>
                                            <ButtonGroup>
                                                <Whisper placement='top' speaker={<Tooltip>Сохранить</Tooltip>}>
                                                    <IconButton icon={<FileDownloadIcon />} onClick={() => this.setState({ setSaveOpen: true })}/>
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Отправить по почте указанной в профиле</Tooltip>}>
                                                    <IconButton icon={<EmailIcon />} />
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Отправить в Telegram</Tooltip>}>
                                                    <IconButton icon={<SendIcon />} />
                                                </Whisper>
                                            </ButtonGroup>
                                        </ButtonToolbar>
                                    </Stack>
                                </Panel>
                                {
                                    opened ? 
                                        <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                            <FlexboxGrid.Item colspan={16}>
                                                <Panel bordered style={{margin: "10px"}}>
                                                    <Table data={data} height={600} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                                        <Column width={60} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell></HeaderCell>
                                                            <Cell><Checkbox/></Cell>
                                                        </Column>
                                                        <Column width={60} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell></HeaderCell>
                                                            <StatusCell/>
                                                        </Column>
                                                        <Column width={90} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Дата</HeaderCell>
                                                            <DateCell dataKey="Date" />
                                                        </Column>
                                                        <Column width={250} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Отчет</HeaderCell>
                                                            <Cell dataKey="НаименованиеОтчета" />
                                                        </Column>
                                                        <Column width={60} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Вид</HeaderCell>
                                                            <Cell dataKey="ПредставлениеВида" />
                                                        </Column>  
                                                        <Column width={150} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Период</HeaderCell>
                                                            <Cell dataKey="ПредставлениеПериода" />
                                                        </Column>                                                  
                                                        <Column width={200} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Организация</HeaderCell>
                                                            <OrgCell dataKey="Организация" />
                                                        </Column>
                                                        <Column width={200} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Комментарий</HeaderCell>
                                                            <Cell dataKey="Комментарий" />
                                                        </Column>
                                                        <Column flexGrow={1} align="center" verticalAlign="middle">
                                                            <HeaderCell>...</HeaderCell>
                                                            <ActionCell/>
                                                        </Column>
                                                        </Table>
                                                    <div style={{ padding: 20 }}>
                                                        <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={reports.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                                    </div>
                                                </Panel>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <Panel bordered style={{margin: "10px"}}>
                                                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.3)', height: '750px', }}>
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                                                            <Viewer fileUrl={URL.createObjectURL(selectedReport)} />
                                                        </Worker>
                                                    </div>
                                                    <Stack wrap spacing={10} style={{margin: "15px 0"}} justifyContent="space-between">                                        
                                                        <ButtonToolbar>
                                                            <IconButton icon={<Save />} onClick={this.downloadReportXML}>Файлы выгрузки</IconButton>
                                                            <IconButton icon={<CloseIcon />}  onClick={() => this.setState({ opened: false })}>Закрыть</IconButton>
                                                         </ButtonToolbar>
                                                    </Stack>
                                                </Panel>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid> :
                                        <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                            <FlexboxGrid.Item colspan={24}>
                                                <Table data={data} height={600} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell></HeaderCell>
                                                        <Cell><Checkbox/></Cell>
                                                    </Column>
                                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell></HeaderCell>
                                                        <StatusCell/>
                                                    </Column>
                                                    <Column width={90} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell>Дата</HeaderCell>
                                                        <DateCell dataKey="Date" />
                                                    </Column>
                                                    <Column width={250} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell>Отчет</HeaderCell>
                                                        <Cell dataKey="НаименованиеОтчета" />
                                                    </Column>
                                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                                            <HeaderCell>Вид</HeaderCell>
                                                            <Cell dataKey="ПредставлениеВида" />
                                                    </Column>  
                                                    <Column width={150} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell>Период</HeaderCell>
                                                        <Cell dataKey="ПредставлениеПериода" />
                                                    </Column>                                                  
                                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell>Организация</HeaderCell>
                                                        <OrgCell dataKey="Организация" />
                                                    </Column>
                                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                                        <HeaderCell>Комментарий</HeaderCell>
                                                        <Cell dataKey="Комментарий" />
                                                    </Column>
                                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                                        <HeaderCell>...</HeaderCell>
                                                        <ActionCell/>
                                                    </Column>
                                                </Table>
                                                <div style={{ padding: 20 }}>
                                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={reports.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                                </div>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                }
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                <MainReporEdit open={this.state.setWizardOpen} cluster={this.state.clusterId} onClose={() => this.setState({ setWizardOpen: false })}/>
                <SaveWizard open={this.state.setSaveOpen} onClose={() => this.setState({ setSaveOpen: false })}/>
            </main>    
        );
    };
}

export default ReglamentReports;

//Форма отчета - RowData.ВыбраннаяФорма