import React from 'react';
import { Form } from 'rsuite';

class Greetings extends React.Component {
    render() {
        return (
            <Form fluid>
                <div className='modal-fixed-custom'>
                    <Form.Group style={{textAlign: 'center'}}>
                        <span>Добро пожаловать в мастер установки платформы. Для продолжения нажмите "Далее".</span>
                    </Form.Group>
                </div>
            </Form>
        );
    };
}

export default Greetings;