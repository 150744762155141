import React from 'react';
import { Container, Header, FlexboxGrid, List, IconButton, Tooltip, Whisper, Stack } from 'rsuite';
import { isEmpty } from "lodash";

//import icons
import { FaServer, FaHdd, FaMemory, FaNetworkWired, FaDocker, FaDatabase } from 'react-icons/fa';
import { SiStatuspal, SiSwagger } from 'react-icons/si';
import { TbCloudDataConnection } from 'react-icons/tb';
import { AiOutlineCluster } from 'react-icons/ai';
import ParagraphIcon from '@rsuite/icons/Paragraph';
import SignIn from '@rsuite/icons/legacy/SignIn';
import EmailIcon from '@rsuite/icons/Email';
import HistoryIcon from '@rsuite/icons/History';
import ReloadIcon from '@rsuite/icons/Reload';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import CloudReflashIcon from '@rsuite/icons/CloudReflash';
import AdminIcon from '@rsuite/icons/Admin';
import StorageIcon from '@rsuite/icons/Storage';
import TrashIcon from '@rsuite/icons/Trash';
import GlobalIcon from '@rsuite/icons/Global';

//import components;
import StatusModal from './Modal/StatusModal';

class Status extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_open: false,
            modal_header: null
        };
    }

    render() {

        const modal_open = this.state.modal_open;

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaServer/></i> Статус сервера</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={22}>
                            <div className='middle-flex status-list'>
                                <List bordered>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <SiStatuspal/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Статус сервера:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Активен
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Журнал</Tooltip>}>
                                                        <IconButton icon={<HistoryIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Журнал Rsyslog/Journal" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Перезагрузка приложения</Tooltip>}>
                                                        <IconButton icon={<ReloadIcon />} />
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <FaHdd/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Жесткий диск:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                90 Gb из 180 Gb
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Очистка логов</Tooltip>}>
                                                        <IconButton icon={<TrashIcon />} />
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <FaMemory/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Оперативная память:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                10 Gb из 180 Gb
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Использование памяти</Tooltip>}>
                                                        <IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Использование памяти" })}/>
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <FaNetworkWired/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Соединение:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                50kbps
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Соединения</Tooltip>}>
                                                        <IconButton icon={<GlobalIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Сетевые соединения" })}/>
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <FaDocker/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Служба Docker:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Запущено
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Журнал</Tooltip>}>
                                                        <IconButton icon={<HistoryIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Журнал Docker" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Статус контейнеров</Tooltip>}>
                                                        <IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Статус контейнеров Docker" })}/>
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <TbCloudDataConnection/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Соединение с 1С:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                ОК
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Журнал</Tooltip>}>
                                                        <IconButton icon={<HistoryIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Журнал соединений с 1С" })}/>
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <AiOutlineCluster/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Кластер 1С:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Запущено
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Обновить кластер</Tooltip>}>
                                                        <IconButton icon={<CloudReflashIcon />} />
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Статус баз данных</Tooltip>}>
                                                        <IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Статус баз данных 1С" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Обновить конфигурацию базы данных</Tooltip>}>
                                                        <IconButton icon={<CloudReflashIcon />} />
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Войти в web-интерфейс базы данных</Tooltip>}>
                                                        <IconButton icon={<SignIn />} />
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <FaDatabase/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>PostgreSQL:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Запущено
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Журнал</Tooltip>}>
                                                        <IconButton icon={<HistoryIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Журнал PostgreSQL" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Конфигурация</Tooltip>}>
                                                        <IconButton icon={<ParagraphIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Конфигурация PostgreSQL" })}/>
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <EmailIcon/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Docker Mail Server:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Запущено
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Журнал</Tooltip>}>
                                                        <IconButton icon={<HistoryIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Журнал Docker Mail Server" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Администрирование</Tooltip>}>
                                                        <IconButton icon={<AdminIcon />} />
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Перейти</Tooltip>}>
                                                        <IconButton icon={<SignIn />} />
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <StorageIcon/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>Minio:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Запущено
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Журнал</Tooltip>}>
                                                        <IconButton icon={<HistoryIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Журнал Minio" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Перейти</Tooltip>}>
                                                        <IconButton icon={<SignIn />} />
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                    <List.Item>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item colspan={2}>
                                                <SiSwagger/>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                <b>OpenAPI:</b>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={8}>
                                                Запущено
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item colspan={6} style={{justifyContent: 'end'}}>
                                                <Stack wrap spacing={6}>
                                                    <Whisper placement='top' speaker={<Tooltip>Конфигурация</Tooltip>}>
                                                        <IconButton icon={<ParagraphIcon />} onClick={() => this.setState({ modal_open: true, modal_header: "Конфигурация OpenAPI" })}/>
                                                    </Whisper>
                                                    <Whisper placement='top' speaker={<Tooltip>Перейти</Tooltip>}>
                                                        <IconButton icon={<SignIn />} />
                                                    </Whisper>
                                                </Stack>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </List.Item>
                                </List>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                {modal_open && <StatusModal open={modal_open} header={this.state.modal_header} onClose={() => this.setState({ modal_open: false })}/>}
            </main>    
        );
    };
}

export default Status;