import React from 'react';
import { Content, Panel, FlexboxGrid, Button } from 'rsuite';

import Download from '@rsuite/icons/legacy/Download';

import SetupWizard from '../Setup/SetupWizard';

class NotInit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            wizardOpen: false
        }
    }

    openWizard = (value) => {
        this.setState({ wizardOpen: value })
    }

    render() {
        return (
            <div>
                <SetupWizard toaster={this.props.toaster} openState={this.state.wizardOpen} openWizard={this.openWizard}/>
                <Content style={{display: 'flex'}}>
                    <FlexboxGrid justify="center" align="middle" style={{flex: '1 0 auto', display: 'flex', flexDirection: 'column'}}>
                        <FlexboxGrid.Item colspan={12}>
                            <Panel header={<h3 style={{textAlign: 'center', width: "100%"}}>Платформа не инициализирована.</h3>} style={{textAlign: 'center'}} bordered>
                                <span className='deniedSpan'>Для начала работы в системе необходимо произвести ее инициализацию.</span>
                                <Button appearance='ghost' onClick={() => this.setState({ wizardOpen: true })}><Download /> Инициализация</Button>
                            </Panel>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Content>
            </div>
        );
	}

}

export default NotInit;