import React from 'react';
import { IconButton, Modal } from 'rsuite';
import { isNull } from 'lodash';

//import icons
import Close from '@rsuite/icons/legacy/Close';
import Check from '@rsuite/icons/legacy/Check';

class ConfirmModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        let color = this.props.color;
        let buttonOkText = this.props.buttonOkText;
        let buttonIcon = this.props.buttonIcon;
        let actionRequestBody = this.props.actionRequestBody;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleAcceptClick = () => {
            this.props.onAction(actionRequestBody);
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="sm">
                <Modal.Header>
                    <Modal.Title>{this.props.caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.props.text}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={isNull(buttonIcon) ? <Check /> : buttonIcon} color={color} appearance="primary" onClick={handleAcceptClick}>{buttonOkText}</IconButton>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick}>Нет</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default ConfirmModal;