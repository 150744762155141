import React from 'react';
import { IconButton, Modal, Table, Notification, Pagination, Loader, FlexboxGrid, Form, Whisper, Tooltip, Stack } from 'rsuite';
import { isEmpty } from 'lodash';

//import icons
import CheckIcon from '@rsuite/icons/Check';
import Trash from '@rsuite/icons/legacy/Trash';
import ExternalLink from '@rsuite/icons/legacy/ExternalLink';
import TagIcon from '@rsuite/icons/Tag';

//import controllers;
import { getSelectedDocumentOfSale } from '../../../controllers/documentsController';

const { Column, HeaderCell, Cell } = Table;

class SaleDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            document_data: [],
            limit: 150,
            page: 1
        };
        this.openDocument = this.openDocument.bind(this);
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    openDocument = async(document_uid) => {

        const toaster = this.props.toaster;

        try{

            let document = await getSelectedDocumentOfSale(this.props.infobase, document_uid);

            this.setState({
                document_data: document.data
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка при открытии документа!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    componentDidMount() {
        this.openDocument(this.props.data);
    }

    render() {

        const document = this.state.document_data;
        let products;
        let data;
        
        if (!isEmpty(document.Товары)){
            products = document.Товары;

            data = products.filter((v, i) => {
                const start = this.state.limit * (this.state.page - 1);
                const end = start + this.state.limit;
                return i >= start && i < end;
            });
        }

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="lg">
                {!isEmpty(document) ? (
                    <div>
                        <Modal.Title>Реализация №{document.Number} от {document.Date}</Modal.Title>
                        <Modal.Body>
                            <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                <FlexboxGrid.Item colspan={12}>
                                    <div className='middle-flex-large'>
                                        <Form fluid>
                                            <Stack wrap spacing={6} style={{padding: "10px 0"}}>
                                                <Stack.Item>
                                                    <Form.Group controlId="Number">
                                                        <Form.ControlLabel>Номер:</Form.ControlLabel>
                                                        <Form.Control name="Number" value={document.Number}/>
                                                    </Form.Group>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <Form.Group controlId="Date">
                                                        <Form.ControlLabel>Дата:</Form.ControlLabel>
                                                        <Form.Control name="Date" value={document.Date}/>
                                                    </Form.Group>
                                                </Stack.Item>
                                            </Stack>
                                            <Form.Group controlId="Организация">
                                                <Form.ControlLabel>Организация:</Form.ControlLabel>
                                                <Form.Control name="Организация" value={document.Организация}/>
                                            </Form.Group>
                                            <Form.Group controlId="Контрагент">
                                                <Form.ControlLabel>Контрагент:</Form.ControlLabel>
                                                <Form.Control name="Контрагент" value={document.Контрагент}/>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={12}>
                                    <div className='middle-flex-large'>
                                        <Form fluid>
                                            <Form.Group controlId="Договор">
                                                <Form.ControlLabel>Договор:</Form.ControlLabel>
                                                <Form.Control name="Договор" value={document.Договор}/>
                                            </Form.Group>
                                            <Form.Group controlId="Склад">
                                                <Form.ControlLabel>Склад:</Form.ControlLabel>
                                                <Form.Control name="Склад" value={document.Склад}/>
                                            </Form.Group>
                                        </Form>
                                    </div>
                                </FlexboxGrid.Item>
                            </FlexboxGrid>
                            {
                                !isEmpty(products) &&
                                    <div>
                                        <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" style={{margin: "15px auto 0 auto"}}>
                                            <Column width={90} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>#</HeaderCell>
                                                <Cell dataKey="LineNumber" />
                                            </Column>
                                            <Column width={200} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>Наименование</HeaderCell>
                                                <Cell dataKey="Наименование" />
                                            </Column>
                                            <Column width={100} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>Цена</HeaderCell>
                                                <Cell dataKey="Цена" />
                                            </Column>
                                            <Column width={70} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>Количество</HeaderCell>
                                                <Cell dataKey="Количество" />
                                            </Column>
                                            <Column width={100} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>Сумма</HeaderCell>
                                                <Cell dataKey="Сумма" />
                                            </Column>
                                            <Column width={100} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>Ставка НДС</HeaderCell>
                                                <Cell dataKey="СтавкаНДС" />
                                            </Column>
                                            <Column width={100} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>Сумма НДС</HeaderCell>
                                                <Cell dataKey="СуммаНДС" />
                                            </Column>
                                            <Column flexGrow={1} align="center" verticalAlign="middle" fixed>
                                                <HeaderCell>...</HeaderCell>
                                                <Cell>
                                                    <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                                                        <IconButton icon={<Trash />} />
                                                    </Whisper>
                                                </Cell>
                                            </Column>
                                        </Table>
                                        <div style={{ padding: 20 }}>
                                            <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={products.length} limitOptions={[50, 100, 150]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                        </div>
                                    </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <IconButton icon={<ExternalLink/>} >Открыть в 1С</IconButton>
                            <IconButton icon={<TagIcon/>} >Провести</IconButton>
                            <IconButton icon={<CheckIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                        </Modal.Footer>
                    </div>
                ) : (
                    <div style={{display: "block", textAlign: "center"}}>
                        <Loader size="lg" content="Открытие документа..." vertical />
                    </div>
                )}

            </Modal> 
        );
    };
}

export default SaleDetails;