import React from 'react';
import { FlexboxGrid, IconButton, Modal, Form, SelectPicker, Notification, Panel } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import CloseIcon from '@rsuite/icons/Close';
import Save from '@rsuite/icons/legacy/Save';

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../../controllers/clusterController';

class MainReporEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            organisationsList: [],
            infobaseName: null,
            organisationId: null
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setDefaults = async() => {
        let cluster = this.props.cluster;
        if(!isEmpty(cluster)){
            await this.getInfobasesByClusterId(cluster);
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let organisationsList = this.state.organisationsList;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleSaveClick = () => {
            console.log("FORM SUBMITTED");
            this.props.onClose();
        };

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationId })
        );

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="full">
                <Modal.Header>
                    <Modal.Title>Мастер создания регламентированных отчетов</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={8}>
                            <Form fluid>
                                <div className='modal-fixed-custom'>
                                    <Form.Group controlId="cluster">
                                        <Form.ControlLabel>Сервер:</Form.ControlLabel>
                                        <Form.Control name="cluster" accepter={SelectPicker} data={cluster} value={this.state.clusterId} style={{width: "100%"}} onChange={(value) => this.getInfobasesByClusterId(value)} disabled={!isEmpty(clusters) ? false : true} cleanable={false} searchable={false} placeholder='Сервер'/>
                                    </Form.Group>
                                    <Form.Group controlId="infobase">
                                        <Form.ControlLabel>Информационная база:</Form.ControlLabel>
                                        <Form.Control name="infobase" accepter={SelectPicker} data={infobases} style={{width: "100%"}} onChange={(value) => this.getOrganisationsList(value)} disabled={!isEmpty(infobasesList) ? false : true} cleanable={false} searchable={false} placeholder='Информационная база'/>
                                    </Form.Group>
                                    <Form.Group controlId="organisation">
                                        <Form.ControlLabel>Организация:</Form.ControlLabel>
                                        <Form.Control name="organisation" accepter={SelectPicker} data={organisations} style={{width: "100%"}} disabled={!isEmpty(organisationsList) ? false : true} cleanable={false} searchable={false} placeholder='Организация'/>
                                    </Form.Group>
                                </div>
                            </Form>
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={16}>
                            <Form fluid>
                                <div className='modal-fixed-custom'>
                                    <Panel style={{textAlign: 'center', display: "flex", flexGrow: 1}} bordered>
                                        
                                    </Panel>
                                </div>
                            </Form>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Save />} onClick={handleSaveClick}>Сохранить</IconButton>
                    <IconButton icon={<CloseIcon />} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default MainReporEdit;