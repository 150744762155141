import axios from 'axios';
import config from '../config';

export async function getProfileData () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.users + '/user/info').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getRoles () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.users + '/roles/getAll').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getUsers () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.users + '/user/all').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function createUser (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.users + '/user/create', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function changeStatusForUser (userId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.users + '/user/' + userId + '/block', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function verifyUser (userId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.users + '/user/' + userId + '/verify', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function updateLastLoginUser () {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.users + '/user/update/login').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getUserInfo (userId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.users + '/user/' + userId + '/getById').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function updateUserKcPassword (userId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.users + '/user/' + userId + '/password', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}