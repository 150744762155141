let config = {
    url: `https://api.${process.env.REACT_APP_HOST}`,
    hostname: `${process.env.REACT_APP_HOST}`,
    api: {
        documents: '/api/documents',
        database: '/api/database',
        cluster: '/api/cluster',
        public: '/api/public',
        users: '/api/users',
        info: '/api/info',
        sys: '/api/system',
        clients: '/api/clients'
    }
}

export default config;