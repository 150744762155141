import React from 'react';
import { IconButton, Modal, Form, Uploader } from 'rsuite';
import { isEmpty } from 'lodash';

//import icons
import FileUploadIcon from '@rsuite/icons/FileUpload';
import CloseIcon from '@rsuite/icons/Close';

class ImportExchangePlanModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="xs">
                <Modal.Title>Импорт плана обмена</Modal.Title>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="import-1">
                            <Form.ControlLabel>Загрузить план обмена</Form.ControlLabel>
                            <Uploader
                                action="//jsonplaceholder.typicode.com/posts/"
                                shouldQueueUpdate={fileList => {
                                this.setState({ loading: true });
                                return new Promise(resolve => {
                                    setTimeout(() => {
                                    alert('The file is checked and allowed to be added to the queue');
                                    resolve(true);
                                    }, 2000);
                                });
                                }}
                                shouldUpload={file => {
                                return new Promise(resolve => {
                                    setTimeout(() => {
                                    alert('File check passed, run upload');
                                    resolve(true);
                                    this.setState({ loading: false });
                                    }, 2000);
                                });
                                }}
                            >
                                <IconButton icon={<FileUploadIcon />} loading={this.state.loading}>Выберите файл *.xml</IconButton>
                            </Uploader>
                            <Form.HelpText>Загрузка файла с правилами обмена для 1С. После загрузки, правила будут интегрированы во все доступные базы данных 1С.</Form.HelpText>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CloseIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default ImportExchangePlanModal;