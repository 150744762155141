const predifinedVars = {
    predefinedRangesDatePicker : [
        {
          label: 'I Квартал',
          value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 2, 31)],
          placement: 'left'
        },
        {
          label: 'II Квартал',
          value: [new Date(new Date().getFullYear(), 3, 1), new Date(new Date().getFullYear(), 5, 30)],
          placement: 'left'
        },
        {
          label: 'III Квартал',
          value: [new Date(new Date().getFullYear(), 6, 1), new Date(new Date().getFullYear(), 8, 30)],
          placement: 'left'
        },
        {
          label: 'IV Квартал',
          value: [new Date(new Date().getFullYear(), 9, 1), new Date(new Date().getFullYear(), 11, 31)],
          placement: 'left'
        },
        {
          label: 'Этот год',
          value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear(), 11, 31)],
          placement: 'left'
        }
    ],
};

export default predifinedVars;