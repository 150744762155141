import React from 'react';
import { IconButton, Modal, Form, InputGroup, Notification } from 'rsuite';
import { isEmpty } from "lodash";

//import icons
import Close from '@rsuite/icons/legacy/Close';
import Lock from '@rsuite/icons/legacy/Lock';

class UserPasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            password_repeat: ''
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangePasswordRepeat = this.handleChangePasswordRepeat.bind(this);
    }

    handleChangePassword = (value) => {
        this.setState({password: value});
    };

    handleChangePasswordRepeat = (value) => {
        this.setState({password_repeat: value});
    };

    render() {

        let userId = this.props.userId;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleAcceptClick = () => {

            const toaster = this.props.toaster;

            if(isEmpty(this.state.password)){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите пароль!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.password_repeat)){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите подтверждение пароля!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(this.state.password_repeat !== this.state.password){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Поля "Пароль" и "Подтверждение пароля" должны совпадать!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            let pass_exp = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
            if(pass_exp.test(this.state.password) === false){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Пароль должен содержать хотя бы одну цифру, хотя бы один специальный символ, прописные буквы и состоять от 8 до 16 символов!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            this.props.onAction(userId, this.state.password);
            this.setState({password: '', password_repeat: ''});
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="sm">
                <Modal.Header>
                    <Modal.Title>{this.props.caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="password">
                            <Form.ControlLabel>Пароль*:</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password" onChange={this.handleChangePassword} type="password" value={this.state.password}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="password_repeat">
                            <Form.ControlLabel>Подтвердите пароль*:</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password_repeat" onChange={this.handleChangePasswordRepeat} type="password" value={this.state.password_repeat}/>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Lock/>} appearance="primary" onClick={handleAcceptClick}>Сменить пароль</IconButton>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick}>Отмена</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default UserPasswordChange;