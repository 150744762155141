import React from 'react';
import { Container, Header, Panel, FlexboxGrid, ButtonToolbar, IconButton, Stack, SelectPicker, DateRangePicker, Notification, Loader} from 'rsuite';
import Spreadsheet from "react-spreadsheet";
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../../controllers/clusterController';
import { getBook } from '../../controllers/documentsController';

//import common
import predifinedVars from '../Common/predifinedVars';

//import icons
import { BsJournalBookmark } from 'react-icons/bs';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import ReloadIcon from '@rsuite/icons/Reload';

class SalesBook extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            infobaseName: null,
            organisationsList: [],
            periodRange: null,
            bookData: null,
            organisationId: null,
            loading: false
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.setOrganisation = this.setOrganisation.bind(this);
        this.getBook = this.getBook.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
        this.setLoading = this.setLoading.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setOrganisation = async(value) => {
        this.setState({ organisationId: value });
    }

    setLoading = async() => {
        this.setState({
            bookData: null,
            loading: true
        });
    }

    getBook = async() => {

        const toaster = this.props.toaster;

        try{

            let period = this.state.periodRange;
            if (isNull(period)) {
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите период!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            this.setLoading();

            let book = await getBook(this.state.infobaseName, "BookOfSales", this.state.organisationId, period[0], period[1]);

            this.setState({
                loading: false,
                bookData: book.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка при получении книги продаж!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    setPeriod = async(value) => {

        const toaster = this.props.toaster;

        try{

            let period = [];

            if(!isNull(value)){

                for(const date of value){
                    let date_converted = format(new Date(date), 'yyyy-MM-dd');
                    period.push(date_converted);
                }

                this.setState({ periodRange: period });

            }else{
                this.setState({ periodRange: null });
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при установке периода!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }
    
    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let organisationsList = this.state.organisationsList;
        const loading = this.state.loading;
        const data = this.state.bookData;

        const columnLabels = ['Номер/Дата', 'Покупатель', 'ИНН', 'НДС', 'Сумма c НДС'];

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationExternalId })
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><BsJournalBookmark/></i> Книга продаж</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10} justifyContent="space-between">
                                        <ButtonToolbar>
                                            <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" style={{ width: 200 }} value={this.convertDate(this.state.periodRange)} onChange={(value) => this.setPeriod(value)} onClean={(event) => this.setPeriod(null)}/>
                                            <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                            <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} onChange={(value) => this.getOrganisationsList(value)} placeholder='Информационная база'/>
                                            <SelectPicker data={organisations} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(organisationsList) ? false : true} onChange={(value) => this.setOrganisation(value)} placeholder='Организация'/>
                                            <IconButton icon={<AddOutlineIcon />} disabled={!isNull(this.state.organisationId) ? false : true} onClick={this.getBook}>Сформировать</IconButton>
                                            <IconButton icon={<ReloadIcon />}>Обновить</IconButton>
                                        </ButtonToolbar>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                {!isNull(this.state.bookData) &&
                                    <Spreadsheet
                                        data={data}
                                        columnLabels={columnLabels}
                                        style={{width: "100%"}}
                                    />
                                }
                                {loading &&
                                    <div style={{display: "block", textAlign: "center"}}>
                                        <Loader size="lg" content="Формирование отчета..." vertical />
                                    </div>
                                }
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default SalesBook;