import axios from 'axios';
import config from '../config';

export async function getClusterList () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/cluster/all').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getClusterInfo (clusterId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/cluster/' + clusterId).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function createNewInfobase (clusterId, infobaseName, infobaseDescr) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + clusterId + '/register', {infobaseName, infobaseDescr}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllInfobasesByClusterId (clusterId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/infobases/' + clusterId + '/all').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllInfobasesByClusterIdFromDatabase (clusterId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/infobases/' + clusterId + '/database').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getInfobaseInfo (clusterId, infobaseExternalId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/infobases/' + clusterId + '/' + infobaseExternalId + '/info').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function restoreInfobase (infobaseName, dt_name) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/restore', {dt_name}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function createExtension (infobaseName, username, password, extensionName, extensionPrefix) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/extension/create', {username, password, extensionName, extensionPrefix}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function disableConfigurationSupport (infobaseName, username, password) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/support', {username, password}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function loadExtension (infobaseName, username, password, extensionName, extensionFile) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/extension/load', {username, password, extensionName, extensionFile}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function applyConfig (infobaseName, username, password) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/apply', {username, password}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function publishInfobase (infobaseName) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/publish').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getInitListOfIB () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/infobases/list/dt').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function checkFileExist (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/checkFileExist', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function activateOData (infobaseName, username, password) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/' + infobaseName + '/odata', {username, password}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function addInfobaseToDatabase (infobaseExternalId, name, cluster, infobaseLevelId, adminLogin, adminPassword, ext_init, odata_init, publish_init, not_empty) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.info + '/infobase/add', {infobaseExternalId, name, cluster, infobaseLevelId, adminLogin, adminPassword, ext_init, odata_init, publish_init, not_empty}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAvailableLevelsOfIB () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.cluster + '/infobases/levels/available').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAvailableInfobasesByLevels (infobaseLevelId) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.cluster + '/infobases/levels/parents', {infobaseLevelId}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function addLevelsToInfobase (parent, child) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.info + '/infobase/assign', {parent, child}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getContragents (infobaseName) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/catalog/' + infobaseName + '/contragents').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getOrganisations (infobaseName) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.documents + '/catalog/' + infobaseName + '/organisations').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getSyncTree (clusterId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.database + '/db/' + clusterId + '/tree').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getClusterMap (clusterId) {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.database + '/db/' + clusterId + '/map').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getOrganisationConnexions (clusterId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.database + '/db/' + clusterId + '/connexions', { periodRange }).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getOrganisationConnexionsByOrganisationId (organisationId, contract_type, nodeId, periodRange) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.database + '/db/child/' + organisationId + '/connexions', {contract_type, nodeId, periodRange}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function updateInfobase (infobaseExternalId, fields) {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.info + '/infobase/' + infobaseExternalId + '/update', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}