import axios from 'axios';
import config from '../config';

export async function getInitStatus () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.public + '/check/status').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function createAdmin (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/create/admin', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function checkAdmin () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.public + '/check/admin').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function initDatabase () {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/create/entries').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function initMailSystem () {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/create/mail').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function initMinioSystem () {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/create/buckets').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function checkMailSystem () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.public + '/check/mail').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function checkMinioSystem () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.public + '/check/buckets').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function checkDatabase () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.public + '/check/entries').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getCDNFileList (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/getFileList', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function checkFileExist (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/checkFileExist', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function downloadFilesViaFTP (fields) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.public + '/upload/ftp', fields).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function updateInitStatus () {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.public + '/update').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}