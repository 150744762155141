import axios from 'axios';
import config from '../config';

export async function getAllRequests () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.clients + '/request/all').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}