import React from 'react';
import { Container, Header, FlexboxGrid, Stack, DateRangePicker, SelectPicker, Panel, Notification, Whisper, Tooltip, Loader } from 'rsuite';
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import icons
import { HiOutlinePresentationChartLine } from 'react-icons/hi';

//import common
import predifinedVars from '../Common/predifinedVars';

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../../controllers/clusterController';
import { getReportsTypesFromOdata } from '../../controllers/documentsController';

class StandartReports extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            organisationsList: [],
            reportsTypeList: [],
            infobaseName: null,
            periodRange: null,
            organisationId: null,
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.setOrganisation = this.setOrganisation.bind(this);
        this.getReportsTypes = this.getReportsTypes.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getReportsTypes = async(value) => {

        const toaster = this.props.toaster;

        try{

            let reports = await getReportsTypesFromOdata(value);

            this.setState({
                reportsTypeList: reports.data,
            });

        }catch(e){

            this.setState({
                reportsTypeList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Варианты отчетов не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

            await this.getReportsTypes(value);

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setOrganisation = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ organisationId: value });

        }catch(e){
            
            this.setState({ docs: [], loading: false, page: 1 });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка отчетов!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let organisationsList = this.state.organisationsList;
        let reportsTypeList = this.state.reportsTypeList;
        let organisationId = this.state.organisationId;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationId })
        );

        const reports = reportsTypeList.map(
            item => ({ label: item.Description, value: item.Ref_Key })
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><HiOutlinePresentationChartLine/></i> Стандартные отчеты</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" style={{ width: 200 }} value={this.convertDate(this.state.periodRange)} onChange={(value) => this.setPeriod(value)} onClean={(event) => this.setPeriod(null)}/>
                                        <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                        <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} placeholder='Информационная база' onChange={(value) => this.getOrganisationsList(value)}/>
                                        <SelectPicker data={organisations} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(organisationsList) ? false : true} placeholder='Организации' onChange={(value) => this.setOrganisation(value)} onClean={(event) => this.setOrganisation(null)}/>
                                        <SelectPicker data={reports} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(reportsTypeList) ? false : true} placeholder='Виды отчетов'/>
                                    </Stack>
                                </Panel>
                                {
                                    !isEmpty(infobasesList) &&
                                        <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                            <FlexboxGrid.Item colspan={24}>
                                                <Panel header="Отчет" bordered style={{margin: "10px"}}>

                                                </Panel>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                }
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default StandartReports;