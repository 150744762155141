import React from 'react';
import { List, FlexboxGrid } from 'rsuite';

const styleCenter = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
};

const fontStyle = {
    fontSize: '1.2em',
    fontWeight: 500
};

export const InfoGrid = ({data}) => {

    const infoView = [];

    for(const key in data){

        if (typeof Boolean(data[key]) === "boolean"){
            data[key] = String(data[key]);
        }

        infoView.push(
            <List.Item>
                <FlexboxGrid>
                    <FlexboxGrid.Item colspan={12} style={styleCenter}>
                        <div style={{ textAlign: 'center' }}>
                            <div style={fontStyle}>{key}</div>
                        </div>
                    </FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={12} style={styleCenter}>
                        <div style={{ textAlign: 'center' }}>
                            <div style={fontStyle}>{data[key]}</div>
                        </div>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </List.Item>
        )
    }

    return <List hover>{infoView}</List>
}
