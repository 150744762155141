import React from 'react';
import { Form, Loader, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons;
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';

//import controller;
import { initMailSystem, checkMailSystem } from "../../../controllers/initController";

class InitMailSystem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            done: false,
            message: null
        };
        this.handleCreateMails = this.handleCreateMails.bind(this);
    }

    handleCreateMails = async() => {
        const toaster = this.props.toaster;

        try{

            let check = await checkMailSystem();

            if(!isEmpty(check) && !isNull(check) && check.success === true){

                this.setState({message: "Инициализация почтовой системы завершена.", done: true})

                this.props.check(false);

                return;

            }else{

                let request = await initMailSystem();

                if(!isEmpty(request) && !isNull(request)){

                    toaster.push(<Notification showIcon header={'Информация'} type={'success'}>{request.message}</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    });
            
                    this.setState({message: request.message, done: true})

                    this.props.check(false);

                    return;
                }
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    componentDidMount() {
        this.props.check(true);
        this.handleCreateMails();
    }

    render() {

        let done = this.state.done;

        return (
            <Form fluid>
                <div className='modal-fixed-custom'>
                    <Form.Group style={{textAlign: 'center'}}>
                        {done ? (
                            <div>
                                <span style={{display: "block", color: "green", fontSize: "48px", paddingBottom: "15px"}}><CheckOutlineIcon/></span>
                                <span style={{display: "block"}}>{this.state.message}</span>
                            </div>
                        ) : (
                            <Loader size="lg" content="Инициализация почтовой системы..." vertical />
                        )}
                    </Form.Group>
                </div>
            </Form>
        );
    };
}

export default InitMailSystem;