import React from 'react';
import { Content, Panel, FlexboxGrid } from 'rsuite';

class DenyLoginWithStatusAndRole extends React.Component {
    constructor(props) {
        super(props);
    };

    render() {

        return (
            <Content style={{display: 'flex'}}>
                <FlexboxGrid justify="center" align="middle" style={{flex: '1 0 auto', display: 'flex', flexDirection: 'column'}}>
                    <FlexboxGrid.Item colspan={12}>
                        <Panel header={<h3 style={{textAlign: 'center', width: "100%"}}>Доступ запрещен</h3>} style={{textAlign: 'center'}} bordered>
                            <span className='deniedSpan'>{this.props.text}</span>
                        </Panel>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Content>
        );
	}

}

export default DenyLoginWithStatusAndRole;