import axios from 'axios';
import config from '../config';

export async function getSystemParameters () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.sys + '/general/getAll').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function updateSystemParameters (params) {
    return await new Promise((resolve, reject)=>{
        axios.put(config.url + config.api.sys + '/general/update', params).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function addNewProxy (username, password, protocol, port, address, name) {
    return await new Promise((resolve, reject)=>{
        axios.post(config.url + config.api.sys + '/proxy/new', {username, password, protocol, port, address, name}).then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}

export async function getAllProxy () {
    return await new Promise((resolve, reject)=>{
        axios.get(config.url + config.api.sys + '/proxy/get').then(async (response)=>{
            resolve(response.data);
        }).catch((err) => {
            reject(err);
        });
    });
}