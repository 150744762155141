import React from 'react';
import { Form } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import controllers;
import { updateInitStatus } from '../../../controllers/initController';

class Finish extends React.Component {
    constructor(props) {
        super(props);
        this.updateInitStatus = this.updateInitStatus.bind(this);
    }

    updateInitStatus = async() => {
        let update = await updateInitStatus();

        if(!isEmpty(update) && !isNull(update)){

            this.props.check(false);

            return;
        }
    }

    componentDidMount() {
        this.props.check(true);
        this.updateInitStatus();
    }

    render() {
        return (
            <Form fluid>
                <div className='modal-fixed-custom'>
                    <Form.Group style={{textAlign: 'center'}}>
                        <span>Платформа успешно установлена и готова к работе. Для авторизации используйте данные пользователя, которые ввели ранее. Для завершения установки нажмите "Завершить установку". Для инициализации информационных баз перейдите в меню "Администирирование" - "Новая база данных".</span>
                    </Form.Group>
                </div>
            </Form>
        );
    };
}

export default Finish;