import React from 'react';
import { Container, Header, Checkbox, FlexboxGrid, Tooltip, Whisper, IconButton, Table, InputGroup, Input, SelectPicker, Stack, Pagination, Panel, ButtonGroup, Notification, Uploader } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import { FaIndustry } from 'react-icons/fa';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import ReloadIcon from '@rsuite/icons/Reload';
import Edit from '@rsuite/icons/Edit';
import Trash from '@rsuite/icons/legacy/Trash';
import FileUpload from '@rsuite/icons/legacy/FileUpload';
import Check from '@rsuite/icons/legacy/Check';
import Refresh from '@rsuite/icons/legacy/Refresh';
import ConversionIcon from '@rsuite/icons/Conversion';

//import components
import Synchronization from '../Common/Synchronization';

//import common
import ConfirmModal from "../Common/ConfirmModal";

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../../controllers/clusterController';
import { syncOrganisationsFromInfobaseToDatabase } from '../../controllers/documentsController';
import { getAllProxy } from "../../controllers/systemController";

const { Column, HeaderCell, Cell } = Table;

class Organisations extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            infobaseName: null,
            proxyList: [],
            organisations: [],
            loading: false,
            modal_loading: false,
            sync_modal_open: false,
            confirm: false,
            caption: '',
            text: "",
            buttonIcon: null,
            buttonOkText: '',
            onAction: null,
            actionRequestBody: null,
            color: "",
            success: false
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getOrganisations = this.getOrganisations.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.syncData = this.syncData.bind(this);
        this.updateOrganisation = this.updateOrganisation.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisations = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value });

            this.setTableLoading();
            
            let organisations = await getOrganisations(value);
            let proxy = await getAllProxy();

            this.setState({
                proxyList: proxy.data,
                organisations: organisations.data,
                loading: false
            });
        
        }catch(e){

            this.setState({
                organisations: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    handleSyncData = async() => {

        const toaster = this.props.toaster;

        if(isNull((this.state.infobaseName))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>База данных не выбрана!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let fields = {
            infobaseName: this.state.infobaseName,
        }

        this.confirmAction("blue", "Синхронизация элементов", `Синхронизировать организации с базой данных ${this.state.infobaseName}?`, <ConversionIcon style={{color: '#fff'}}/>, "Начать синхронизацию", this.syncData, fields );
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm: true, 
            color: color, 
            caption: caption, 
            text: text, 
            buttonIcon: buttonIcon, 
            buttonOkText: buttonOkText, 
            onAction: onAction, 
            actionRequestBody: actionRequestBody
        });
    }

    syncData = async() => {

        const toaster = this.props.toaster;

        try{

            this.setState({
                sync_modal_open: true,
                modal_loading: true
            });

            if(isNull((this.state.infobaseName))){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>База данных не выбрана!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            let data_sync = await syncOrganisationsFromInfobaseToDatabase(this.state.infobaseName);
            toaster.push(<Notification showIcon header={data_sync.success === true ? 'Информация' : 'Ошибка'} type={data_sync.success === true ? 'success' : 'error'}>{data_sync.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

            this.setState({
                modal_loading: false,
                success: data_sync.success === true ? true : false
            });

            if (data_sync.success === true) {
                this.getOrganisations(this.state.infobaseName);
            }
        
        }catch(e){

            this.setState({
                modal_loading: false,
                success: false
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка синхронизации организаций!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    updateOrganisation = async(proxyId, organisationExternalId) => {
        const toaster = this.props.toaster;

        try{

            getOrganisations(this.state.infobaseName);

        }catch(e){

            this.setState({
                organisations: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка обновления организации!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let infobaseName = this.state.infobaseName;
        let proxyList = this.state.proxyList;
        let organisation = this.state.organisations;

        const confirm = this.state.confirm;
        const sync_modal_open = this.state.sync_modal_open;

        const proxy = proxyList.map(
            item => ({ label: item.name + " (" + item.latency + ")", value: item.proxyId})
        );

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const data = organisation.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const EcpCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { !isNull(rowData.ecp_path) ? (
                    rowData.ecp_path
                ) : (
                    <Uploader 
                        listType="picture" 
                        fileListVisible={false}    
                    >
                        <button style={{height: "40px", width: "40px", margin: "auto"}}>
                            <FileUpload />
                        </button>
                    </Uploader>
                )}
            </Cell>
        );
        
        const ProxyCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                <Stack wrap spacing={10} justifyContent="center">
                    <SelectPicker data={proxy} style={{ width: 200 }} cleanable={false} value={rowData.proxyId} searchable={false} placeholder='Прокси-сервер' onChange={(value) => this.updateOrganisation(value, rowData.organistionId)}/>
                </Stack>
            </Cell>
        );

        const KpCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { !isNull(rowData.kp_path) ? (
                    <Stack wrap spacing={10} justifyContent="center">
                        {rowData.kp_path}
                        <ButtonGroup>
                            <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                                <IconButton icon={<SearchIcon />} appearance="subtle" size="xs"/>
                            </Whisper>
                            <Whisper placement='top' speaker={<Tooltip>Заменить</Tooltip>}>
                                <IconButton icon={<FileUpload />} appearance="subtle" size="xs"/>
                            </Whisper>
                        </ButtonGroup>
                    </Stack>
                ) : (
                    <Stack wrap spacing={10} justifyContent="center">
                        <Whisper placement='top' speaker={<Tooltip>Добавить</Tooltip>}>
                            <IconButton icon={<AddOutlineIcon />} appearance="subtle" size="xs"/>
                        </Whisper>
                    </Stack>
                )}
            </Cell>
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaIndustry/></i> Организации</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                        <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} onChange={(value) => this.getOrganisations(value)} placeholder='Информационная база'/>
                                        <IconButton icon={<AddOutlineIcon />} disabled={!isNull(infobaseName) ? false : true}>Добавить</IconButton>
                                        <IconButton icon={<ReloadIcon />} disabled={!isNull(infobaseName) ? false : true}>Обновить</IconButton>
                                        <Whisper placement='top' speaker={<Tooltip>Синхронизировать с базой данных</Tooltip>}>
                                            <IconButton icon={<Refresh />} disabled={!isNull(infobaseName) ? false : true} onClick={() => this.handleSyncData()}/>
                                        </Whisper>
                                        <InputGroup inside style={{ width: 200 }}>
                                            <Input />
                                            <InputGroup.Button>
                                                <SearchIcon />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="organisationId" />
                                    </Column>
                                    <Column width={270} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Наименование</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={250} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Тип</HeaderCell>
                                        <Cell dataKey="type" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>ИНН</HeaderCell>
                                        <Cell dataKey="inn" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>КПП</HeaderCell>
                                        <Cell dataKey="kpp" />
                                    </Column>
                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>КП</HeaderCell>
                                        <KpCell dataKey="kp_path" />
                                    </Column>
                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>ЭЦП</HeaderCell>
                                        <EcpCell dataKey="ecp_path" />
                                    </Column>
                                    <Column width={250} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Прокси</HeaderCell>
                                        <ProxyCell dataKey="proxyId" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <Cell>                                               
                                            <ButtonGroup>
                                                <Whisper placement='top' speaker={<Tooltip>Обновить ЭЦП</Tooltip>}>
                                                    <IconButton icon={<FileUpload />} externalId={data.organisationId}/>
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                                                    <IconButton icon={<SearchIcon />} externalId={data.organisationExternalId}/>
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Редактировать</Tooltip>}>
                                                    <IconButton icon={<Edit />} externalId={data.organisationExternalId}/>
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                                                    <IconButton icon={<Trash />} externalId={data.organisationExternalId}/>
                                                </Whisper>
                                            </ButtonGroup>
                                        </Cell>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={organisation.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {sync_modal_open && 
                    <Synchronization 
                        open={this.state.sync_modal_open} 
                        onClose={() => this.setState({ 
                                sync_modal_open: false, 
                                modal_loading: false,
                                success: false
                            })} 
                        modal_loading={this.state.modal_loading}
                        syncType={"организаций"}
                        infobaseName={this.state.infobaseName}
                        success={this.state.success}
                    />
                }
                {confirm && 
                    <ConfirmModal
                        open={this.state.confirm} 
                        onClose={() => this.setState({ 
                            confirm: false,
                            caption: '',
                            text: "",
                            buttonIcon: null,
                            buttonOkText: '',
                            onAction: null,
                            actionRequestBody: null,
                            color: "",
                        })} 
                        color={this.state.color} 
                        caption={this.state.caption} 
                        text={this.state.text} 
                        buttonIcon={this.state.buttonIcon} 
                        buttonOkText={this.state.buttonOkText} 
                        onAction={this.state.onAction} 
                        actionRequestBody={this.state.actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default Organisations;