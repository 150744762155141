import React from 'react';
import { Container, Header, Panel, FlexboxGrid, ButtonToolbar, ButtonGroup, IconButton, Table, Pagination } from 'rsuite';
import { isEmpty } from "lodash";

//import icons
import { MdBackup } from 'react-icons/md';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import TrashIcon from '@rsuite/icons/Trash';
import TimeIcon from '@rsuite/icons/Time';
import ParagraphIcon from '@rsuite/icons/Paragraph';

const { Column, HeaderCell, Cell } = Table;

const backup = [{
    "id": 1,
    "filename": "БухгалтерияПредприятия1506",
    "path": "/data/bp/2022",
    "size": 516,
    "date": "17-06-22"
},{
    "id": 2,
    "filename": "БухгалтерияПредприятия13406",
    "path": "/data/bp/2021",
    "size": 516,
    "date": "14-09-21"
},{
    "id": 3,
    "filename": "БухгалтерияПредприятия12306",
    "path": "/data/bp/2022",
    "size": 256,
    "date": "13-01-22"
},{
    "id": 4,
    "filename": "БухгалтерияПредприятия1546",
    "path": "/data/bp/2021",
    "size": 128,
    "date": "15-02-21"
},{
    "id": 5,
    "filename": "БухгалтерияПредприятия1576",
    "path": "/data/bp/2022",
    "size": 1024,
    "date": "14-03-22"
},{
    "id": 6,
    "filename": "БухгалтерияПредприятия2346",
    "path": "/data/bp/2021",
    "size": 4096,
    "date": "11-01-21"
}
]

class Backups extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            page: 1
        };
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    render() {

        const data = backup.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><MdBackup/></i> Резервные копии</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <ButtonToolbar>
                                        <IconButton icon={<AddOutlineIcon />}>Новая резервная копия</IconButton>
                                        <IconButton icon={<TimeIcon />}>Планировщик</IconButton>
                                        <IconButton icon={<ParagraphIcon />}>Журнал</IconButton>
                                    </ButtonToolbar>
                               </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="id" />
                                    </Column>

                                    <Column width={350} align="center" verticalAlign="middle">
                                        <HeaderCell>Имя файла</HeaderCell>
                                        <Cell dataKey="filename" />
                                    </Column>

                                    <Column width={350} align="center" verticalAlign="middle">
                                        <HeaderCell>Путь</HeaderCell>
                                        <Cell dataKey="path" />
                                    </Column>

                                    <Column width={150} align="center" verticalAlign="middle">
                                        <HeaderCell>Размер</HeaderCell>
                                        <Cell dataKey="size" />
                                    </Column>

                                    <Column width={150} align="center" verticalAlign="middle">
                                        <HeaderCell>Дата создания</HeaderCell>
                                        <Cell dataKey="date" />
                                    </Column>

                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <Cell>                                               
                                            <ButtonGroup>
                                                <IconButton icon={<FileDownloadIcon />} />
                                                <IconButton icon={<TrashIcon />} />
                                            </ButtonGroup>
                                        </Cell>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={backup.length} limitOptions={[10, 30, 50]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default Backups;