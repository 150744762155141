import React from 'react';
import ReactFlow, { MiniMap, Controls, Panel as PanelReactFlow } from 'reactflow';
import { Container, Header, Panel, FlexboxGrid, ButtonToolbar, ButtonGroup, IconButton, Stack, SelectPicker, Row, Col, Tooltip, Whisper, Notification, Loader, Radio, RadioGroup, DateRangePicker } from 'rsuite';
import CircularSlider from '@fseehawer/react-circular-slider'; //https://www.npmjs.com/package/payment-slider
import { v4 as uuidv4 } from 'uuid';
import styled, { ThemeProvider } from 'styled-components';
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import styles;
import 'reactflow/dist/style.css';

//import icons
import { FaRegMap } from 'react-icons/fa';
import EditIcon from '@rsuite/icons/Edit';
import ReloadIcon from '@rsuite/icons/Reload';
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';
import { AiOutlineFall, AiOutlineArrowUp, AiOutlineArrowDown } from 'react-icons/ai';
import { MdOutlineTrendingUp } from 'react-icons/md';
import { GoDiff } from 'react-icons/go';

//import common
import predifinedVars from './Common/predifinedVars';

//import components;
import MapOfClusterInfoModal from './Common/MapOfClusterInfoModal';
import OkvedsModal from './Common/OkvedsModal';
import InformationNode from './Common/InformationNode';

//import controllers
import { getClusterList, getClusterMap, getOrganisationConnexions, getOrganisationConnexionsByOrganisationId } from '../controllers/clusterController';
import { getOrganisationCardInPDF } from '../controllers/documentsController';

const viewStyles = {
    radioGroupLabel: {
      padding: '8px 2px 8px 10px',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
};

const nodeTypes = {
    infoNode: InformationNode,
};

const darkTheme = {
    bg: '#000',
    primary: '#ff0072',
    nodeBg: '#eed202',
    nodeColor: '#f9f9f9',
    nodeBorder: '#888',
    minimapMaskBg: 'rgba(52, 52, 53, 0.5)',
    controlsBg: '#555',
    controlsBgHover: '#676768',
    controlsColor: '#dddddd',
    controlsBorder: '#676768',
};

class MapOfCluster extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clusterList: [],
            clusterId: null,
            organisationsList: [],
            connexionsList: [],
            filtered_connexions: [],
            loading: false,
            info_modal_open: false,
            data: [],
            okveds: [],
            periodRange: null,
            okveds_modal_open: false,
            percent_of_connexions: 100,
            mapType: "cluster_map",
            connexionType: "СПокупателем"
        };
        this.getClusters = this.getClusters.bind(this);
        this.getMap = this.getMap.bind(this);
        this.getConnexions = this.getConnexions.bind(this);
        this.getConnexionsByOrganisationId = this.getConnexionsByOrganisationId.bind(this);
        this.getData = this.getData.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.getOrganisationCardInPDF = this.getOrganisationCardInPDF.bind(this);
        this.setReqType = this.setReqType.bind(this);
        this.setClusterId = this.setClusterId.bind(this);
        this.setDisplayPercent = this.setDisplayPercent.bind(this);
        this.filterConnexionsByCount = this.filterConnexionsByCount.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
        this.setContractType = this.setContractType.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    getOrganisationCardInPDF = async(organisationId) => {
        await getOrganisationCardInPDF(organisationId);
    }

    setReqType = async(value) => {
        this.setState({mapType: value});
        if (!isNull(this.state.clusterId)) {
            this.getData(this.state.clusterId, value);
        }
    }

    setClusterId = async(value) => {
        this.setState({clusterId: value});
        if (!isNull(this.state.mapType)) {
            this.getData(value, this.state.mapType);
        }
    }

    getData = async(clusterId, mapType) => {

        const toaster = this.props.toaster;

        try{          

            if(mapType === "cluster_map"){
                this.getMap(clusterId);
            }else if(mapType === "organisation_connexions"){
                this.getConnexions(clusterId);
            }else{
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Тип запроса неопределен!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                });
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка получения данных!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    getMap = async(clusterId) => {

        const toaster = this.props.toaster;

        try{

            this.setLoading();

            let organisationMap = await getClusterMap(clusterId);

            this.setState({
                organisationsList: organisationMap.data,
                loading: false
            });

        }catch(e){

            this.setState({
                organisationsList: [],
                loading: false
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setContractType = async(connexionType) => {
        const toaster = this.props.toaster;

        try{
            this.setState({connexionType: connexionType}, function() {
                this.getConnexions(this.state.clusterId);
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка установки типа связей!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    getConnexions = async(clusterId) => {

        const toaster = this.props.toaster;

        try{

            this.setLoading();

            let connexions = await getOrganisationConnexions(clusterId, this.state.periodRange);

            if(!isEmpty(connexions.data)){

                this.setState({connexionsList: connexions.data}, function() {
                    this.filterConnexionsByCount(this.state.percent_of_connexions);
                });

            }

            this.setState({loading: false});

        }catch(e){

            this.setState({
                connexionsList: null,
                loading: false
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getConnexionsByOrganisationId = async(organisationId, nodeId) => {
        const toaster = this.props.toaster;
        //check percent of display
        try{

            //////////////////////////////////////////////////
            //                                              //
            //                    FIX IT                    //
            //      (filtering and gettin all levels)       //
            //                                              //
            //////////////////////////////////////////////////

            if(!isNull(organisationId)){
                let org_connexions = await getOrganisationConnexionsByOrganisationId(organisationId, this.state.connexionType, nodeId, this.state.periodRange); 

                if(!isEmpty(org_connexions.data)){

                    console.log(org_connexions);

                    // if(!isEmpty(connexions.data)){

                    //     this.setState({connexionsList: connexions.data}, function() {
                    //         this.filterConnexionsByCount(this.state.percent_of_connexions);
                    //     });
        
                    // }
                    
            //         let current_data = this.state.connexionsList;

            //         console.log("current_data", current_data);

            //         let replace_data = current_data.map((cluster) => {
            //             let cluster_childrens = cluster.children;
            //             let result = cluster_childrens.map((item) => {
            //                 console.log("item", item);
            //                 //item.map if levelId of infobase 1..3 
            //                 if(item.organisationId === node.data.organisationId){
            //                     item.children = org_connexions.data;
            //                 }

            //                 return item;
            //             })

            //             cluster.children = result;

            //             return cluster;
            //         })

            //         console.log("replace_data", replace_data);

            //         this.setState({
            //             connexionsList: replace_data
            //         });

            //         node.data.__rd3t.collapsed = false;
                }

            }


        }catch(e){


        }
    }

    setDisplayPercent = async(value) => {

        const toaster = this.props.toaster;

        try{

            let percent;
            if(!isNull(value)){
                this.setState({percent_of_connexions: value});
                percent = value;
            }else{
                this.setState({percent_of_connexions: 100});
                percent = 100;
            }

            if(!isEmpty(this.state.connexionsList)){
                this.filterConnexionsByCount(percent);
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка установки процента видимости связей!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    setPeriod = async(value) => {

        const toaster = this.props.toaster;

        try{

            let period = [];

            if(!isNull(value)){

                for(const date of value){
                    let date_converted = format(new Date(date), 'yyyy-MM-dd');
                    period.push(date_converted);
                }

                period[0] = period[0] + 'T00:00:00';
                period[1] = period[1] + 'T23:59:59';

                this.setState({ periodRange: period });

            }else{
                this.setState({ periodRange: null });
            }

            if(!isNull(this.state.clusterId)){
                
                this.setLoading();

                let connexions = await getOrganisationConnexions(this.state.clusterId, period);
       
                if(!isEmpty(connexions.data)){
                    this.setState({connexionsList: connexions.data}, function() {
                        this.filterConnexionsByCount(this.state.percent_of_connexions);
                    });
                }
    
                this.setState({loading: false});
            }

        }catch(e){
            
            this.setState({
                connexionsList: null,
                loading: false
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка организаций по указанному периоду!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    filterConnexionsByCount = async(percent) => {

            //////////////////////////////////////////////////////////////////
            //                                                              //
            //                            FIX IT                            //
            //      (connexionsList and filtered_connexions are same)       //
            //                                                              //
            //////////////////////////////////////////////////////////////////

        const toaster = this.props.toaster;

        try{
            if(!isEmpty(this.state.connexionsList)){
                const original_data = this.state.connexionsList;
                const nodes = [...original_data.nodes];
                const edges = [...original_data.edges];

                let show_hidden_orgs = false;

                let replace_data = {};

                let first_level_orgs = nodes.filter((node) => node.data.infobaseLevelId === 1);
                let first_level_length = Math.ceil(first_level_orgs.length / 100 * percent);

                let elements = [];

                let replace_id = uuidv4();
                first_level_orgs.map((node, index) => {

                    if(index <= first_level_length){
                        node.data.getConnexionsByOrganisationId = this.getConnexionsByOrganisationId;
                        elements.push(node);
                    }

                })

                if(first_level_orgs.length > elements.length){

                    let diff_count = Number(first_level_orgs.length) - Number(elements.length);

                    let hidden_nodes_info = {
                        id: `${replace_id}`,
                        type: 'output',
                        data: { label: `Скрытых организаций: ${diff_count}` },
                        position: { x: elements[elements.length - 1].position.x + 250, y: 155 },
                        style: { backgroundColor: '#6ede87', color: 'white', fontSize: '10px', flexDirection: 'column', display: 'flex', justifyContent: 'center' },
                    };
                    
                    elements.push(hidden_nodes_info);
                    show_hidden_orgs = true;
                }

                let replace_cluster = {
                    id: '1',
                    type: 'input',
                    data: { label: "Сервер" },
                    position: { x: elements[elements.length - 1].position.x/2, y: 15 },
                    style: { backgroundColor: '#6ede87', color: 'white', fontSize: '10px' },
                };

                elements.push(replace_cluster);

                let replace_edges = [];

                edges.map((edge) => {

                    elements.map((element) => {
                        if(element.id === edge.target){
                            replace_edges.push(edge);
                        }
                    })
                   
                });

                if(show_hidden_orgs === true){
                    replace_edges.push({ id: `e1-${replace_id}`, source: '1', target: `${replace_id}`, animated: true });
                }

                replace_data = {
                    nodes: elements,
                    edges: replace_edges
                }

                this.setState({filtered_connexions: replace_data});
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка фильтрации связей!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    setLoading = async() => {
        this.setState({
            loading: true
        });
    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.setClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    render() {

        let mapType = this.state.mapType;

        const info_modal_open = this.state.info_modal_open;
        const okveds_modal_open = this.state.okveds_modal_open;

        let modal_data = this.state.data;
        let okveds_data = this.state.okveds;

        let clusters = this.state.clusterList;

        let cluster_map = this.state.organisationsList;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const connexion_type = [{caption: 'От продавца', value: 'СПоставщиком'}, {caption: 'От покупателя', value: 'СПокупателем'}].map(
            item => ({ label: item.caption, value: item.value })
        );

        const percentage = [...Array(100)].map((_, i) => i + 1);

        const connexion_display = percentage.map(
            item => ({ label: item, value: item })
        );

        const filtered_connexions = this.state.filtered_connexions;
        const edges = filtered_connexions.edges;
        const nodes = filtered_connexions.nodes;

        const ReactFlowStyled = styled(ReactFlow)`
            background-color: ${(props) => props.theme.bg};
        `;

        const MiniMapStyled = styled(MiniMap)`
            background-color: ${(props) => props.theme.bg};

            .react-flow__minimap-mask {
                fill: ${(props) => props.theme.minimapMaskBg};
            }

            .react-flow__minimap-node {
                fill: ${(props) => props.theme.nodeBg};
                stroke: none;
            }
        `;

        const ControlsStyled = styled(Controls)`
            button {
                background-color: ${(props) => props.theme.controlsBg};
                color: ${(props) => props.theme.controlsColor};
                border-bottom: 1px solid ${(props) => props.theme.controlsBorder};

                &:hover {
                background-color: ${(props) => props.theme.controlsBgHover};
                }

                path {
                fill: currentColor;
                }
            }
        `;

        const Card = props => (
            <Panel {...props} bordered 
                header={
                    <Stack wrap spacing={10} justifyContent="space-between">
                        <span>
                            [{props.item.infobaseLevelId === 1 ? "Флагман" : "Техничка"}] {props.item.infobaseName}
                        </span>
                        <ButtonToolbar>
                            <ButtonGroup>
                                <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                                    <IconButton icon={<EditIcon />} onClick={() => this.setState({info_modal_open: true, data: props.item})} size="xs"/>
                                </Whisper>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Stack>
                } 
                style={{margin: "10px", height: "300px", borderRadius: "25px"}}
            >
                <h5 style={{display: "block", fontSize: "12px", lineHeight: "24px"}}>{props.item.name}</h5>
                <span style={{display: "block", fontSize: "11px", lineHeight: "22px"}}>ИНН: {props.item.inn} КПП: {props.item.kpp}</span>
                <span style={{display: "block", fontSize: "11px", lineHeight: "22px"}}>Дата регистрации: <b>
                    {!isNull(props.item.register_date) ?
                        format(new Date(props.item.register_date), 'yyyy-MM-dd') :
                        "Неизвестно"
                    }
                </b></span>
                <span style={{display: "block", fontSize: "11px", lineHeight: "22px"}}>ОКВЭД: {props.item.okved} <Whisper placement='top' speaker={<Tooltip>{props.item.services}</Tooltip>}><IconButton icon={<InfoOutlineIcon />} appearance="subtle" style={{color: "#00FF00", cursor: "pointer"}} disabled={!isEmpty(props.item.okveds) ? false : true} onClick={() => this.setState({ okveds_modal_open: true, okveds: props.item.okveds})} size="xs"/></Whisper></span>
                {!isNull(props.item.owner) &&
                    <span style={{display: "block", fontSize: "11px", lineHeight: "22px"}}>Директор: {props.item.owner}</span>
                }
                <span style={{display: "block", fontSize: "11px", lineHeight: "22px"}}>Сотрудники: {isNull(props.item.employee_count) ? 0 : props.item.employee_count}</span>
                <span style={{display: "block", fontSize: "11px", lineHeight: "22px"}}><b>Сдача НД</b>: <b>{format(new Date(props.item.report_date), 'yyyy-MM-dd')}</b> 21:00 МСК</span>

                <IconButton icon={<FileDownloadIcon />} onClick={() => this.getOrganisationCardInPDF(props.item.organisationId)} size="xs" style={{position: "absolute", bottom: "30px"}}> Карточка компании</IconButton>
            </Panel>
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaRegMap/></i> Карта кластера</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10} justifyContent="space-between">
                                        <ButtonToolbar>
                                            <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.setClusterId(value)}/>
                                            {this.state.mapType === "organisation_connexions" && <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" style={{ width: 200 }} value={this.convertDate(this.state.periodRange)} onChange={(value) => this.setPeriod(value)} onClean={(event) => this.setPeriod(null)}/>}
                                            {this.state.mapType === "organisation_connexions" && <SelectPicker data={connexion_type} style={{ width: 200 }} cleanable={false} value={this.state.connexionType} onChange={(value) => this.setContractType(value)} searchable={false} placeholder="Тип схемы"/>}
                                            {this.state.mapType === "organisation_connexions" && <SelectPicker data={connexion_display} style={{ width: 200 }} cleanable={false} onChange={(value) => this.setDisplayPercent(value)} value={this.state.percent_of_connexions} searchable={false} placeholder="% отбора контрагентов"/>}
                                            <IconButton icon={<ReloadIcon />}>Обновить</IconButton>
                                        </ButtonToolbar>
                                        <RadioGroup name="radioList" inline appearance="picker" defaultValue="cluster_map" onChange={(value) => this.setReqType(value)}>
                                            <span style={viewStyles.radioGroupLabel}>Вид: </span>
                                            <Radio value="cluster_map">Карта кластера</Radio>
                                            <Radio value="organisation_connexions">Карта связей</Radio>
                                        </RadioGroup>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                {mapType === "cluster_map" ? (
                                    <div>
                                        {this.state.loading ? (
                                            <Panel bordered style={{margin: "10px"}}>
                                                <Row>
                                                    <div style={{width: "100%", textAlign: 'center', padding: "15px 0"}}>
                                                        <Loader size="lg" content="Получение данных..." vertical />
                                                    </div>
                                                </Row>
                                            </Panel>
                                        ) : (
                                            <Panel bordered style={{margin: "10px"}}>
                                                <Row>
                                                    { !isEmpty(cluster_map) ?
                                                        cluster_map.map((item) => {
                                                            return (
                                                                <Col md={4} sm={12}>
                                                                    <Card item={item}/>
                                                                </Col>
                                                            )
                                                        }):
                                                        <span style={{display: "block", textAlign: "center"}}>Данные отсутствует. Выберите сервер.</span>
                                                    }
                                                </Row>
                                            </Panel>
                                        )}
                                    </div>
                                ) : (
                                    <div>
                                        {this.state.loading ? (
                                            <Panel bordered style={{margin: "10px"}}>
                                                <Row>
                                                    <div style={{width: "100%", textAlign: 'center', padding: "15px 0"}}>
                                                        <Loader size="lg" content="Получение данных..." vertical />
                                                    </div>
                                                </Row>
                                            </Panel>
                                        ) : (
                                            <Panel bordered style={{margin: "10px"}}>
                                                <Row>
                                                    { !isEmpty(filtered_connexions) ?
                                                        <div id="treeWrapper" style={{ width: '100%', height: '800px', overflow: "auto" }}>
                                                            <ThemeProvider theme={darkTheme}>
                                                                <ReactFlowStyled 
                                                                    nodes={nodes} 
                                                                    edges={edges} 
                                                                    nodesConnectable={false} 
                                                                    nodesDraggable={false} 
                                                                    nodeTypes={nodeTypes}
                                                                    fitView 
                                                                    onLoad={(instance) => setTimeout(instance.fitView)}
                                                                >
                                                                    <MiniMapStyled nodeColor="#6ede87" nodeStrokeWidth={3} position='top-left' zoomable pannable ariaLabel={null} style={{width: 400, height: 150}} />
                                                                    <ControlsStyled showInteractive={false}/>
                                                                    <PanelReactFlow position="top-right">
                                                                        <div style={{width: "180px", backgroundColor: "rgba(52, 52, 53, 0.6)", color: "#fff", padding: "7px", borderRadius: "25px"}}>
                                                                            <span style={{display: "block", margin: "7px", fontWeight: "bold"}}>Обозначения:</span>
                                                                            <span style={{display: "block", margin: "7px"}}><MdOutlineTrendingUp style={{color: "green"}}/> Приход</span>
                                                                            <span style={{display: "block", margin: "7px"}}><AiOutlineFall style={{color: "red"}}/> Расход</span>
                                                                            <span style={{display: "block", margin: "7px"}}><GoDiff style={{color: "orange"}}/> Расхождение</span>
                                                                            <span style={{display: "block", margin: "7px"}}><AiOutlineArrowUp style={{color: "green"}}/> НДС выставленный</span>
                                                                            <span style={{display: "block", margin: "7px"}}><AiOutlineArrowDown style={{color: "red"}}/> НДС полученный</span>
                                                                        </div>
                                                                    </PanelReactFlow>
                                                                </ReactFlowStyled>
                                                            </ThemeProvider>
                                                        </div>:
                                                        <span style={{display: "block", textAlign: "center"}}>Данные отсутствует. Выберите кластер.</span>
                                                    }
                                                </Row>
                                            </Panel>
                                        )}
                                    </div>
                                )}
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {info_modal_open && <MapOfClusterInfoModal open={this.state.info_modal_open} onClose={() => this.setState({ info_modal_open: false })} data={modal_data} toaster={this.props.toaster}/>}
                {okveds_modal_open && <OkvedsModal open={this.state.okveds_modal_open} onClose={() => this.setState({ okveds_modal_open: false })} data={okveds_data} toaster={this.props.toaster}/>}
            </main>    
        );
    };
}

export default MapOfCluster;