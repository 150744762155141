import React from 'react';
import { Container, Header, FlexboxGrid, Tooltip, Whisper, IconButton, Table, InputGroup, Input, Stack, Pagination, Panel, ButtonGroup, Notification } from 'rsuite';
import { isNull } from "lodash";

//import icons
import { VscRequestChanges } from "react-icons/vsc";
import SearchIcon from '@rsuite/icons/Search';
import TagUnauthorizeIcon from '@rsuite/icons/TagUnauthorize';
import TagAuthorizeIcon from '@rsuite/icons/TagAuthorize';
import ReloadIcon from '@rsuite/icons/Reload';
import Trash from '@rsuite/icons/legacy/Trash';
import CheckSquare from '@rsuite/icons/legacy/CheckSquare';

//import common
import ConfirmModal from "../Common/ConfirmModal";

//import controllers
import { getAllRequests } from '../../controllers/clientsController';

const { Column, HeaderCell, Cell } = Table;

class Requests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 10,
            page: 1,
            requestsList: [],
            loading: false,
            confirm: false,
            caption: '',
            text: "",
            buttonIcon: null,
            buttonOkText: '',
            onAction: null,
            actionRequestBody: null,
            color: ""
        };
        this.getRequests = this.getRequests.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.approveRequest = this.approveRequest.bind(this);
        this.discardRequest = this.discardRequest.bind(this);
        this.handleApproveRequest = this.handleApproveRequest.bind(this);
        this.handleDiscardRequest = this.handleDiscardRequest.bind(this);
    }

    getRequests = async() => {

        const toaster = this.props.toaster;

        try{

            let requests = await getAllRequests();

            this.setState({
                requestsList: requests.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Запросы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    handleApproveRequest = async(rowData) => {

        const toaster = this.props.toaster;

        if(isNull((rowData.requestId))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Заявка не выбрана!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let type_text;

        if (rowData.typeId === 1) {
            type_text = "создание организации";
        }else if(rowData.typeId === 2){
            type_text = "изменение организации";
        }else if(rowData.typeId === 3){
            type_text = "сброс пароля";
        }

        let fields = {
            requestId: rowData.requestId,
        }

        this.confirmAction("green", "Утверждение запроса", `Утвердить запрос №${rowData.requestId} на ${type_text} для агента ${rowData.userId}?`, <TagAuthorizeIcon style={{color: '#fff'}}/>, "Утвердить запрос", this.approveRequest, fields );
    }

    approveRequest = async(fields) => {
        const toaster = this.props.toaster;

        try{

            console.log("Операция проведения: " + fields.requestId);

            //update list or one document

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Заявка не была утверждена!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleDiscardRequest = async(rowData) => {

        const toaster = this.props.toaster;

        if(isNull((rowData.requestId))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Заявка не выбрана!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let fields = {
            requestId: rowData.requestId,
        }

        this.confirmAction("red", "Удаление заявки", `Удалить заявку №${rowData.requestId}? Внимание! Это действие необратимо!`, <Trash style={{color: '#fff'}}/>, "Удаление заявки", this.discardRequest, fields );
    }

    discardRequest = async(fields) => {
        const toaster = this.props.toaster;

        try{

            console.log("Операция удаления: " + fields.requestId);

            //update list or one document

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка удаления заявки!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm: true, 
            color: color, 
            caption: caption, 
            text: text, 
            buttonIcon: buttonIcon, 
            buttonOkText: buttonOkText, 
            onAction: onAction, 
            actionRequestBody: actionRequestBody
        });
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    componentDidMount() {
        this.getRequests();
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    render() {

        const confirm = this.state.confirm;
        const requests = this.state.requestsList;

        const data = requests.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const StatusCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { (rowData.completed === true) ? <span style={{color: '#00FF00'}}>Утвержден</span> : <span style={{color: '#fff'}}>Создан</span>}
            </Cell>
        );

        const BodyCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {JSON.stringify(rowData.requestBody)}
            </Cell>
        );

        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Cell {...props}>                                               
                    <ButtonGroup>
                        <Whisper placement='top' speaker={<Tooltip>Утвердить</Tooltip>}>
                            <IconButton icon={<CheckSquare/>} onClick={() => this.handleApproveRequest(rowData)}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                            <IconButton icon={<Trash/>} onClick={() => this.handleDiscardRequest(rowData)} />
                        </Whisper>
                    </ButtonGroup>
                </Cell>
            );
        };

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><VscRequestChanges/></i> Заявки агентов</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <IconButton icon={<ReloadIcon />}>Обновить</IconButton>
                                        <InputGroup inside style={{ width: 200 }}>
                                            <Input />
                                            <InputGroup.Button>
                                                <SearchIcon />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="requestId" />
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Статус</HeaderCell>
                                        <StatusCell/>
                                    </Column>               
                                    <Column width={150} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Тип запроса</HeaderCell>
                                        <Cell dataKey="typeId" />
                                    </Column>
                                    <Column width={350} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Тело запроса</HeaderCell>
                                        <BodyCell dataKey="requestBody" />
                                    </Column>
                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Владелец</HeaderCell>
                                        <Cell dataKey="userId" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <ActionCell/>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={requests.length} limitOptions={[10, 30, 50]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {confirm && 
                    <ConfirmModal
                        open={this.state.confirm} 
                        onClose={() => this.setState({ 
                            confirm: false,
                            caption: '',
                            text: "",
                            buttonIcon: null,
                            buttonOkText: '',
                            onAction: null,
                            actionRequestBody: null,
                            color: "",
                        })} 
                        color={this.state.color} 
                        caption={this.state.caption} 
                        text={this.state.text} 
                        buttonIcon={this.state.buttonIcon} 
                        buttonOkText={this.state.buttonOkText} 
                        onAction={this.state.onAction} 
                        actionRequestBody={this.state.actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default Requests;
