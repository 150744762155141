import React from 'react';
import { Container, Header, Checkbox, FlexboxGrid, DateRangePicker, Tooltip, Whisper, IconButton, Table, InputGroup, Input, SelectPicker, Stack, Pagination, Panel, ButtonGroup, Notification, Dropdown, Popover } from 'rsuite';
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import icons
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import SearchIcon from '@rsuite/icons/Search';
import TagUnauthorizeIcon from '@rsuite/icons/TagUnauthorize';
import TagAuthorizeIcon from '@rsuite/icons/TagAuthorize';
import TagIcon from '@rsuite/icons/Tag';
import ReloadIcon from '@rsuite/icons/Reload';
import Trash from '@rsuite/icons/legacy/Trash';
import CheckSquare from '@rsuite/icons/legacy/CheckSquare';
import Print from '@rsuite/icons/legacy/Print';

//import common
import predifinedVars from '../Common/predifinedVars';
import ConfirmModal from "../Common/ConfirmModal";

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../../controllers/clusterController';
import { getListOfDocumentsSchetFacturaIn, getSelectedDocumentPrintForm } from '../../controllers/documentsController'

const { Column, HeaderCell, Cell } = Table;

class SchetFacturaIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            clusterId: null,
            clusterList: [],
            infobasesList: [],
            organisationsList: [],
            documentId: null,
            infobaseName: null,
            periodRange: null,
            organisationId: null,
            docs: [],
            loading: false,
            confirm: false,
            caption: '',
            text: "",
            buttonIcon: null,
            buttonOkText: '',
            onAction: null,
            actionRequestBody: null,
            color: ""
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.setPeriod = this.setPeriod.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.setOrganisation = this.setOrganisation.bind(this);
        this.setTableLoading = this.setTableLoading.bind(this);
        this.changePostStatus = this.changePostStatus.bind(this);
        this.deleteDocument = this.deleteDocument.bind(this);
        this.handleChangePostStatus = this.handleChangePostStatus.bind(this);
        this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
        this.getPrintForm = this.getPrintForm.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    handleChangePostStatus = async(rowData) => {

        const toaster = this.props.toaster;

        if(isNull((rowData.Ref_Key))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Документ не выбран!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        if (rowData.Posted === false && rowData.DeletionMark === true) {
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Документ помечен на удаление и не может быть проведен!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let status;
        let status_text;
        let status_short_text;
        let status_color;
        let icon;

        if (rowData.Posted === true && rowData.DeletionMark === false) {
            status = false;
            status_text = "Снять проведение с документа";
            icon = <TagUnauthorizeIcon style={{color: '#fff'}}/>;
            status_color = "blue";
            status_short_text = "Отмена проведения документа";
        }else if(rowData.Posted === false && rowData.DeletionMark === false){
            status = true;
            status_text = "Провести документ";
            icon = <TagAuthorizeIcon style={{color: '#fff'}}/>;
            status_color = "green";
            status_short_text = "Проведение документа";
        }

        let fields = {
            documentId: rowData.Ref_Key,
            status: status
        }

        this.confirmAction(`${status_color}`, "Проведение документа", `${status_text} Реализация товаров и услуг №${rowData.Number} от ${rowData.Date}?`, icon, status_short_text, this.changePostStatus, fields );
    }

    changePostStatus = async(fields) => {
        const toaster = this.props.toaster;

        try{

            console.log("Операция проведения: " + fields.status);

            //update list or one document

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Статус проведения документа не изменен!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleDeleteDocument = async(rowData) => {

        const toaster = this.props.toaster;

        if(isNull((rowData.Ref_Key))){
            return(
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Документ не выбран!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                })
            )
        }

        let fields = {
            documentId: rowData.Ref_Key,
        }

        this.confirmAction("red", "Удаление документа", `Удалить документ Реализация товаров и услуг №${rowData.Number} от ${rowData.Date}? Внимание! Это действие необратимо!`, <Trash style={{color: '#fff'}}/>, "Удаление документа", this.deleteDocument, fields );
    }

    deleteDocument = async(fields) => {
        const toaster = this.props.toaster;

        try{

            console.log("Операция удаления: " + fields.documentId);

            //update list or one document

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Ошибка удаления документа!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    confirmAction = async(color, caption, text, buttonIcon, buttonOkText, onAction, actionRequestBody) => {
        this.setState({
            confirm: true, 
            color: color, 
            caption: caption, 
            text: text, 
            buttonIcon: buttonIcon, 
            buttonOkText: buttonOkText, 
            onAction: onAction, 
            actionRequestBody: actionRequestBody
        });
    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getPrintForm = async(documentId, printformType, docType) => {
        await getSelectedDocumentPrintForm(this.state.infobaseName, documentId, printformType, docType);
    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

            this.setTableLoading();

            try{

                let docs = await getListOfDocumentsSchetFacturaIn(value, this.state.organisationId, this.state.periodRange);

                this.setState({ docs: docs.data, loading: false, page: 1 });

            }catch(e){
            
                this.setState({ docs: [], loading: false, page: 1 });
    
                toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка документов!</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                });
            }

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setPeriod = async(value) => {

        const toaster = this.props.toaster;

        try{

            let period = [];

            if(!isNull(value)){

                for(const date of value){
                    let date_converted = format(new Date(date), 'yyyy-MM-dd');
                    period.push(date_converted);
                }

                period[0] = period[0] + 'T00:00:00';
                period[1] = period[1] + 'T23:59:59';

                this.setState({ periodRange: period });

            }else{
                this.setState({ periodRange: null });
            }

            if(!isNull(this.state.infobaseName)){
                this.setTableLoading();

                let docs = await getListOfDocumentsSchetFacturaIn(this.state.infobaseName, this.state.organisationId, period);

                this.setState({ docs: docs.data, loading: false, page: 1 });
            }

        }catch(e){
            
            this.setState({ docs: [], loading: false, page: 1 });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка документов!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    setOrganisation = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ organisationId: value });

            this.setTableLoading();
            
            let docs = await getListOfDocumentsSchetFacturaIn(this.state.infobaseName, value, this.state.periodRange);

            this.setState({ docs: docs.data, loading: false, page: 1 });

        }catch(e){
            
            this.setState({ docs: [], loading: false, page: 1 });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Произошла ошибка при получении списка документов!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let organisationsList = this.state.organisationsList;
        const confirm = this.state.confirm;
        const docs = this.state.docs;

        let documentId = this.state.documentId;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationId })
        );

        const data = docs.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const StatusCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { (rowData.Posted === true && rowData.DeletionMark === false) && <TagAuthorizeIcon style={{color: '#00FF00'}}/>}
                { (rowData.Posted === false && rowData.DeletionMark === true) && <TagUnauthorizeIcon style={{color: '#FA5F55'}}/>}
                { (rowData.Posted === false && rowData.DeletionMark === false) && <TagIcon style={{color: '#fff'}}/>}
            </Cell>
        );

        const DateCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {format(new Date(rowData.Date), 'yyyy-MM-dd')}
            </Cell>
        );

        const OrgCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {isNull(rowData.Организация) ? 
                    <span style={{color: "#FA5F55"}}>Отсутствует</span> :
                    <span organisationId={rowData.Организация.Ref_Key}>{isNull(rowData.Организация.НаименованиеСокращенное) ? rowData.Организация.НаименованиеПолное : rowData.Организация.НаименованиеСокращенное}</span>
                }
            </Cell>
        );

        const ContragentCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {isNull(rowData.Контрагент) ? 
                    <span style={{color: "#FA5F55"}}>Отсутствует</span> :
                    <span contragentId={rowData.Контрагент.Ref_Key}>{isNull(rowData.Контрагент.Description) ? rowData.Контрагент.НаименованиеПолное : rowData.Контрагент.Description}</span>
                }
            </Cell>
        );

        const ContractCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {
                    !isNull(rowData.ДоговорКонтрагента) ?
                    <span>{rowData.ДоговорКонтрагента.Description}</span> :
                    <span style={{color: "#FA5F55"}}>Отсутствует</span>
                }
            </Cell>
        );

        const NDSCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {
                    rowData.СчетФактураБезНДС === true ?
                    <span>Да</span> :
                    <span>Нет</span>
                }
            </Cell>
        );

        const InputDateCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {format(new Date(rowData.ДатаВходящегоДокумента), 'yyyy-MM-dd')}
            </Cell>
        );

        const ActionCell = ({ rowData, dataKey, ...props }) => {
            return (
                <Cell {...props}>                                               
                    <ButtonGroup>
                        <Whisper placement='top' speaker={<Tooltip>Открыть</Tooltip>}>
                            <IconButton icon={<SearchIcon />} onClick={() => this.setState({documentId: rowData.Ref_Key, sale_modal_open: true})}/>
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Провести</Tooltip>}>
                            <IconButton icon={<CheckSquare/>} onClick={() => this.handleChangePostStatus(rowData)}/>
                        </Whisper>
                        <Whisper 
                            placement="autoVerticalStart"
                            trigger="click" 
                            speaker={(props, ref) => {
                                const { onClose, left, top, className } = props;
                                const handleSelect = eventKey => {
                                    if (eventKey === 1){
                                        this.getPrintForm(rowData.Ref_Key, "upd", "SFIncoming")
                                    }
                                    if (eventKey === 2){
                                        this.getPrintForm(rowData.Ref_Key, "sf", "SFIncoming")
                                    }
                                    onClose();
                                };
                                return (
                                    <Popover ref={ref} className={className} style={{ left, top }} full>
                                        <Dropdown.Menu onSelect={handleSelect}>
                                            <Dropdown.Item eventKey={1} icon={<Print />}>УПД за поставщика</Dropdown.Item>
                                            <Dropdown.Item eventKey={2} icon={<Print />}>Счет-фактура за поставщика</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Popover>
                                )
                            }}
                        >
                            <IconButton icon={<Print />} />
                        </Whisper>
                        <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                            <IconButton icon={<Trash/>} onClick={() => this.handleDeleteDocument(rowData)} />
                        </Whisper>
                    </ButtonGroup>
                </Cell>
            );
        };

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><LiaFileInvoiceDollarSolid/></i> Счета-Фактуры полученные</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" style={{ width: 200 }} value={this.convertDate(this.state.periodRange)} onChange={(value) => this.setPeriod(value)} onClean={(event) => this.setPeriod(null)}/>
                                        <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Сервер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                        <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} placeholder='Информационная база' onChange={(value) => this.getOrganisationsList(value)}/>
                                        <SelectPicker data={organisations} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(organisationsList) ? false : true} placeholder='Организации' onChange={(value) => this.setOrganisation(value)} onClean={(event) => this.setOrganisation(null)}/>
                                        <IconButton icon={<ReloadIcon />} disabled={!isNull(this.state.infobaseName) ? false : true}>Обновить</IconButton>
                                        <InputGroup inside style={{ width: 200 }}>
                                            <Input />
                                            <InputGroup.Button>
                                                <SearchIcon />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" loading={this.state.loading} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <StatusCell/>
                                    </Column>
                                    <Column width={100} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="Number" />
                                    </Column>
                                    <Column width={90} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Дата</HeaderCell>
                                        <DateCell dataKey="Date" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Вид</HeaderCell>
                                        <Cell dataKey="ВидСчетаФактуры" />
                                    </Column>
                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Организация</HeaderCell>
                                        <OrgCell dataKey="Организация" />
                                    </Column>
                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Контрагент</HeaderCell>
                                        <ContragentCell dataKey="Контрагент" />
                                    </Column>
                                    <Column width={150} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Договор</HeaderCell>
                                        <ContractCell dataKey="ДоговорКонтрагента" />
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Без НДС</HeaderCell>
                                        <NDSCell dataKey="СчетФактураБезНДС" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Сумма</HeaderCell>
                                        <Cell dataKey="СуммаДокумента" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Сумма НДС</HeaderCell>
                                        <Cell dataKey="СуммаНДСДокумента" />
                                    </Column>
                                    <Column width={140} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>№ вх.док.</HeaderCell>
                                        <Cell dataKey="НомерВходящегоДокумента" />
                                    </Column>
                                    <Column width={140} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Дата вх.док.</HeaderCell>
                                        <InputDateCell dataKey="ДатаВходящегоДокумента" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <ActionCell/>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={docs.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                {confirm && 
                    <ConfirmModal
                        open={this.state.confirm} 
                        onClose={() => this.setState({ 
                            confirm: false,
                            caption: '',
                            text: "",
                            buttonIcon: null,
                            buttonOkText: '',
                            onAction: null,
                            actionRequestBody: null,
                            color: "",
                        })} 
                        color={this.state.color} 
                        caption={this.state.caption} 
                        text={this.state.text} 
                        buttonIcon={this.state.buttonIcon} 
                        buttonOkText={this.state.buttonOkText} 
                        onAction={this.state.onAction} 
                        actionRequestBody={this.state.actionRequestBody}
                    />
                }
            </main>    
        );
    };
}

export default SchetFacturaIn;