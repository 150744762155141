import React from 'react';
import { IconButton, Modal } from 'rsuite';
import { isNull } from 'lodash';

//import icons
import Close from '@rsuite/icons/legacy/Close';

class UserDataModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="sm">
                <Modal.Header>
                    <Modal.Title>Информация о пользователе: {this.props.data.data.user.username}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span style={{display: "block"}}><b>Имя пользователя: </b>{this.props.data.data.user.username}</span>
                    <span style={{display: "block"}}><b>Идентификатор: </b>{this.props.data.data.user.userId}</span>
                    <span style={{display: "block"}}><b>Внешний идентификатор: </b>{this.props.data.data.user.userExternalId}</span>
                    <span style={{display: "block"}}><b>Имя: </b>{this.props.data.data.user.firstname}</span>
                    <span style={{display: "block"}}><b>Фамилия: </b>{this.props.data.data.user.lastname}</span>
                    <span style={{display: "block"}}><b>email: </b>{this.props.data.data.user.email}</span>
                    <span style={{display: "block"}}><b>telegram: </b><a style={{textDecoration: "none"}} href={`https://t.me/${this.props.data.data.user.telegram}`}>{this.props.data.data.user.telegram}</a></span>
                    <span style={{display: "block"}}><b>Телефон: </b><a style={{textDecoration: "none"}} href={`tel:${this.props.data.data.user.phone}`}>{this.props.data.data.user.phone}</a></span>
                    <span style={{display: "block"}}>
                        <b>Статус: </b>
                        {this.props.data.data.user.statusId === 1 && <b style={{color:'green'}}>Верифицирован</b>}
                        {this.props.data.data.user.statusId === 2 && <b style={{color:'red'}}>Заблокирован</b>}
                        {this.props.data.data.user.statusId === 3 && <b style={{color:'white'}}>Создан</b>}
                    </span>
                    <span style={{display: "block"}}>
                        <b>Роль: </b>
                        {this.props.data.data.user.roleId === 1 && <b style={{color:'green'}}>Главный администратор</b>}
                        {this.props.data.data.user.roleId === 2 && <b style={{color:'yellow'}}>Администратор</b>}
                        {this.props.data.data.user.roleId === 3 && <b style={{color:'white'}}>Бухгалтер</b>}
                        {this.props.data.data.user.roleId === 4 && <b style={{color:'white'}}>Организация</b>}
                        {this.props.data.data.user.roleId === 5 && <b style={{color:'white'}}>Агент</b>}
                    </span>
                    { !isNull(this.props.data.data.params) &&
                        <span style={{display: "block"}}>
                            <b>Разрешены уведомления: </b>
                            {this.props.data.data.params.notifyMailAllow === true ? <b style={{color:'green'}}>Да</b> : <b style={{color:'red'}}>Нет</b>}
                        </span>
                    }
                    { !isNull(this.props.data.data.params) &&
                        <span style={{display: "block"}}><b>Адрес уведомлений: </b>{this.props.data.data.params.notifyMailAddress}</span>
                    }
                    <span style={{display: "block"}}><b>Последний вход: </b>{this.props.data.data.user.last_login}</span>
                    <span style={{display: "block"}}><b>Создан: </b>{this.props.data.data.user.createdAt}</span>
                    <span style={{display: "block"}}><b>Обновлен: </b>{this.props.data.data.user.updatedAt}</span>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default UserDataModal;