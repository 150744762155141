import React from 'react';
import { Container, Header, Checkbox, FlexboxGrid, Tooltip, Whisper, IconButton, Table, InputGroup, Input, Stack, Pagination, Panel, ButtonGroup, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import { SiEnvoyproxy } from 'react-icons/si';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import ReloadIcon from '@rsuite/icons/Reload';
import Edit from '@rsuite/icons/Edit';
import Trash from '@rsuite/icons/legacy/Trash';
import Check from '@rsuite/icons/legacy/Check';
import CloseIcon from '@rsuite/icons/Close';

//import components
import ProxyAddModal from './SpravActionForms/ProxyAddModal';

//import controllers;
import { getAllProxy } from '../../controllers/systemController';

const { Column, HeaderCell, Cell } = Table;

class Proxy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 100,
            page: 1,
            proxyList: [],
            loading: false,
            setAddProxyOpen: false
         };
         this.getProxy = this.getProxy.bind(this);
         this.updateEntries = this.updateEntries.bind(this);
         this.setTableLoading = this.setTableLoading.bind(this);
    }

    getProxy = async() => {
        
        try{

            this.setTableLoading();
           
            let allProxy = await getAllProxy();

            this.setState({
                proxyList: allProxy.data,
                loading: false
            });

        }catch(e){
            
            this.setState({
                proxyList: [],
            });
        }
    }

    setTableLoading = async() => {

        this.setState({
            loading: true
        });

    }

    updateEntries = async() => {
        this.getProxy();
    }

    componentDidMount() {
        this.getProxy();
    }

    render() {

        const proxy = this.state.proxyList;

        const data = proxy.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const handleCloseClick = () => {
            this.setState({ setAddProxyOpen: false });
            this.updateEntries();
        };

        const ConnectionCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                { rowData.connection == true ? (
                    <Check/>
                ) : (
                    <CloseIcon/>
                )}
            </Cell>
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><SiEnvoyproxy/></i> Прокси</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <div className='middle-flex-large'>
                            <FlexboxGrid.Item colspan={24}>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <IconButton icon={<AddOutlineIcon />} onClick={() => this.setState({ setAddProxyOpen: true })}>Добавить</IconButton>
                                        <IconButton icon={<ReloadIcon />}>Обновить</IconButton>
                                        <InputGroup inside style={{ width: 200 }}>
                                            <Input />
                                            <InputGroup.Button>
                                                <SearchIcon />
                                            </InputGroup.Button>
                                        </InputGroup>
                                    </Stack>
                                </Panel>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={24}>
                                <Table data={data} autoHeight={true} loading={this.state.loading} rowHeight={64} style={{margin: "15px auto 0 auto", padding: "0 15px"}}>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell></HeaderCell>
                                        <Cell><Checkbox/></Cell>
                                    </Column>           
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>#</HeaderCell>
                                        <Cell dataKey="proxyId" />
                                    </Column>
                                    <Column width={200} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Наименование</HeaderCell>
                                        <Cell dataKey="name" />
                                    </Column>
                                    <Column width={250} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Адрес</HeaderCell>
                                        <Cell dataKey="address" />
                                    </Column>
                                    <Column width={60} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Порт</HeaderCell>
                                        <Cell dataKey="port" />
                                    </Column>
                                    <Column width={80} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Протокол</HeaderCell>
                                        <Cell dataKey="protocol" />
                                    </Column>
                                    <Column width={150} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Время ответа</HeaderCell>
                                        <Cell dataKey="latency" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Имя пользователя</HeaderCell>
                                        <Cell dataKey="username" />
                                    </Column>
                                    <Column width={120} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Пароль</HeaderCell>
                                        <Cell dataKey="password" />
                                    </Column>
                                    <Column width={90} align="center" verticalAlign="middle" fixed>
                                        <HeaderCell>Соединение</HeaderCell>
                                        <ConnectionCell dataKey="connection" />
                                    </Column>
                                    <Column flexGrow={1} align="center" verticalAlign="middle">
                                        <HeaderCell>...</HeaderCell>
                                        <Cell>                                               
                                            <ButtonGroup>
                                                <Whisper placement='top' speaker={<Tooltip>Обновить</Tooltip>}>
                                                    <IconButton icon={<Edit />} />
                                                </Whisper>
                                                <Whisper placement='top' speaker={<Tooltip>Удалить</Tooltip>}>
                                                    <IconButton icon={<Trash />} />
                                                </Whisper>
                                            </ButtonGroup>
                                        </Cell>
                                    </Column>
                                </Table>
                                <div style={{ padding: 20 }}>
                                    <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={proxy.length} limitOptions={[100, 300, 500]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                                </div>
                            </FlexboxGrid.Item>
                        </div>
                    </FlexboxGrid>
                </Container>
                <ProxyAddModal open={this.state.setAddProxyOpen} toaster={this.props.toaster} onClose={handleCloseClick}/>
            </main>    
        );
    };
}

export default Proxy;