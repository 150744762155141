import React from 'react';
import { Form, InputGroup, IconButton, Notification } from 'rsuite';
import { isEmpty, isNull } from "lodash";

//import icons
import OperatePeopleIcon from '@rsuite/icons/OperatePeople';
import Lock from '@rsuite/icons/legacy/Lock';
import AdminIcon from '@rsuite/icons/Admin';
import UserPlus from '@rsuite/icons/legacy/UserPlus';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import Phone from '@rsuite/icons/legacy/Phone';
import Telegram from '@rsuite/icons/legacy/Telegram';

//import controller;
import { createAdmin, checkAdmin } from "../../../controllers/initController";

class InitAdmin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
            username_onec: null,
            password_onec: null,
            telegram: null,
            phone: null,
            message: null,
            done: null
        };
        this.handleCreateAdmin = this.handleCreateAdmin.bind(this);
        this.checkAdmin = this.checkAdmin.bind(this);
    }

    checkAdmin = async() => {

        const toaster = this.props.toaster;

        try{

            let check = await checkAdmin();

            if(!isEmpty(check.data) || !isNull(check.data)){
                this.setState({done: true, message: check.message});
                this.props.check(false);
            }else{
                this.setState({done: false, message: null});
                this.props.check(true);
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    handleCreateAdmin = async() => {

        const toaster = this.props.toaster;

        let fields = {
            user_name: this.state.username,
            user_password: this.state.password,
            user_infobase_login: this.state.username_onec,
            user_infobase_password: this.state.password_onec,
            telegram: this.state.telegram,
            phone: this.state.phone
        }

        try{

            if(isEmpty(this.state.username) || isNull(this.state.username)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите имя администратора!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.password) || isNull(this.state.password)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите пароль администратора!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.username_onec) || isNull(this.state.username_onec)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите имя пользователя 1С!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.password_onec) || isNull(this.state.password_onec)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите пароль пользователя 1С!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.phone) || isNull(this.state.phone)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите номер телефона администратора!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.telegram) || isNull(this.state.telegram)){
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Укажите telegram (username) администратора!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            let request = await createAdmin(fields);

            if(!isEmpty(request)){

                toaster.push(<Notification showIcon header={'Информация'} type={'success'}>{request.message}</Notification>, {
                    duration: 10000,
                    placement: 'topEnd'
                });

                this.setState({done: true, message: request.message});
        
                this.props.check(false);
            }

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    componentDidMount() {
        this.props.check(true);
        this.checkAdmin();
    }

    render() {

        let done = this.state.done;

        return (
            <Form fluid>
                {done ? (
                    <div className='modal-fixed-custom'>
                        <Form.Group style={{textAlign: 'center'}}>
                            <span style={{display: "block", color: "green", fontSize: "48px", paddingBottom: "15px"}}><CheckOutlineIcon/></span>
                            <span style={{display: "block"}}>{this.state.message}</span>
                        </Form.Group>
                    </div>
                ) : (
                    <div className='modal-fixed-custom'>
                        <Form.Group controlId="username">
                            <Form.ControlLabel>Пользователь (только латинские буквы)</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><AdminIcon/></InputGroup.Addon>
                                <Form.Control name="username" onBlur={(event) => this.setState({ username: event.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.ControlLabel>Пароль (латинские буквы, цифры, специальные символы)</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password" type="password" autoComplete="off" onBlur={(event) => this.setState({ password: event.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="username_one_s">
                            <Form.ControlLabel>Пользователь 1С</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><OperatePeopleIcon/></InputGroup.Addon>
                                <Form.Control name="username_one_s" onBlur={(event) => this.setState({ username_onec: event.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="password_one_s">
                            <Form.ControlLabel>Пароль пользователя 1С</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password_one_s" type="password" autoComplete="off" onBlur={(event) => this.setState({ password_onec: event.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="telegram">
                            <Form.ControlLabel>Telegam (имя пользователя)*</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Telegram/></InputGroup.Addon>
                                <Form.Control name="telegram" onBlur={(event) => this.setState({ telegram: event.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="phone">
                            <Form.ControlLabel>Номер телефона</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Phone/></InputGroup.Addon>
                                <Form.Control name="phone" onBlur={(event) => this.setState({ phone: event.target.value })}/>
                            </InputGroup>
                        </Form.Group>
                        <IconButton icon={<UserPlus />} onClick={this.handleCreateAdmin}>Создать</IconButton>
                    </div>
                )}
            </Form>
        );
    };
}

export default InitAdmin;