import React from 'react';
import { Tooltip, Whisper, Nav } from 'rsuite';
import { isEmpty } from "lodash";

//icons
import Tools from '@rsuite/icons/Tools';

class MaintanceModeButton extends React.Component {

    render() {
        return (
            <Whisper 
                placement="bottom" 
                controlId="mode-hover" 
                trigger="hover" 
                speaker={
                    <Tooltip>
                        Перейти в режим обслуживания
                    </Tooltip>
                }
            >
                <Nav.Item icon={<Tools style={{marginRight: '0'}}/>}/>
            </Whisper>
        );
    }

}

export default MaintanceModeButton;