import React from 'react';
import { IconButton, Modal, Form, Notification, Popover, Whisper } from 'rsuite';
import { format } from 'date-fns';

//import icons
import CheckIcon from '@rsuite/icons/Check';
import Save from '@rsuite/icons/legacy/Save';
import CloudDownload from '@rsuite/icons/legacy/CloudDownload';

//import controller functions

class MapOfClusterInfoModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="md">
                <Modal.Title>Организация: {this.props.data.name}</Modal.Title>
                <Modal.Body>
                    <Form fluid layout="horizontal">
                        <Form.Group controlId="organisationName" className="flex_modal_form">
                            <Form.ControlLabel>Сокращенное наименование:</Form.ControlLabel>
                            <Form.Control name="organisationName" value={this.props.data.name}/>
                        </Form.Group>
                        <Form.Group controlId="organisationFullName" className="flex_modal_form">
                            <Form.ControlLabel>Полное наименование:</Form.ControlLabel>
                            <Form.Control name="organisationFullName" value={this.props.data.fullName}/>
                        </Form.Group>
                        <Form.Group controlId="register_date" className="flex_modal_form" disable={true}>
                            <Form.ControlLabel>Дата регистрации:</Form.ControlLabel>
                            <Form.Control name="register_date" value={format(new Date(this.props.data.register_date), 'yyyy-MM-dd')}/>
                        </Form.Group>
                        <Form.Group controlId="legalAddress" className="flex_modal_form">
                            <Form.ControlLabel>Юридический адрес:</Form.ControlLabel>
                            <Form.Control name="legalAddress" value={this.props.data.legalAddress}/>
                        </Form.Group>
                        <Form.Group controlId="mailingAddress" className="flex_modal_form">
                            <Form.ControlLabel>Почтовый адрес:</Form.ControlLabel>
                            <Form.Control name="mailingAddress" value={this.props.data.mailingAddress}/>
                        </Form.Group>
                        <Form.Group controlId="phone" className="flex_modal_form">
                            <Form.ControlLabel>Телефон:</Form.ControlLabel>
                            <Form.Control name="phone" value={this.props.data.phone}/>
                        </Form.Group>
                        <Form.Group controlId="email" className="flex_modal_form">
                            <Form.ControlLabel>Email:</Form.ControlLabel>
                            <Form.Control name="email" value={this.props.data.email}/>
                        </Form.Group>
                        <Form.Group controlId="website" className="flex_modal_form">
                            <Form.ControlLabel>Сайт:</Form.ControlLabel>
                            <Form.Control name="website" value={this.props.data.website}/>
                        </Form.Group>
                        <Form.Group controlId="employee_count" className="flex_modal_form">
                            <Form.ControlLabel>Количество работников:</Form.ControlLabel>
                            <Form.Control name="employee_count" value={this.props.data.employee_count}/>
                        </Form.Group>
                        <Form.Group controlId="inn" className="flex_modal_form">
                            <Form.ControlLabel>ИНН:</Form.ControlLabel>
                            <Form.Control name="inn" value={this.props.data.inn} disable={true}/>
                        </Form.Group>
                        <Form.Group controlId="kpp" className="flex_modal_form">
                            <Form.ControlLabel>КПП:</Form.ControlLabel>
                            <Form.Control name="kpp" value={this.props.data.kpp} disable={true}/>
                        </Form.Group>
                        <Form.Group controlId="okpo" className="flex_modal_form">
                            <Form.ControlLabel>ОКПО:</Form.ControlLabel>
                            <Form.Control name="okpo" value={this.props.data.okpo}/>
                        </Form.Group>
                        <Form.Group controlId="oktmo" className="flex_modal_form">
                            <Form.ControlLabel>ОКТМО:</Form.ControlLabel>
                            <Form.Control name="oktmo" value={this.props.data.oktmo}/>
                        </Form.Group>
                        <Form.Group controlId="ogrn" className="flex_modal_form">
                            <Form.ControlLabel>ОГРН:</Form.ControlLabel>
                            <Form.Control name="ogrn" value={this.props.data.ogrn}/>
                        </Form.Group>
                        <Form.Group controlId="okved" className="flex_modal_form">
                            <Form.ControlLabel>ОКВЭД:</Form.ControlLabel>
                            <Form.Control name="okved" value={this.props.data.okved} disable={true}/>
                        </Form.Group>
                        <Form.Group controlId="bank_name" className="flex_modal_form">
                            <Form.ControlLabel>Банк:</Form.ControlLabel>
                            <Form.Control name="bank_name" value={this.props.data.bank_name}/>
                        </Form.Group>
                        <Form.Group controlId="bank_bik" className="flex_modal_form">
                            <Form.ControlLabel>БИК Банка:</Form.ControlLabel>
                            <Form.Control name="bank_bik" value={this.props.data.bank_bik}/>
                        </Form.Group>
                        <Form.Group controlId="bank_checking_account" className="flex_modal_form">
                            <Form.ControlLabel>Основной счет:</Form.ControlLabel>
                            <Form.Control name="bank_checking_account" value={this.props.data.bank_checking_account}/>
                        </Form.Group>
                        <Form.Group controlId="bank_correspondent_account" className="flex_modal_form">
                            <Form.ControlLabel>Корреспондентский счет:</Form.ControlLabel>
                            <Form.Control name="bank_correspondent_account" value={this.props.data.bank_correspondent_account}/>
                        </Form.Group>
                        <Form.Group controlId="owner" className="flex_modal_form">
                            <Form.ControlLabel>Директор:</Form.ControlLabel>
                            <Form.Control name="owner" value={this.props.data.owner}/>
                        </Form.Group>
                        <Form.Group controlId="buh" className="flex_modal_form">
                            <Form.ControlLabel>Главный бухгалтер:</Form.ControlLabel>
                            <Form.Control name="buh" value={this.props.data.buh}/>
                        </Form.Group>
                        <Form.Group controlId="max_balance" className="flex_modal_form">
                            <Form.ControlLabel>Максимальный баланс:</Form.ControlLabel>
                            <Form.Control name="max_balance" value={this.props.data.max_balance}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CloudDownload/>}>Заполнить</IconButton>
                    <IconButton icon={<Save/>}>Сохранить</IconButton>
                    <IconButton icon={<CheckIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default MapOfClusterInfoModal;