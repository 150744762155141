import React from 'react';
import { IconButton, Modal } from 'rsuite';
import { isEmpty } from 'lodash';

//import icons
import CheckIcon from '@rsuite/icons/Check';

class StatusModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const header = this.props.header;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="lg">
                <Modal.Title>{header}</Modal.Title>
                <Modal.Body>

                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CheckIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default StatusModal;