import React from 'react';
import { IconButton, Modal, Table, Pagination } from 'rsuite';

//import icons
import CheckIcon from '@rsuite/icons/Check';

const { Column, HeaderCell, Cell } = Table;

class MapOfClusterInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            limit: 50,
            page: 1
        };
    }

    handleChangeLimit = dataKey => {
        this.setState({page: 1});
        this.setState({limit: dataKey});
        return;
    };

    handleChangePage = async(value) => {
        this.setState({ page: value });
        return;
    };

    render() {

        const okveds = this.props.data;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const data = okveds.filter((v, i) => {
            const start = this.state.limit * (this.state.page - 1);
            const end = start + this.state.limit;
            return i >= start && i < end;
        });

        const MainCell = ({ rowData, dataKey, ...props }) => (
            <Cell {...props} style={{ padding: 0 }}>
                {
                    rowData.main === true ?
                    <span>Да</span> :
                    <span>Нет</span>
                }
            </Cell>
        );

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="lg">
                <Modal.Title>Список ОКВЭД</Modal.Title>
                <Modal.Body>
                    <Table data={data} autoHeight={true} rowHeight={64} wordWrap="break-word" style={{margin: "15px auto 0 auto"}}>
                        <Column width={90} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Код</HeaderCell>
                            <Cell dataKey="code" />
                        </Column>
                        <Column width={70} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Основной</HeaderCell>
                            <MainCell dataKey="main" />
                        </Column>
                        <Column width={120} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Тип</HeaderCell>
                            <Cell dataKey="type" />
                        </Column>
                        <Column flexGrow={1} align="center" verticalAlign="middle" fixed>
                            <HeaderCell>Описание</HeaderCell>
                            <Cell dataKey="name" />
                        </Column>
                    </Table>
                    <div style={{ padding: 20 }}>
                        <Pagination prev next first last ellipsis boundaryLinks maxButtons={5} size="xs" layout={['total', '-', 'limit', '|', 'pager']} total={okveds.length} limitOptions={[10, 30, 50]} limit={this.state.limit} activePage={this.state.page} onChangePage={this.handleChangePage} onChangeLimit={this.handleChangeLimit} style={{maxWidth: "auto", margin: "0 auto"}}/>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CheckIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default MapOfClusterInfoModal;