import React from 'react';
import { Container, Header, FlexboxGrid, Panel, Stack, DateRangePicker, SelectPicker, IconButton, Notification } from 'rsuite';
import Chart from 'react-apexcharts'
import { isEmpty, isNull } from "lodash";
import { format } from 'date-fns';

//import icons
import { MdOutlineSpaceDashboard } from 'react-icons/md';
import ReloadIcon from '@rsuite/icons/Reload';

//import common
import predifinedVars from './Common/predifinedVars';

//import controllers
import { getClusterList, getAllInfobasesByClusterIdFromDatabase, getOrganisations } from '../controllers/clusterController';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                finance: {
                    chart: {
                        id: 'apexchart-finance',
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    xaxis: {
                        categories: ["01.22", "02.22", "03.22", "04.22", "05.22", "06.22", "07.22", "08.22", "09.22", "10.22", "11.22", "12.22"]
                    }
                },
                debt: {
                    chart: {
                        id: 'apexchart-debt',
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    stroke: {
                        curve: 'straight'
                    },
                    xaxis: {
                        categories: ["01.22", "02.22", "03.22", "04.22", "05.22", "06.22", "07.22", "08.22", "09.22", "10.22", "11.22", "12.22"]
                    }
                },
                analyse: {
                    chart: {
                        id: 'apexchart-analyse',
                        zoom: {
                            enabled: false
                        }
                    },
                    plotOptions: {
                        bar: {
                          horizontal: false,
                          columnWidth: '55%',
                          endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2
                    },
                    xaxis: {
                        categories: ["01.22", "02.22", "03.22", "04.22", "05.22", "06.22", "07.22", "08.22", "09.22", "10.22", "11.22", "12.22"]
                    }
                },
                osv: {
                    chart: {
                        id: 'apexchart-osv',
                        zoom: {
                            enabled: false
                        }
                    },
                    plotOptions: {
                        bar: {
                          horizontal: false,
                          columnWidth: '55%',
                          endingShape: 'rounded'
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2
                    },
                    xaxis: {
                        categories: ["01.22", "02.22", "03.22", "04.22", "05.22", "06.22", "07.22", "08.22", "09.22", "10.22", "11.22", "12.22"]
                    }
                },
            },
            series: {
                finance: [
                    {
                        name: 'Продажи',
                        data: [450987, 532711, 1752124, 115712, 321571, 613271, 100145, 500000, 27000, 231578, 114789, 1054078]
                    },
                    {
                        name: 'Закупки',
                        data: [124310, 12711, 23001, 561210, 150332, 47800, 98100, 157200, 10000, 67170, 97600, 57810]
                    }
                ],
                debt: [
                    {
                        name: 'Дебиторская',
                        data: [140000, 53897, 23513, 11154, 0, 0, 0, 637211, 415167, 201647, 53011, 0]
                    },
                    {
                        name: 'Кредиторская',
                        data: [15891, 29167, 11120, 0, 0, 9514, 67104, 129034, 217145, 0, 53011, 167412]
                    }
                ],
                analyse: [
                    {
                        name: 'Поступление',
                        data: [140000, 53897, 23513, 11154, 0, 0, 0, 637211, 415167, 201647, 53011, 0]
                    },
                    {
                        name: 'Списание',
                        data: [15891, 29167, 11120, 0, 0, 9514, 67104, 129034, 217145, 0, 53011, 167412]
                    }
                ],
                osv: [
                    {
                        name: 'Поступление',
                        data: [42425, 46545, 454545, 247775, 0, 442, 0, 12576, 224447, 201647, 334789, 0]
                    },
                    {
                        name: 'Списание',
                        data: [124789, 547898, 11120, 0, 0, 78987, 67104, 129034, 217145, 0, 243478, 167412]
                    }
                ],
            },
            clusterId: null,
            clusterList: [],
            periodRange: null,
            infobasesList: [],
            infobaseId: null,
            organisationsList: []
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobasesByClusterId = this.getInfobasesByClusterId.bind(this);
        this.getOrganisationsList = this.getOrganisationsList.bind(this);
        this.convertDate = this.convertDate.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getInfobasesByClusterId = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ clusterId: value });
            
            let infobases = await getAllInfobasesByClusterIdFromDatabase(value);

            this.setState({
                infobasesList: infobases.data,
            });

        }catch(e){
            
            this.setState({
                infobasesList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getOrganisationsList = async(value) => {

        const toaster = this.props.toaster;

        try{

            this.setState({ infobaseName: value, organisationId: null });
            
            let organisations = await getOrganisations(value);

            this.setState({
                organisationsList: organisations.data,
            });

        }catch(e){
            
            this.setState({
                organisationsList: [],
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Организации отсутствуют или не синхронизированы!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobasesByClusterId(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }

    render() {

        let clusters = this.state.clusterList;
        let infobasesList = this.state.infobasesList;
        let organisationsList = this.state.organisationsList;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        const infobases = infobasesList.map(
            item => ({ label: item.name + " [" + item.infobaseExternalId + "]", value: item.name })
        );

        const organisations = organisationsList.map(
            item => ({ label: item.name, value: item.organisationId })
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><MdOutlineSpaceDashboard/></i> Статистика</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" value={this.convertDate(this.state.periodRange)} style={{ width: 200 }} />
                                        <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Кластер' onChange={(value) => this.getInfobasesByClusterId(value)}/>
                                        <SelectPicker data={infobases} style={{ width: 200 }} cleanable={false} searchable={false} disabled={!isEmpty(infobasesList) ? false : true} placeholder='Информационная база' onChange={(value) => this.getOrganisationsList(value)}/>
                                        <SelectPicker data={organisations} style={{ width: 250 }} cleanable={true} searchable={true} disabled={!isEmpty(organisationsList) ? false : true} placeholder='Организация'/>
                                        <IconButton icon={<ReloadIcon />}>Обновить</IconButton>
                                    </Stack>
                                </Panel>
                                <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Обороты" bordered style={{margin: "10px"}}>
                                            <Chart options={this.state.options.finance} series={this.state.series.finance} width={"100%"} height={"300px"} />
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Задолженность" bordered style={{margin: "10px"}}>
                                            <Chart options={this.state.options.debt} series={this.state.series.debt} width={"100%"} height={"300px"} />
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Анализ движения денежных средств" bordered style={{margin: "10px"}}>
                                            <Chart options={this.state.options.analyse} series={this.state.series.analyse} type='bar' width={"100%"} height={"300px"} />
                                        </Panel>
                                    </FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={12}>
                                        <Panel header="Оборотно-сальдовая ведомость" bordered style={{margin: "10px"}}>
                                            <Chart options={this.state.options.osv} series={this.state.series.osv} type='bar' width={"100%"} height={"300px"} />
                                        </Panel>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default Dashboard;