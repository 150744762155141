import React from 'react';
import { IconButton, Modal, FlexboxGrid, Form, SelectPicker, InputNumber, Notification } from 'rsuite';

//import icons
import CheckIcon from '@rsuite/icons/Check';
import Save from '@rsuite/icons/legacy/Save';

//import controllers
import { addNewProxy } from '../../../controllers/systemController';

const PROTOCOLS = ["HTTP", "HTTPS", "Socks4", "Socks5"];

class ProxyAddModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: null,
            password: null,
            protocol: null,
            port: 80,
            address: null,
            name: null
        };
        this.addProxy = this.addProxy.bind(this);
    }

    addProxy = async() => {

        const toaster = this.props.toaster;
        
        try{
           
            let request = await addNewProxy(this.state.username, this.state.password, this.state.protocol, parseInt(this.state.port, 10), this.state.address, this.state.name);

            toaster.push(<Notification showIcon header={'Информация'} type={'success'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

            this.props.onClose();

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleSaveClick = () => {
            this.addProxy();
        };

        const protocols_data = PROTOCOLS.map(
            item => ({ label: item, value: item })
        );

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="lg">
                <div>
                    <Modal.Title>Добавление Proxy</Modal.Title>
                    <Modal.Body>
                        <FlexboxGrid justify="center" style={{alignContent: "stretch", alignItems: "stretch"}}>
                            <FlexboxGrid.Item colspan={12}>
                                <div className='middle-flex-large'>
                                    <Form fluid>
                                        <Form.Group controlId="name">
                                            <Form.ControlLabel>Наименование:</Form.ControlLabel>
                                            <Form.Control name="name" onBlur={(event) => this.setState({ name: event.target.value })}/>
                                        </Form.Group>
                                        <Form.Group controlId="address">
                                            <Form.ControlLabel>Ip:</Form.ControlLabel>
                                            <Form.Control name="address" onBlur={(event) => this.setState({ address: event.target.value })} placeholder="123.123.123.123"/>
                                        </Form.Group>
                                        <Form.Group controlId="port">
                                            <Form.ControlLabel>Порт:</Form.ControlLabel>
                                            <Form.Control name="port" accepter={InputNumber} defaultValue={80} max={65535} min={80} step={1} scrollable={true} onChange={(value) => this.setState({ port: value })}/>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={12}>
                                <div className='middle-flex-large'>
                                    <Form fluid>
                                        <Form.Group controlId="protocol">
                                            <Form.ControlLabel>Протокол:</Form.ControlLabel>
                                            <Form.Control name="protocol" accepter={SelectPicker} data={protocols_data} style={{width: "100%"}} cleanable={false} searchable={false} placeholder='Протокол' onChange={(value) => this.setState({ protocol: value})}/>
                                        </Form.Group>
                                        <Form.Group controlId="username">
                                            <Form.ControlLabel>Пользователь:</Form.ControlLabel>
                                            <Form.Control name="username" onBlur={(event) => this.setState({ username: event.target.value.replaceAll(' ', '') })} placeholder="user"/>
                                        </Form.Group>
                                        <Form.Group controlId="password">
                                            <Form.ControlLabel>Пароль:</Form.ControlLabel>
                                            <Form.Control name="password" type="password" onBlur={(event) => this.setState({ password: event.target.value })} placeholder="password"/>
                                        </Form.Group>
                                    </Form>
                                </div>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Modal.Body>
                    <Modal.Footer>
                        <IconButton icon={<Save />} onClick={handleSaveClick}>Сохранить</IconButton>
                        <IconButton icon={<CheckIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                    </Modal.Footer>
                </div>
            </Modal> 
        );
    };
}

export default ProxyAddModal;