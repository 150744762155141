import React from 'react';
import { Container, Header, Panel, FlexboxGrid, ButtonToolbar, ButtonGroup, IconButton, Dropdown, Checkbox, Stack, DateRangePicker, Popover, Whisper, SelectPicker, Notification } from 'rsuite';
import { Tree, TreeNode } from 'react-organizational-chart';
import { isEmpty, isNull, isUndefined } from "lodash";
import { format } from 'date-fns';

//import icons
import { FaSync } from 'react-icons/fa';
import GearIcon from '@rsuite/icons/Gear';
import TrashIcon from '@rsuite/icons/Trash';
import ConversionIcon from '@rsuite/icons/Conversion';
import SearchIcon from '@rsuite/icons/Search';
import MoreIcon from '@rsuite/icons/More';
import ImportIcon from '@rsuite/icons/Import';
import ExportIcon from '@rsuite/icons/Export';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import InfoOutlineIcon from '@rsuite/icons/InfoOutline';

//components;
import InfoModal from '../Common/Info';
import ExportExchangePlanModal from './Modal/ExportExchangePlanModal';
import SyncParametersModal from './Modal/SyncParametersModal';
import ImportExchangePlanModal from './Modal/ImportExchangePlanModal';

//import common
import predifinedVars from '../Common/predifinedVars';

//controllers
import { getClusterList, getSyncTree } from '../../controllers/clusterController';

const treeCheckbox={
    position:'absolute',
    top:'7px',
    left:'7px',
}

class Sync extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            setSyncOpen: false,
            setExportOpen: false,
            setImportOpen: false,
            setInfoOpen: false,
            data: {},
            clusterList: [],
            infobaseList: [],
            periodRange: null,
            clusterId: null,
        };
        this.getClusters = this.getClusters.bind(this);
        this.getInfobaseList = this.getInfobaseList.bind(this);
        this.setDefaults = this.setDefaults.bind(this);
        this.convertDate = this.convertDate.bind(this);
    }

    getClusters = async() => {

        const toaster = this.props.toaster;

        try{

            let clusters = await getClusterList();

            this.setState({
                clusterList: clusters.data,
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Кластеры не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    getInfobaseList = async(value) => {
        const toaster = this.props.toaster;

        try{
            if(!isEmpty(value)){

                this.setState({ clusterId: value });

                const allInfobases = await getSyncTree(value);

                this.setState({
                    infobaseList: allInfobases.data,
                });

            }
        }catch(e){

            this.setState({
                infobaseList: []
            });

            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Информационные базы не найдены!</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    convertDate = (value) => {
        if(!isEmpty(value)){

            let period = [];

            for(const date of value){
                let d = new Date(date);
                let month = '' + (d.getMonth() + 1);
                let day = '' + d.getDate();
                let year = d.getFullYear();
    
                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;
    
                let correctDate = [year, month, day].join('-');

                period.push(correctDate);
            }

            return [new Date(period[0]), new Date(period[1])];
        }
    }

    setDefaults = async() => {
        let parameters = this.props.parameters;
        let user = this.props.user;
        if(!isEmpty(parameters.defaultCluster)){
            await this.getInfobaseList(parameters.defaultCluster);
        }
        if(!isNull(user.params.defaultDateStart) && !isNull(user.params.defaultDateEnd)){
            let start_date = format(new Date(user.params.defaultDateStart), 'yyyy-MM-dd') + 'T00:00:00';
            let end_date = format(new Date(user.params.defaultDateEnd), 'yyyy-MM-dd') + 'T23:59:59';
            
            this.setState({periodRange: [start_date, end_date]});
        }
    }

    componentDidMount() {
        this.getClusters().then(() => {
            this.setDefaults();
        });
    }
    

    render() {

        const setInfoOpen = this.state.setInfoOpen;
        const setSyncOpen = this.state.setSyncOpen;
        const setExportOpen = this.state.setExportOpen;
        const setImportOpen = this.state.setImportOpen;

        let clusters = this.state.clusterList;
        let infobases = this.state.infobaseList;
        
        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );
       
        const SyncTree = () => {
            let clusterObj = infobases.filter((item) => {
                return item.type === "cluster"
            });

            let mapOfInfobasesObj = infobases.filter((item) => {
                return item.type === "infobase"
            });

            let info_map = mapOfInfobasesObj.map((item) => {
                let infobases_level_one = item;
                if(!isUndefined(infobases_level_one.childs) && !isNull(infobases_level_one.childs)){
                    let infobases_level_one_childs = infobases_level_one.childs;
                    let infobases_level_one_childs_map = infobases_level_one_childs.map((item) => {
                        let infobases_level_two = item;
                        if(!isUndefined(infobases_level_two.childs) && !isNull(infobases_level_two.childs)){
                            let infobases_level_two_childs = infobases_level_two.childs;
                            let infobases_level_two_childs_map = infobases_level_two_childs.map((item) => {
                                return(
                                    <TreeNode label={<div className='treeChildLow' ><IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: item.name + " [" + item.infobaseExternalId + "]", clusterId: item.cluster, objectId: item.infobaseExternalId, object: "infobase" }})} classPrefix={'btn-icon btn-tree'} />{item.name}<Checkbox style={treeCheckbox}/></div>}/>
                                )
                            })

                            return(
                                <TreeNode label={<div className='treeChildMedium' ><IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: item.name + " [" + item.infobaseExternalId + "]", clusterId: item.cluster, objectId: item.infobaseExternalId, object: "infobase" }})} classPrefix={'btn-icon btn-tree'} />{item.name}<Checkbox style={treeCheckbox}/></div>}>
                                    {infobases_level_two_childs_map}
                                </TreeNode>
                            )

                        }else{
                            return(
                                <TreeNode label={<div className='treeChildMedium' ><IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: item.name + " [" + item.infobaseExternalId + "]", clusterId: item.cluster, objectId: item.infobaseExternalId, object: "infobase" }})} classPrefix={'btn-icon btn-tree'} />{item.name}<Checkbox style={treeCheckbox}/></div>}/>
                            )
                        }
                    })

                    return(
                        <TreeNode label={<div className='treeChildHigh' ><IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: item.name + " [" + item.infobaseExternalId + "]", clusterId: item.cluster, objectId: item.infobaseExternalId, object: "infobase" }})} classPrefix={'btn-icon btn-tree'} />{item.name}<Checkbox style={treeCheckbox}/></div>}>
                            {infobases_level_one_childs_map}
                        </TreeNode>
                    )

                }else{

                    return(
                        <TreeNode label={<div className='treeChildHigh' ><IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: item.name + " [" + item.infobaseExternalId + "]", clusterId: item.cluster, objectId: item.infobaseExternalId, object: "infobase" }})} classPrefix={'btn-icon btn-tree'} />{item.name}<Checkbox style={treeCheckbox}/></div>}/>
                    )

                }
            });

            return (
                <Tree label={<div className='treeRoot'><IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: clusterObj[0].name + " [" + clusterObj[0].cluster + "]", objectId: clusterObj[0].cluster, object: "cluster" }})} classPrefix={'btn-icon btn-tree'} />{clusterObj[0].name}<Checkbox style={treeCheckbox}/></div>}>
                    {info_map}
                </Tree>
            )
 
        };

        const DisplayNotAssignedWithLevelInfobases = () => {

            let nullInfobasesObj = infobases.filter((item) => {
                return item.type === "infobase_null"
            });

            if (!isEmpty(nullInfobasesObj)) {
                let ib_null = nullInfobasesObj[0].object;
                return(
                    <Panel header="Информационные базы без уровня" bordered style={{margin: "10px"}}>
                        <div className='treeArea'>
                            {
                                ib_null.map((infobase) => {
                                    return (
                                        <div className='treeNone'>
                                            <IconButton icon={<InfoOutlineIcon />} onClick={() => this.setState({setInfoOpen: true, data: {header: infobase.name + " [" + infobase.infobaseExternalId + "]", clusterId: infobase.cluster, objectId: infobase.infobaseExternalId, object: "infobase" }})} />
                                            {infobase.name}
                                            <Checkbox style={treeCheckbox}/>
                                        </div>
                                    )         
                                })
                            }
                        </div>
                    </Panel>
                )
            }
        }

        const renderMenuMore = ({ onClose, left, top, className }, ref) => {
            const handleSelect = (eventKey, event) => {

                if(eventKey == 1){
                    this.setState({ setExportOpen: true })
                }else if(eventKey == 2){
                    this.setState({ setImportOpen: true })
                }else if(eventKey == 3){

                };

                onClose();
            };
            return (
              <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu onSelect={handleSelect}>
                    <Dropdown.Item eventKey={1} icon={<ExportIcon />}>Выгрузить план обмена</Dropdown.Item>
                    <Dropdown.Item eventKey={2} icon={<ImportIcon />}>Загрузить план обмена</Dropdown.Item>
                    <Dropdown.Item eventKey={3} icon={<SingleSourceIcon />} >Создать резервную копию</Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            );
        };

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><FaSync/></i> Синхронизация</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={24}>
                            <div className='middle-flex-large'>
                                <Panel bordered style={{margin: "10px"}}>
                                    <Stack wrap spacing={10}>
                                        <SelectPicker data={cluster} value={this.state.clusterId} style={{ width: 200 }} cleanable={false} searchable={false} placeholder='Кластер' onChange={(value) => this.getInfobaseList(value)}/>
                                        <DateRangePicker placeholder="Выберите период" ranges={predifinedVars.predefinedRangesDatePicker} format="yyyy-MM-dd" value={this.convertDate(this.state.periodRange)} style={{ width: 300 }} />
                                        <ButtonToolbar>
                                            <IconButton icon={<ConversionIcon />}>Синхронизация</IconButton>
                                            <IconButton icon={<GearIcon />} onClick={() => this.setState({ setSyncOpen: true })}>Параметры синхронизации</IconButton>
                                            <ButtonGroup>
                                                <IconButton icon={<SearchIcon />} />
                                                <IconButton icon={<TrashIcon />} />
                                            </ButtonGroup>
                                            <Whisper placement="bottom" trigger="click" speaker={renderMenuMore}>
                                                <IconButton icon={<MoreIcon />}>Еще</IconButton>
                                            </Whisper>
                                        </ButtonToolbar>
                                    </Stack>
                                </Panel>
                                <FlexboxGrid justify="center">
                                    <FlexboxGrid.Item colspan={24}>
                                        <Panel bordered style={{margin: "10px"}}>
                                            <div className='treeArea'>
                                                {!isEmpty(infobases) ?
                                                    <SyncTree /> :
                                                    <span>Данные отсутствуют. Выберите сервер.</span>
                                                }
                                            </div>
                                        </Panel>
                                        <DisplayNotAssignedWithLevelInfobases/>
                                    </FlexboxGrid.Item>
                                </FlexboxGrid>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
                {setInfoOpen && <InfoModal open={this.state.setInfoOpen} onClose={() => this.setState({ setInfoOpen: false })} data={this.state.data} toaster={this.props.toaster}/>}
                {setSyncOpen && <SyncParametersModal open={this.state.setSyncOpen} onClose={() => this.setState({ setSyncOpen: false })} toaster={this.props.toaster}/>}
                {setExportOpen && <ExportExchangePlanModal open={this.state.setExportOpen} onClose={() => this.setState({ setExportOpen: false })} toaster={this.props.toaster}/>}
                {setImportOpen && <ImportExchangePlanModal open={this.state.setImportOpen} onClose={() => this.setState({ setImportOpen: false })} toaster={this.props.toaster}/>}
            </main>    
        );
    };
}

export default Sync;