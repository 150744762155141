import React from 'react';
import { IconButton, Modal, Notification, Popover, Whisper, Dropdown, Loader } from 'rsuite';

import config from '../../config';

//import icons
import CheckIcon from '@rsuite/icons/Check';
import SignIn from '@rsuite/icons/legacy/SignIn';
import FileUploadIcon from '@rsuite/icons/FileUpload';
import MoreIcon from '@rsuite/icons/More';
import TagNumberIcon from '@rsuite/icons/TagNumber';
import TrashIcon from '@rsuite/icons/Trash';
import BarLineChartIcon from '@rsuite/icons/BarLineChart';
import ToolsIcon from '@rsuite/icons/Tools';
import Share from '@rsuite/icons/legacy/Share';
import StopCircle from '@rsuite/icons/legacy/StopCircle';
import ResizeIcon from '@rsuite/icons/Resize';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import SingleSourceIcon from '@rsuite/icons/SingleSource';
import Lock from '@rsuite/icons/legacy/Lock';
import CopyIcon from '@rsuite/icons/Copy';

//import components
import InfobaseCredentialsChange from './InfobaseCredentialsChange';

//import controller functions
import { getClusterInfo, getInfobaseInfo } from '../../controllers/clusterController';
import { InfoGrid } from './InfoGrid';
import { isEmpty } from 'lodash';

class InfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            loading: false,
            credentials_set: false,
            caption: null
        };
        this.getInfo = this.getInfo.bind(this);
    }

    setLoading = async() => {
        this.setState({
            loading: true
        });
    }

    getInfo = async(data) => {

        const toaster = this.props.toaster;

        try {

            this.setLoading();

            let object = data.object;
            let info;
            if(object === 'cluster'){
                info = await getClusterInfo(data.objectId);
            }else if(object === 'infobase'){
                info = await getInfobaseInfo(data.clusterId, data.objectId);
            }

            this.setState({
                info: info.data,
                loading: false
            })

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.data}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }
    }

    componentDidMount() {
        this.getInfo(this.props.data);
    }

    render() {

        const credentials_set = this.state.credentials_set;

        let info = this.state.info;
        let object_class = this.props.data.object;

        const info_items = info.map(item => <InfoGrid data={item}/>);

        const not_empty = info.map(item => (
            item.not_empty
        ));

        const publish_init = info.map(item => (
            item.publish_init
        ));

        const odata_init = info.map(item => (
            item.odata_init
        ));

        const name = info.map(item => (
            item.name
        ));

        let block_rule_one = `${not_empty}`;
        let block_rule_two = `${publish_init}`;
        let block_rule_three = `${odata_init}`;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const renderMenuInfobaseUnit = ({ onClose, left, top, className }, ref) => {
            const handleSelect = (eventKey, event) => {
                if(eventKey == 1){

                }else if(eventKey == 2){

                }else if(eventKey == 3){

                }else if(eventKey == 4){

                }else if(eventKey == 5){

                }else if(eventKey == 6){
                    this.setState({credentials_set: true, caption: `Изменение прав доступа для ${this.props.data.header}`})
                }else if(eventKey == 7){

                }else if(eventKey == 8){

                }else if(eventKey == 9){

                }else if(eventKey == 10){

                }else if(eventKey == 11){

                };

                onClose();
            };
            return (
              <Popover ref={ref} className={className} style={{ left, top }} full>
                <Dropdown.Menu onSelect={handleSelect}>
                    {!isEmpty(info_items) && <Dropdown.Item eventKey={1} icon={<FileUploadIcon />}>Загрузить базу данных</Dropdown.Item>}   
                    {(!isEmpty(info_items) && block_rule_two === "false") && <Dropdown.Item eventKey={2} icon={<Share />}>Опубликовать</Dropdown.Item>}
                    {(!isEmpty(info_items) && block_rule_two === "true") && <Dropdown.Item eventKey={3} icon={<StopCircle />}>Снять с публикации</Dropdown.Item>}
                    {(!isEmpty(info_items) && block_rule_three ==="false") && <Dropdown.Item eventKey={4} icon={<CheckOutlineIcon />}>Активировать OData</Dropdown.Item>}
                    {!isEmpty(info_items) && <Dropdown.Item divider />}
                    <Dropdown.Item eventKey={5} icon={<TagNumberIcon />}>Изменить родителя</Dropdown.Item>
                    <Dropdown.Item eventKey={6} icon={<Lock />}>Изменить права доступа</Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item eventKey={7} icon={<ResizeIcon />}>Загрузить расширение...</Dropdown.Item>
                    <Dropdown.Item eventKey={8} icon={<ToolsIcon />}>Загрузить обработку...</Dropdown.Item>
                    <Dropdown.Item eventKey={9} icon={<BarLineChartIcon />}>Загрузить отчет...</Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item eventKey={10} icon={<SingleSourceIcon />} >Создать резервную копию</Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item eventKey={11} icon={<CopyIcon />} >Создать пустую копию</Dropdown.Item>
                    <Dropdown.Item divider />
                    <Dropdown.Item eventKey={12} icon={<TrashIcon />}>Удалить</Dropdown.Item>
                </Dropdown.Menu>
              </Popover>
            );
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="md">
                <Modal.Title>{this.props.data.header}</Modal.Title>
                <Modal.Body>
                    {this.state.loading ? (
                        <div style={{width: "100%", textAlign: 'center', padding: "15px 0"}}>
                            <Loader size="lg" content="Получение данных..." vertical />
                        </div>
                    ) : (
                        info_items
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {object_class === 'infobase' && 
                        <IconButton icon={<SignIn />} href={`https://ws.${config.hostname}/${name}/`} disabled={(block_rule_one === "false" && block_rule_two === "false") || this.state.loading === true ? true : false} >Вход</IconButton>
                    }
                    {object_class === 'infobase' && 
                        <Whisper placement="auto" trigger="click" speaker={renderMenuInfobaseUnit}>
                            <IconButton icon={<MoreIcon />}>Операции</IconButton>
                        </Whisper>
                    }
                    <IconButton icon={<CheckIcon />} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
                {credentials_set && <InfobaseCredentialsChange open={this.state.credentials_set} onClose={() => this.setState({ credentials_set: false })} infobaseExternalId={this.props.data.objectId} caption={this.state.caption} toaster={this.props.toaster}/>}
            </Modal> 
        );
    };
}

export default InfoModal;