import React from 'react';
import { Container, Header, FlexboxGrid, Form, Panel, Checkbox, InputGroup, SelectPicker, Uploader, IconButton, PanelGroup, Notification } from 'rsuite';
import { isEmpty, isNaN } from "lodash";

//import icons
import { GoGear } from 'react-icons/go';
import Lock from '@rsuite/icons/legacy/Lock';
import EmailIcon from '@rsuite/icons/Email';
import UserInfoIcon from '@rsuite/icons/UserInfo';
import Key from '@rsuite/icons/legacy/Key';
import Upload from '@rsuite/icons/legacy/Upload';
import StorageIcon from '@rsuite/icons/Storage';

//import controllers
import { getClusterList } from '../../controllers/clusterController';
import { getSystemParameters, updateSystemParameters } from '../../controllers/systemController';

class Parameters extends React.Component {
    constructor(props) {
        super(props);
        this.uploader = React.createRef();
        this.state = {
            licServer: "",
            notificationsTechEnable: false,
            notificationsTechEmail: '',
            maintanceMode: false,
            maintanceModeDelay: false,
            defaultCluster: '',
            updateLicenseKeys: [],
            itsLogin: '',
            itsPassword: '',
            logsStoreDays: 7,
            logsMaxSize: 8,
            logsArchive: false,
            clusterList: [],
            old_values: {}
        };
        this.getClusters = this.getClusters.bind(this);
        this.getSystemParameters = this.getSystemParameters.bind(this);
        this.updateSystemParams = this.updateSystemParams.bind(this);
        this.handleChangeLicServer = this.handleChangeLicServer.bind(this);
        this.handleChangeNotificationsTechEnable = this.handleChangeNotificationsTechEnable.bind(this);
        this.handleChangeNotificationsTechEmail = this.handleChangeNotificationsTechEmail.bind(this);
        this.handleChangeMaintanceMode = this.handleChangeMaintanceMode.bind(this);
        this.handleChangeMaintanceModeAwaitUsers = this.handleChangeMaintanceModeAwaitUsers.bind(this);
        this.handleChangeDefaultCluster = this.handleChangeDefaultCluster.bind(this);
        this.handleChangeUpdateITSLogin = this.handleChangeUpdateITSLogin.bind(this);
        this.handleChangeUpdateITSPassword = this.handleChangeUpdateITSPassword.bind(this);
        this.handleChangeJournalStorePeriod = this.handleChangeJournalStorePeriod.bind(this);
        this.handleChangeJournalStoreSize = this.handleChangeJournalStoreSize.bind(this);
        this.handleChangeJournalStoreArchive = this.handleChangeJournalStoreArchive.bind(this);
    }

    getClusters = async() => {

        let clusters = await getClusterList();

        this.setState({
            clusterList: clusters.data,
        });

    }

    updateSystemParams = async(params) => {

        const toaster = this.props.toaster;

        try{

            let fields = {
                params: params
            }

            let request = await updateSystemParameters(fields);
            toaster.push(<Notification showIcon header={request.success === true ? 'Информация' : 'Ошибка'} type={request.success === true ? 'success' : 'error'}>{request.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

            this.getSystemParameters();
        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

    }

    getSystemParameters = async() => {

        let parameters = await getSystemParameters();

        this.setState({
            licServer: parameters.data.licServer,
            notificationsTechEnable: parameters.data.notificationsTechEnable,
            notificationsTechEmail: parameters.data.notificationsTechEmail,
            maintanceMode: parameters.data.maintanceMode,
            maintanceModeDelay: parameters.data.maintanceModeAwaitUsers,
            defaultCluster: parameters.data.defaultCluster,
            itsLogin: parameters.data.updateITSLogin,
            itsPassword: parameters.data.updateITSPassword,
            logsStoreDays: parameters.data.journalStorePeriod,
            logsMaxSize: parameters.data.journalStoreSize,
            logsArchive: parameters.data.journalStoreArchive,
            old_values: {
                licServer: parameters.data.licServer,
                notificationsTechEnable: parameters.data.notificationsTechEnable,
                notificationsTechEmail: parameters.data.notificationsTechEmail,
                maintanceMode: parameters.data.maintanceMode,
                maintanceModeDelay: parameters.data.maintanceModeAwaitUsers,
                defaultCluster: parameters.data.defaultCluster,
                itsLogin: parameters.data.updateITSLogin,
                itsPassword: parameters.data.updateITSPassword,
                logsStoreDays: parameters.data.journalStorePeriod,
                logsMaxSize: parameters.data.journalStoreSize,
                logsArchive: parameters.data.journalStoreArchive,
            }
        });

    }

    componentDidMount() {
        this.getClusters();
        this.getSystemParameters();
    }

    handleUpdateUploadList = async(value) => {
        this.setState({ updateLicenseKeys: [...this.state.updateLicenseKeys, value] });
        return;
    }

    handleChangeNotificationsTechEnable = () => {
        this.setState({notificationsTechEnable: !this.state.notificationsTechEnable});
        let params = {
            notificationsTechEnable: !this.state.notificationsTechEnable
        }
        this.updateSystemParams(params);
    };

    handleChangeNotificationsTechEmail = () => {
        const toaster = this.props.toaster;

        if(this.state.notificationsTechEmail !== this.state.old_values.notificationsTechEmail){
            if(!isEmpty(this.state.notificationsTechEmail)){

                let mail_exp = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
                if(mail_exp.test(this.state.notificationsTechEmail) === false){
                    return (
                        toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите почту в правильном формате!</Notification>, {
                            duration: 10000,
                            placement: 'topEnd'
                        })
                    )
                }else{
                    let params = {
                        notificationsTechEmail: this.state.notificationsTechEmail
                    }
                    this.updateSystemParams(params);
                }
            }
        }
    };

    handleChangeMaintanceMode = () => {
        this.setState({maintanceMode: !this.state.maintanceMode});
        let params = {
            maintanceMode: !this.state.maintanceMode
        }
        this.updateSystemParams(params);
    };

    handleChangeMaintanceModeAwaitUsers = () => {
        this.setState({maintanceModeDelay: !this.state.maintanceModeDelay});
        let params = {
            maintanceModeAwaitUsers: !this.state.maintanceModeDelay
        }
        this.updateSystemParams(params);
    };

    handleChangeDefaultCluster = (value) => {
        this.setState({defaultCluster: value});

        if(this.state.defaultCluster !== this.state.old_values.defaultCluster){
            let params = {
                defaultCluster: value
            }

            this.updateSystemParams(params);
        }
    };

    handleChangeLicServer = (value) => {
        this.setState({licServer: value});

        if(this.state.licServer !== this.state.old_values.licServer){
            let params = {
                licServer: value
            }

            this.updateSystemParams(params);
        }
    };

    handleChangeUpdateITSLogin = () => {
        if(this.state.itsLogin !== this.state.old_values.itsLogin){
            if(!isEmpty(this.state.itsLogin)){
                let params = {
                    updateITSLogin: this.state.itsLogin
                }
                this.updateSystemParams(params);
            }
        }
    };

    handleChangeUpdateITSPassword = () => {
        if(this.state.itsPassword !== this.state.old_values.itsPassword){
            if(!isEmpty(this.state.itsPassword)){
                let params = {
                    updateITSPassword: this.state.itsPassword
                }
                this.updateSystemParams(params);
            }
        }
    };

    handleChangeJournalStorePeriod = () => {
        const toaster = this.props.toaster;
        
        if(this.state.logsStoreDays !== this.state.old_values.logsStoreDays){
            if(!isNaN(this.state.logsStoreDays)){
                if(!isEmpty(this.state.logsStoreDays)){
                    let params = {
                        journalStorePeriod: this.state.logsStoreDays
                    }
                    this.updateSystemParams(params);
                }
            }else{
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите параметр в числовом формате!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }
        }
    };

    handleChangeJournalStoreSize = () => {
        const toaster = this.props.toaster;
        if(this.state.logsMaxSize !== this.state.old_values.logsMaxSize){
            if(!isNaN(this.state.logsMaxSize)){
                if(!isEmpty(this.state.logsMaxSize)){
                    let params = {
                        journalStoreSize: this.state.logsMaxSize
                    }
                    this.updateSystemParams(params);
                }
            }else{
                return (
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите параметр в числовом формате!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }
        }
    };

    handleChangeJournalStoreArchive = () => {
        this.setState({logsArchive: !this.state.logsArchive});

        let params = {
            journalStoreArchive: !this.state.logsArchive
        }

        this.updateSystemParams(params);
    };

    render() {

        let clusters = this.state.clusterList;

        const cluster = clusters.map(
            item => ({ label: item.name + " [" + item.cluster + "]", value: item.cluster })
        );

        return (
            <main>
                <Container>
                    <Header>
                        <FlexboxGrid justify="center">
                            <FlexboxGrid.Item colspan={24}>
                                <h2 className='header'><i><GoGear/></i> Параметры</h2>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Header>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={12}>
                            <div className='middle-flex-large'>
                                <PanelGroup accordion defaultActiveKey={1} bordered style={{marginBottom: '15px'}}>
                                    <Panel header="Основное" eventKey={1} id="general">
                                        <Form fluid>
                                            <Form.Group controlId="selectDefaultCluster">
                                                <Form.ControlLabel>Основной сервер 1С:</Form.ControlLabel>
                                                <Form.Control name="selectDefaultCluster" accepter={SelectPicker} data={cluster} style={{ width: '100%' }} cleanable={false} onChange={(value) => this.handleChangeDefaultCluster(value)} value={this.state.defaultCluster} searchable={false} placeholder='Кластер' />
                                                <Form.HelpText>Выберите сервер 1С. Данный кластер серверов используется для текущей работы системы.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="licServer">
                                                <Form.ControlLabel>Сервер лицензий:</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><StorageIcon/></InputGroup.Addon>
                                                    <Form.Control name="licServer" onBlur={this.handleChangeLicServer} onChange={(value) => this.setState({licServer: value})} value={this.state.licServer}/>
                                                </InputGroup>
                                                <Form.HelpText>Укажите адрес сервера лицензирования 1С.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="notificationsTechEnableCheck">
                                                <Form.ControlLabel>Технические уведомления:</Form.ControlLabel>
                                                <Checkbox value="notificationsTechEnableCheck" onChange={this.handleChangeNotificationsTechEnable} checked={this.state.notificationsTechEnable}>Активно</Checkbox>
                                                <Form.HelpText tooltip>Когда установлен флажок, технические уведомления системы будут отправляться на указанную ниже почту.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="notificationsTechEmailAddress">
                                                <Form.ControlLabel>Почта для уведомлений:</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><EmailIcon/></InputGroup.Addon>
                                                    <Form.Control name="notificationsTechEmailAddress" onBlur={this.handleChangeNotificationsTechEmail} onChange={(value) => this.setState({notificationsTechEmail: value})} value={this.state.notificationsTechEmail} disabled={!this.state.notificationsTechEnable}/>
                                                </InputGroup>
                                                <Form.HelpText>Введите адрес почтового ящика для технический уведомлений.</Form.HelpText>
                                            </Form.Group>
                                        </Form>
                                    </Panel>
                                    <Panel header="Обновление" eventKey={2} id="oneCUpdate">
                                        <Form fluid>
                                            <Form.Group controlId="itsLogin">
                                                <Form.ControlLabel>Пользователь its.1c.ru:</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><UserInfoIcon/></InputGroup.Addon>
                                                    <Form.Control name="itsLogin" onBlur={this.handleChangeUpdateITSLogin} onChange={(value) => this.setState({itsLogin: value})} value={this.state.itsLogin}/>
                                                </InputGroup>
                                                <Form.HelpText>Введите имя пользователя сервиса its.1c.ru.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="itsPassword">
                                                <Form.ControlLabel>Пароль:</Form.ControlLabel>
                                                <InputGroup>
                                                    <InputGroup.Addon><Lock/></InputGroup.Addon>
                                                    <Form.Control name="itsPassword" type="password" onBlur={this.handleChangeUpdateITSPassword} onChange={(value) => this.setState({itsPassword: value})} value={this.state.itsPassword}/>
                                                </InputGroup>
                                                <Form.HelpText>Введите пароль пользователя сервиса its.1c.ru.</Form.HelpText>
                                            </Form.Group>
                                        </Form>
                                    </Panel>
                                    <Panel header="Лицензирование" eventKey={3} id="licensing">
                                        <Form fluid>
                                            <Form.Group controlId="licensingUpdate">
                                                <Form.ControlLabel>Обновление лицензий 1С:</Form.ControlLabel>
                                                <Uploader multiple autoUpload={false} ref={this.uploader} value={this.state.updateLicenseKeys} onChange={this.handleUpdateUploadList} listType="picture">
                                                    <button>
                                                    <Key />
                                                    </button>      
                                                </Uploader>
                                                <IconButton style={{width: '100%', margin: '7px 0'}} icon={<Upload/>} disabled={!this.state.updateLicenseKeys.length} onClick={() => { this.uploader.current.start();}}>Обновить</IconButton>
                                                <Form.HelpText><b>Предупреждение: после загрузки файлов они установятся в качестве основных ключей 1С.</b> Все файлы ключей имеют расширение json.</Form.HelpText>
                                            </Form.Group>
                                        </Form>
                                    </Panel>
                                    <Panel header="Режим обслуживания" eventKey={4} id="maintance">
                                        <Form fluid>
                                            <Form.Group controlId="maintanceModeCheck">
                                                <Form.ControlLabel>Режим обслуживания:</Form.ControlLabel>
                                                <Checkbox value="maintanceModeCheck" onChange={this.handleChangeMaintanceMode} checked={this.state.maintanceMode}>Активно</Checkbox>
                                                <Form.HelpText tooltip>Когда установлен флажок, сервер будет работать в режиме обслуживания.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="maintanceModeDelay">
                                                <Form.ControlLabel>Ожидание завершения работы пользователей:</Form.ControlLabel>
                                                <Checkbox value="maintanceModeDelay" onChange={this.handleChangeMaintanceModeAwaitUsers} checked={this.state.maintanceModeDelay}>Активно</Checkbox>
                                                <Form.HelpText tooltip>Ждать, когда пользователь завершит работу и выйдет из системы. В противном случае, сеанс пользователя будет принудительно завершен.</Form.HelpText>
                                            </Form.Group>
                                        </Form>
                                    </Panel>
                                    <Panel header="Журналирование" eventKey={5} id="logs">
                                        <Form fluid>
                                            <Form.Group controlId="logsExpired">
                                                <Form.ControlLabel>Хранение журналов (дней):</Form.ControlLabel>
                                                <Form.Control name="logsExpired" type="number" onBlur={this.handleChangeJournalStorePeriod} onChange={(value) => this.setState({logsStoreDays: value})} value={this.state.logsStoreDays}/>
                                                <Form.HelpText>Определяет, сколько дней будут храниться журналы сервисов.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="logsMaxSize">
                                                <Form.ControlLabel>Максимальный размер журнала (Mb):</Form.ControlLabel>
                                                <Form.Control name="logsMaxSize" type="number" onBlur={this.handleChangeJournalStoreSize} onChange={(value) => this.setState({logsMaxSize: value})} value={this.state.logsMaxSize}/>
                                                <Form.HelpText>Определяет максимальный размер файла журнала.</Form.HelpText>
                                            </Form.Group>
                                            <Form.Group controlId="logsArchiveCheck">
                                                <Form.ControlLabel>Архивировать журналы:</Form.ControlLabel>
                                                <Checkbox value="logsArchiveCheck" onChange={this.handleChangeJournalStoreArchive} checked={this.state.logsArchive}>Активно</Checkbox>
                                                <Form.HelpText>Включает возможность архивирования журналов в архивы *.tar.gz.</Form.HelpText>
                                            </Form.Group>
                                        </Form>
                                    </Panel>
                                </PanelGroup>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Container>
            </main>    
        );
    };
}

export default Parameters;