import React from 'react';
import { IconButton, Modal, FlexboxGrid, Progress, Loader } from 'rsuite';
import { isNull } from "lodash";

//import icons
import Close from '@rsuite/icons/legacy/Close';

class NewInfobaseProgress extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const error = this.props.error;

        const percent = this.props.percent;

        const status = percent === 100 ? 'success' : null;
        const color = percent === 100 ? '#52c41a' : '#3385ff';

        return (
            <Modal backdrop="static" role="alertdialog" open={this.props.open} onClose={handleCloseClick} size="md">
                <Modal.Header closeButton={false}>
                    <Modal.Title>Создание информационной базы {this.props.infobaseName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FlexboxGrid justify="center">
                        <FlexboxGrid.Item colspan={16}>
                            <span style={{display: "block"}}>
                                <b>Создание информационной базы...</b>
                                {!isNull(this.props.createIbResp) &&
                                    (this.props.createIbResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                }
                                {isNull(this.props.createIbResp) &&  <Loader />}
                            </span>
                            {!isNull(this.props.createIbResp) && 
                                (this.props.createIbResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.createIbResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.createIbResp.message}</b></span>
                                )
                            }
                            {(!isNull(this.props.createIbResp) && this.props.createIbResp.success === true && this.props.copyActive === true && this.props.skipDump === false) &&
                                <span style={{display: "block"}}>
                                    <b>Создание дампа информационной базы...</b>
                                    {!isNull(this.props.createDumpResp) &&
                                        (this.props.createDumpResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.createDumpResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.createDumpResp) && this.props.copyActive === true && this.props.skipDump === false) && 
                                (this.props.createDumpResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.createDumpResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.createDumpResp.message}</b></span>
                                )
                            }

                            {(!isNull(this.props.createIbResp) && this.props.copyActive === true && (!isNull(this.props.createDumpResp) || this.props.skipDump === true)) &&
                                <span style={{display: "block"}}>
                                    <b>Восстановление информационной базы из дампа...</b>
                                    {!isNull(this.props.restoreDumpResp) &&
                                        (this.props.restoreDumpResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.restoreDumpResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.restoreDumpResp) && this.props.copyActive === true) &&
                                (this.props.restoreDumpResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.restoreDumpResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.restoreDumpResp.message}</b></span>
                                )
                            }

                            {(!isNull(this.props.restoreDumpResp) && this.props.copyActive === true && this.props.restoreDumpResp.success === true) &&
                                <span style={{display: "block"}}>
                                    <b>Изменение поддержки конфигурации в информационной базе...</b>
                                    {!isNull(this.props.disableSupportResp) &&
                                        (this.props.disableSupportResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.disableSupportResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.disableSupportResp) && this.props.copyActive === true) &&
                                (this.props.disableSupportResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.disableSupportResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.disableSupportResp.message}</b></span>
                                )
                            }

                            {(!isNull(this.props.disableSupportResp) && this.props.copyActive === true && this.props.disableSupportResp.success === true) &&
                                <span style={{display: "block"}}>
                                    <b>Создание расширения в информационной базе...</b>
                                    {!isNull(this.props.createExt) &&
                                        (this.props.createExt.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.createExt) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.createExt) && this.props.copyActive === true) &&
                                (this.props.createExt.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.createExt.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.createExt.message}</b></span>
                                )
                            }

                            {(!isNull(this.props.createExt) && this.props.copyActive === true && this.props.createExt.success === true) &&
                                <span style={{display: "block"}}>
                                    <b>Загрузка расширения в информационную базу...</b>
                                    {!isNull(this.props.loadExtResp) &&
                                        (this.props.loadExtResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.loadExtResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.loadExtResp) && this.props.copyActive === true) &&
                                (this.props.loadExtResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.loadExtResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.loadExtResp.message}</b></span>
                                )
                            }
                            
                            {(!isNull(this.props.loadExtResp) && this.props.copyActive === true  && this.props.loadExtResp.success === true) &&
                                <span style={{display: "block"}}>
                                    <b>Применение изменений в информационной базе...</b>
                                    {!isNull(this.props.applyExtResp) &&
                                        (this.props.applyExtResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.applyExtResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.applyExtResp) && this.props.copyActive === true) &&
                                (this.props.applyExtResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.applyExtResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.applyExtResp.message}</b></span>
                                )
                            }

                            {(!isNull(this.props.applyExtResp) && this.props.copyActive === true && this.props.applyExtResp.success === true) &&
                                <span style={{display: "block"}}>
                                    <b>Публикация информационной базы...</b>
                                    {!isNull(this.props.pubIbResp) &&
                                        (this.props.pubIbResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.pubIbResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.pubIbResp) && this.props.copyActive === true) &&
                                (this.props.pubIbResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.pubIbResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.pubIbResp.message}</b></span>
                                )
                            }

                            {(!isNull(this.props.pubIbResp) && this.props.copyActive === true && this.props.pubIbResp.success === true) &&
                                <span style={{display: "block"}}>
                                    <b>Активация OData...</b>
                                    {!isNull(this.props.activateOdataResp) &&
                                        (this.props.activateOdataResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.activateOdataResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.activateOdataResp) && this.props.copyActive === true) &&
                                (this.props.activateOdataResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.activateOdataResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.activateOdataResp.message}</b></span>
                                )
                            }

                            {((!isNull(this.props.activateOdataResp) && this.props.copyActive === true && this.props.activateOdataResp.success === true) || (!isNull(this.props.createIbResp) && this.props.createIbResp.success === true && this.props.copyActive === false)) &&
                                <span style={{display: "block"}}>
                                    <b>Запись параметров информационной базы...</b>
                                    {!isNull(this.props.createIbInDbResp) &&
                                        (this.props.createIbInDbResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.createIbInDbResp) &&  <Loader />}
                                </span>
                            }

                            {!isNull(this.props.createIbInDbResp) &&
                                (this.props.createIbInDbResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.createIbInDbResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.createIbInDbResp.message}</b></span>
                                )
                            }

                            {((!isNull(this.props.createIbInDbResp) && this.props.createIbInDbResp.success === true)) &&
                                <span style={{display: "block"}}>
                                    <b>Присвоение уровня информационной базе...</b>
                                    {!isNull(this.props.assignLevelResp) &&
                                        (this.props.assignLevelResp.success === true ? <b style={{color: "green"}}>&#10003;</b> : <b style={{color: "red"}}>&#215;</b>)
                                    }
                                    {isNull(this.props.assignLevelResp) &&  <Loader />}
                                </span>
                            }

                            {(!isNull(this.props.assignLevelResp) && this.props.assignLevelResp.success === true) &&
                                (this.props.assignLevelResp.success === true ?
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "green"}}>{this.props.assignLevelResp.message}</b></span> :
                                    <span style={{display: "block"}}><b>Ответ: </b><b style={{color: "red"}}>{this.props.assignLevelResp.message}</b></span>
                                )
                            }

                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={8}>
                            <div className='middle-flex-large' style={{justifyContent: "center", flexDirection: "row", display: "flex"}}>
                                <div style={{ width: 120 }}>
                                    <Progress.Circle percent={percent} strokeColor={color} status={this.props.error === true ? "fail" : status} />
                                </div>
                            </div>
                        </FlexboxGrid.Item>
                    </FlexboxGrid>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick} disabled={(status === "success" || error === true) ? false : true}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default NewInfobaseProgress;