import React from 'react';
import { IconButton, Modal, Form } from 'rsuite';
import { isEmpty } from 'lodash';

//import icons
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import CloseIcon from '@rsuite/icons/Close';

class ExportExchangePlanModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {

        const handleCloseClick = () => {
            this.props.onClose();
        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="xs">
                <Modal.Title>Экспорт плана обмена</Modal.Title>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="export-1">
                            <Form.ControlLabel>Скачать план обмена</Form.ControlLabel>
                            <IconButton icon={<FileDownloadIcon />}>Скачать 1CRules.xml</IconButton>
                            <Form.HelpText>Загрузка файла с правилами обмена для 1С.</Form.HelpText>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<CloseIcon/>} onClick={handleCloseClick}>Закрыть</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default ExportExchangePlanModal;