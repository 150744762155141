import React from 'react';
import { IconButton, Modal, Form, InputGroup, Notification } from 'rsuite';
import { isEmpty } from "lodash";

//import icons
import Close from '@rsuite/icons/legacy/Close';
import Lock from '@rsuite/icons/legacy/Lock';

import { updateInfobase } from '../../controllers/clusterController';

class InfobaseCredentialsChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            login: ''
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeLogin = this.handleChangeLogin.bind(this);
        this.updateInfobase = this.updateInfobase.bind(this);
    }

    updateInfobase = async(infobaseExternalId) => {

        const toaster = this.props.toaster;

        try{

            let fields = {
                adminLogin: this.state.login,
                adminPassword: this.state.password
            }

            let update_ib = await updateInfobase(infobaseExternalId, fields);
            toaster.push(<Notification showIcon header={update_ib.success === true ? 'Информация' : 'Ошибка'} type={update_ib.success === true ? 'success' : 'error'}>{update_ib.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });

        }catch(e){
            toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>{e.response.data.message}</Notification>, {
                duration: 10000,
                placement: 'topEnd'
            });
        }

        this.setState({password: '', login: ''});
        this.props.onClose();

    }

    handleChangePassword = (value) => {
        this.setState({password: value});
    };

    handleChangeLogin = (value) => {
        this.setState({login: value});
    };

    render() {

        let infobaseExternalId = this.props.infobaseExternalId;

        const handleCloseClick = () => {
            this.props.onClose();
        };

        const handleAcceptClick = () => {

            const toaster = this.props.toaster;

            if(isEmpty(this.state.password)){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите пароль!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            if(isEmpty(this.state.login)){
                return(
                    toaster.push(<Notification showIcon header={'Ошибка'} type={'error'}>Введите имя пользователя!</Notification>, {
                        duration: 10000,
                        placement: 'topEnd'
                    })
                )
            }

            this.updateInfobase(infobaseExternalId)

        };

        return (
            <Modal backdrop="static" open={this.props.open} onClose={handleCloseClick} size="md">
                <Modal.Header>
                    <Modal.Title>{this.props.caption}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group controlId="login">
                            <Form.ControlLabel>Имя пользователя*:</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="login" onChange={this.handleChangeLogin} value={this.state.login}/>
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="password">
                            <Form.ControlLabel>Пароль*:</Form.ControlLabel>
                            <InputGroup>
                                <InputGroup.Addon><Lock/></InputGroup.Addon>
                                <Form.Control name="password" onChange={this.handleChangePassword} type="password" value={this.state.password}/>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={<Lock/>} appearance="primary" onClick={handleAcceptClick}>Сменить права</IconButton>
                    <IconButton icon={<Close />} appearance="subtle" onClick={handleCloseClick}>Отмена</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default InfobaseCredentialsChange;