import React from 'react';
import { IconButton, Modal, Notification } from 'rsuite';
import { isEmpty } from "lodash";

//import steps
import Greetings from './Steps/Greetings';
import InitAdmin from './Steps/InitAdmin';
import DownloadInfobases from './Steps/DownloadInfobases';
import Finish from './Steps/Finish';
import InitDatabase from './Steps/InitDatabase';
import InitMailSystem from './Steps/InitMailSystem';
import InitStorageSystem from './Steps/InitStorageSystem';

//import icons
import ArrowRightLineIcon from '@rsuite/icons/ArrowRightLine';
import CheckIcon from '@rsuite/icons/Check';

const SetupTitles = ["Мастер установки: Начало работы", "Мастер установки: Инициализация базы данных", "Мастер установки: Инициализация почтовой системы", "Мастер установки: Инициализация хранилища", "Мастер установки: Создание Администратора", "Мастер установки: Загрузка информационных баз", "Мастер установки: Завершение"];

class SetupWizard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            nextDisabled: false
        };
        this.checkDisabledSetupNext = this.checkDisabledSetupNext.bind(this);
    }

    checkDisabledSetupNext = async(e) => {
        this.setState({nextDisabled: e});
    }

    render() {       
        const currentStep = () => {
            if (this.state.step === 0) {
                return <Greetings/>;
            } else if (this.state.step === 1) {
                return <InitDatabase check={this.checkDisabledSetupNext} toaster={this.props.toaster}/>;
            } else if (this.state.step === 2) {
                return <InitMailSystem check={this.checkDisabledSetupNext} toaster={this.props.toaster}/>;
            } else if (this.state.step === 3) {
                return <InitStorageSystem check={this.checkDisabledSetupNext} toaster={this.props.toaster}/>;
            } else if (this.state.step === 4) {
                return <InitAdmin check={this.checkDisabledSetupNext} toaster={this.props.toaster}/>;
            } else if (this.state.step === 5) {
                return <DownloadInfobases check={this.checkDisabledSetupNext} toaster={this.props.toaster}/>;
            } else {
                return <Finish check={this.checkDisabledSetupNext}/>;
            }
        };

        const handleCloseClick = () => {
            this.props.openWizard(false)
        };

        const handleNextStepsClick = () => {
            if (this.state.step === SetupTitles.length - 1) {
                this.setState({ step: 0 });
                handleCloseClick();
                window.location.reload();
              } else {
                this.setState({ step: this.state.step + 1 });
              }
        };

        return (
            <Modal backdrop="static" open={this.props.openState} onClose={handleCloseClick} size="lg">
                <Modal.Title>{SetupTitles[this.state.step]}</Modal.Title>
                <Modal.Body>
                    {currentStep()}
                </Modal.Body>
                <Modal.Footer>
                    <IconButton icon={this.state.step === SetupTitles.length - 1 ? <CheckIcon /> : <ArrowRightLineIcon />} disabled={this.state.nextDisabled} onClick={handleNextStepsClick}>{this.state.step === SetupTitles.length - 1 ? "Завершить установку" : "Далее"}</IconButton>
                </Modal.Footer>
            </Modal> 
        );
    };
}

export default SetupWizard;