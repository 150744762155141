import React, { useState, useEffect } from 'react';
import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';
import Keycloak from 'keycloak-js';
import { Container, Header, Content, Footer, CustomProvider, Navbar, Nav, useToaster, Toggle } from 'rsuite';
import Clock from 'react-live-clock';
import ruRU from 'rsuite/locales/ru_RU';
import { isNull } from 'lodash';

//import components
import UnAuthenticated from './components/Login/UnAuthenticated';
import DenyLoginWithStatusAndRole from './components/Login/DenyLoginWithStatusAndRole';
import LoginButton from './components/Login/LoginButton';
import NotificationButton from './components/Notifications/NotificationButton';
import MaintanceModeButton from './components/UI/MaintanceModeButton';
import Profile from './components/Profile';
import Dashboard from './components/Dashboard';
import Parameters from './components/Admin/Parameters';
import Status from './components/Admin/Status';
import Backups from './components/Admin/Backups';
import NewDB from './components/Admin/NewDB';
import Sync from './components/Admin/Sync';
import Users from './components/Admin/Users';
import ReglamentReports from './components/Reports/ReglamentReports';
import StandartReports from './components/Reports/StandartReports';
import MySidebar from './components/UI/Sidebar';
import Sale from './components/Docs/Sale';
import Salary from './components/Docs/Salary';
import SalesBook from './components/Reports/SalesBook';
import Procurement from './components/Docs/Procurement';
import ProcurementBook from './components/Reports/ProcurementBook';
import Contragents from './components/Sprav/Contragents';
import Organisations from './components/Sprav/Organisations';
import Proxy from './components/Sprav/Proxy';
import EDO from './components/EDO';
import MapOfCluster from './components/MapOfCluster';
import SchetFacturaOut from './components/Docs/SchetFacturaOut';
import SchetFacturaIn from './components/Docs/SchetFacturaIn';
import Requests from './components/Admin/Requests';

//import icons
import SunO from '@rsuite/icons/legacy/SunO';
import MoonO from '@rsuite/icons/legacy/MoonO';

//import setup
import NotInit from './components/Login/NotInit';

import { getInitStatus } from './controllers/initController';

import { history } from './helpers/history';

//import controllers
import { getSystemParameters } from './controllers/systemController';
import { getProfileData } from './controllers/userController';

import './app.css';

export default function App() {

    const [loggedIn, setLoggedIn] = useState(false);
    const [keycloak] = useState(Keycloak('/keycloak.json'));
    const [loaded, setLoaded] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [user_data, setUserData] = useState(null);
    const [general_parameters, setGeneralParameters] = useState(null);
    const [darkTheme, setTheme] = useState(true);
    const toaster = useToaster();

    useEffect(() => {
        getStatus();      
        keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
          setLoggedIn(authenticated);
          setLoaded(true);
        });
    }, []);

    useEffect(() => {
        if(loggedIn){
            getUser();
            getParameters();
        }
    }, [loggedIn]);

    let getStatus = async () => {
        let status = await getInitStatus();
        setInitialized(status.init_flag);
    }

    let getUser = async () => {
        let user = await getProfileData();
        setUserData(user.data);
    }

    let getParameters = async () => {
        let parameters = await getSystemParameters();
        setGeneralParameters(parameters.data);
    }

    const onLoginChange = (bool) => {
        setLoggedIn(bool);
    }

    const toggleChecked = () => setTheme(value => !value);

    let authButtonBehaviour = loggedIn ? "Выйти" : "Войти";

    return( 
        <div id="app" className="App">
            {!loaded && 
                <div id="app_loading">
                    <p>Идет загрузка...</p>
                </div>
            }
            {loaded &&
                <CustomProvider theme={darkTheme ? 'dark' : 'light'} locale={ruRU}>
                    <Router history={history}>
                        {!initialized ? (
                            <NotInit toaster={toaster} />
                        ) : (
                            <Container style={{minHeight: '100%', alignItems: 'stretch', flexDirection: 'column'}}>
                                <Header>
                                    <Navbar>
                                        <Navbar.Brand href="/">FINANCE CLUB</Navbar.Brand>
                                        <Nav>
                                            {(loggedIn && !isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор") && user_data.status.statusName === "verified") &&
                                                    <MaintanceModeButton/>
                                            }
                                        </Nav>
                                        <Nav pullRight>
                                            {(loggedIn && !isNull(user_data) && (user_data.user.role !== "Агент" && user_data.user.role !== "Организация") && user_data.status.statusName === "verified") &&
                                                <NotificationButton/>
                                            }
                                            <LoginButton 
                                                behaviour={authButtonBehaviour}
                                                keycloak={keycloak}
                                                onLoginChange={onLoginChange}
                                            />
                                        </Nav>
                                    </Navbar>
                                </Header>
                                {!loggedIn ? (
                                    <UnAuthenticated />
                                ) : (
                                    <Content style={{display: 'flex', flexDirection: 'column'}}>
                                        { (!isNull(user_data) && (user_data.user.role === "Агент" || user_data.user.role === "Организация")) && 
                                            <DenyLoginWithStatusAndRole text={"Доступ запрещен для пользователей с ролями 'Агент' и 'Организация'!"} />
                                        }
                                        { (!isNull(user_data) && (user_data.user.role !== "Агент" && user_data.user.role !== "Организация") && user_data.status.statusName === "blocked") && 
                                            <DenyLoginWithStatusAndRole text={"Пользователь заблокирован! Обратитесь к администратору."} />
                                        }
                                        { (!isNull(user_data) && (user_data.user.role !== "Агент" && user_data.user.role !== "Организация") && user_data.status.statusName === "created") &&
                                            <DenyLoginWithStatusAndRole text={"Пользователь не верифицирован! Письмо для верификации было отправлено на почту: " + user_data.user.email + ". В случае, если у Вас нет доступа к почте, дождитесь верификации от администратора."} />
                                        }
                                        { (!isNull(user_data) && (user_data.user.role !== "Агент" && user_data.user.role !== "Организация") && user_data.status.statusName === "verified") &&
                                            <div id="work_area">
                                                <MySidebar user={user_data}/>
                                                <Routes>
                                                    <Route path="*" element={<Navigate to="/profile" /> }/>
                                                    <Route exact path="/" element={<Navigate to="/dashboard" /> }/>
                                                    <Route exact path="/dashboard" element={<Dashboard user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/profile" element={<Profile toaster={toaster}/>} />
                                                    <Route exact path="/reports/standart" element={<StandartReports user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/reports/reglament" element={<ReglamentReports user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/edo" element={<EDO user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/map" element={<MapOfCluster user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/sprav/contragents" element={<Contragents parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/sprav/org" element={<Organisations parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/sprav/proxy" element={<Proxy toaster={toaster}/>} />
                                                    { (!isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор")) ?
                                                        <Route exact path="/admin/parameters" element={<Parameters toaster={toaster}/>} />:
                                                        <Route exact path="/admin/parameters" element={<Navigate to="/profile" />} />
                                                    }
                                                    { (!isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор")) ?
                                                        <Route exact path="/admin/users" element={<Users toaster={toaster}/>} />:
                                                        <Route exact path="/admin/users" element={<Navigate to="/profile" />} />
                                                    }
                                                    { (!isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор")) ?
                                                        <Route exact path="/admin/requests" element={<Requests toaster={toaster}/>} />                                                    :
                                                        <Route exact path="/admin/requests" element={<Navigate to="/profile" />} />
                                                    }
                                                    { (!isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор")) ?
                                                        <Route exact path="/admin/status" element={<Status toaster={toaster}/>} />:
                                                        <Route exact path="/admin/status" element={<Navigate to="/profile" />} />
                                                    }
                                                    { (!isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор")) ?
                                                        <Route exact path="/admin/backups" element={<Backups parameters={general_parameters} toaster={toaster}/>} />:
                                                        <Route exact path="/admin/backups" element={<Navigate to="/profile" />} />
                                                    }
                                                    { (!isNull(user_data) && (user_data.user.role === "Главный администратор" || user_data.user.role === "Администратор")) ?
                                                        <Route exact path="/admin/createdb" element={<NewDB parameters={general_parameters} toaster={toaster} token={keycloak.token}/>} />:
                                                        <Route exact path="/admin/createdb" element={<Navigate to="/profile" />} />
                                                    }
                                                    <Route exact path="/sync" element={<Sync user={user_data} parameters={general_parameters} toaster={toaster}/>} />:
                                                    <Route exact path="/reports/book/procurement" element={<ProcurementBook user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/reports/book/sales" element={<SalesBook user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/docs/sales" element={<Sale user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/docs/sf/outgoing" element={<SchetFacturaOut user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/docs/sf/incoming" element={<SchetFacturaIn user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/docs/procurement" element={<Procurement user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                    <Route exact path="/docs/salary" element={<Salary user={user_data} parameters={general_parameters} toaster={toaster}/>} />
                                                </Routes>
                                            </div>
                                        }
                                    </Content>
                                )}
                                <Footer>
                                    <Navbar>
                                        <Nav pullRight>
                                            <Toggle checkedChildren={<SunO />} unCheckedChildren={<MoonO />} style={{padding: '0 10px', lineHeight: '48px'}} onChange={toggleChecked}/>
                                            <Clock format="HH:mm DD.MM.yyyy" interval={1000} ticking={true} style={{padding: '0 10px', lineHeight: '48px'}}/>
                                        </Nav>
                                    </Navbar>
                                </Footer>
                            </Container>
                        )}
                    </Router>
                </CustomProvider>
            }
        </div>
    )
};